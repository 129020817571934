import { CheckOutlined, WarningOutlined } from "@ant-design/icons";
import { Card, Col, Empty, Row, Skeleton, Statistic } from "antd";
import { isEmpty } from "lodash";
import { PropsWithChildren, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";

import { myTheme } from "src/theme";
import { isNoConnectionError, showNoConnectionNotification } from "src/utils";

import { backOfficeApi } from "../../api";
import { StatusIcon } from "../../components";
import { Statistics } from "../../models";
import { BackOfficeLayout } from "../BackOfficeLayout";

const MaxWidthWrapper = styled.div`
  max-width: 700px;
  padding-bottom: 50px;
`;

const PrefixWrapper = styled.span`
  padding-right: 5px;
`;

const cardSpacing = 16;

function StatisticCard(
  props: PropsWithChildren<{
    statistics: { label?: string; value: string | number }[];
    cardLabel?: string;
    icon?: ReactNode;
    precision?: number;
    color?: string;
    suffix?: string;
  }>
) {
  return (
    <Card
      style={{ width: "100%", marginBottom: cardSpacing, borderRadius: 6 }}
      title={props.cardLabel}
    >
      {props.statistics.map((statistic) => (
        <Statistic
          key={`${props.cardLabel}${statistic.label}`}
          value={statistic.value}
          title={statistic.label}
          valueStyle={props.color ? { color: props.color } : undefined}
          precision={props.precision}
          prefix={
            <>{props.icon && <PrefixWrapper>{props.icon}</PrefixWrapper>}</>
          }
          suffix={props.suffix}
        />
      ))}
      {props.children}
    </Card>
  );
}

const SubStatisticsWrapper = styled.div`
  border-left: 1px solid ${myTheme.colors.whitePanelBorderColor};
  margin: 4px 0 0 10px;
  padding-left: 14px;
`;

const subStatisticsValueFontSize = 18;

export function Dashboard() {
  const { t } = useTranslation("backoffice");
  const { t: tTranslation } = useTranslation("translation");
  const [isLoading, setIsLoading] = useState(true);
  const [statisticValue, setStatisticValue] = useState<Statistics | null>(null);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const statisticsValue = await backOfficeApi.getStatistics();
        setStatisticValue(statisticsValue);
      } catch (e) {
        if (isNoConnectionError(e)) {
          showNoConnectionNotification(tTranslation);
        } else {
          throw e;
        }
      }

      setIsLoading(false);
    })();
  }, [tTranslation]);

  const theme = useTheme();

  return (
    <BackOfficeLayout pageTitle={t("dashboard")} contentMinWidth={0}>
      <BackOfficeLayout.Content>
        <MaxWidthWrapper>
          <Row gutter={[cardSpacing, 0]}>
            {isLoading ? (
              <Skeleton />
            ) : !statisticValue ? (
              <Empty description={false} />
            ) : (
              <>
                <Col xs={24} sm={12}>
                  <StatisticCard
                    statistics={[{ value: statisticValue.nonNegativeVehicles }]}
                    cardLabel={t("statistics.nonNegativeVehicles")}
                    icon={<CheckOutlined />}
                    color={theme.colors.status.ok}
                    suffix="%"
                    precision={2}
                  >
                    <SubStatisticsWrapper>
                      <Statistic
                        style={{ marginBottom: 10 }}
                        value={statisticValue.okStatusVehicles}
                        title={t("statistics.okStatusVehicles")}
                        valueStyle={{ fontSize: subStatisticsValueFontSize }}
                        precision={2}
                        prefix={
                          <>
                            <PrefixWrapper>
                              <StatusIcon status="ok" />
                            </PrefixWrapper>
                          </>
                        }
                        suffix="%"
                      />
                      <Statistic
                        style={{ marginBottom: 10 }}
                        value={statisticValue.warningStatusVehicles}
                        title={t("statistics.warningStatusVehicles")}
                        valueStyle={{ fontSize: subStatisticsValueFontSize }}
                        precision={2}
                        prefix={
                          <>
                            <PrefixWrapper>
                              <StatusIcon status="warning" />
                            </PrefixWrapper>
                          </>
                        }
                        suffix="%"
                      />
                      <Statistic
                        value={statisticValue.noneStatusVehicles}
                        title={t("statistics.noneStatusVehicles")}
                        valueStyle={{ fontSize: subStatisticsValueFontSize }}
                        precision={2}
                        suffix="%"
                      />
                    </SubStatisticsWrapper>
                  </StatisticCard>
                  <StatisticCard
                    statistics={[{ value: statisticValue.numberOfProtocols }]}
                    cardLabel={t("statistics.numberOfProtocols")}
                  />
                  <StatisticCard
                    color={theme.colors.status.ok}
                    statistics={
                      isEmpty(statisticValue.paidCharges)
                        ? [{ value: "-" }]
                        : Object.entries(statisticValue.paidCharges)
                            .sort(([firstCurrency], [secondCurrency]) =>
                              firstCurrency.localeCompare(secondCurrency)
                            )
                            .map(([currency, amount]) => ({
                              value: `${amount} ${currency}`,
                            }))
                    }
                    cardLabel={t("statistics.paidCharges")}
                  />
                  <StatisticCard
                    color={theme.colors.status.notOk}
                    statistics={
                      isEmpty(statisticValue.unpaidCharges)
                        ? [{ value: "-" }]
                        : Object.entries(statisticValue.unpaidCharges)
                            .sort(([firstCurrency], [secondCurrency]) =>
                              firstCurrency.localeCompare(secondCurrency)
                            )
                            .map(([currency, amount]) => ({
                              value: `${amount} ${currency}`,
                            }))
                    }
                    cardLabel={t("statistics.unpaidCharges")}
                  />
                </Col>
                <Col xs={24} sm={12}>
                  <StatisticCard
                    statistics={statisticValue.vehicleTypeStatistics.map(
                      (vehicleTypeStatistic) => ({
                        label: vehicleTypeStatistic.label,
                        value: vehicleTypeStatistic.vehicles,
                      })
                    )}
                    cardLabel={t("statistics.numberOfVehicles")}
                  />
                  <StatisticCard
                    statistics={statisticValue.vehicleTypeStatistics.map(
                      (vehicleTypeStatistic) => ({
                        label: vehicleTypeStatistic.label,
                        value: vehicleTypeStatistic.negativeVehicles,
                      })
                    )}
                    cardLabel={t("statistics.numberOfNegativeVehicles")}
                    icon={<WarningOutlined />}
                    color={theme.colors.status.notOk}
                  />
                </Col>
              </>
            )}
          </Row>
        </MaxWidthWrapper>
      </BackOfficeLayout.Content>
    </BackOfficeLayout>
  );
}
