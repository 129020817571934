import { Skeleton, Table, TableProps } from "antd";
import { ReactNode } from "react";
import styled from "styled-components";

import { myTheme } from "src/theme";

import { GlobalFormErrors } from "../Form/GlobalFormErrors";
import { NextPreviousPagination } from "./NextPreviousPagination";

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 16px 0;
`;

export interface BasicTableProps<RecordType>
  extends Pick<
    TableProps<RecordType>,
    | "columns"
    | "dataSource"
    | "rowKey"
    | "onRow"
    | "rowSelection"
    | "onChange"
    | "expandable"
  > {
  hoverableRows?: boolean;
  verticalAlignTop?: boolean;
  pagination:
    | false
    | { current: number; total: number; onChange: (page: number) => void };
  stickyActions?: () => ReactNode;
  errors?: string[];
  loading?: boolean;
}

export function BasicTable<RecordType extends object>({
  errors = [],
  ...props
}: BasicTableProps<RecordType>): JSX.Element {
  const hasData = !!props.dataSource && !!props.dataSource.length;
  const rowClassNames = [
    ...(props.hoverableRows ? ["ins-cursor-pointer"] : []),
    ...(props.verticalAlignTop ? ["ins-vertical-align-top"] : []),
  ];
  return (
    <>
      <Table
        size="middle"
        loading={props.loading && hasData}
        locale={{
          ...(props.loading && !hasData
            ? { emptyText: <Skeleton /> }
            : !props.loading && errors.length
            ? {
                emptyText: <GlobalFormErrors errors={errors} />,
              }
            : {}),
        }}
        showSorterTooltip={false}
        pagination={false}
        columns={props.columns}
        dataSource={props.dataSource}
        rowKey={props.rowKey}
        rowClassName={rowClassNames.join(" ")}
        onRow={props.onRow}
        onChange={props.onChange}
        summary={
          props.stickyActions
            ? () => (
                <Table.Summary fixed="top">
                  <Table.Summary.Row style={{ backgroundColor: "#fcfcfc" }}>
                    <Table.Summary.Cell index={0} colSpan={99}>
                      {props.stickyActions?.()}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )
            : undefined
        }
        sticky={
          props.stickyActions
            ? {
                offsetHeader: myTheme.commonVariables.backOfficeHeaderHeight,
              }
            : undefined
        }
        rowSelection={props.rowSelection}
        expandable={props.expandable}
      />

      {props.pagination && (
        <PaginationWrapper>
          <NextPreviousPagination
            currentPageNumber={props.pagination.current}
            totalCount={props.pagination.total}
            onPageChange={props.pagination.onChange}
            isLoading={props.loading}
          />
        </PaginationWrapper>
      )}
    </>
  );
}
