import { FileDoneOutlined } from "@ant-design/icons";
import { Modal, Skeleton, Typography, Space, Badge } from "antd";
import { ModalProps } from "antd/es/modal";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { BasicTable } from "src/components";
import { urls } from "src/urls";
import { fullName } from "src/utils";

import { backOfficeApi } from "../api";
import {
  LinkingHistoryChangeReason,
  LinkingHistoryHeader,
  LinkingHistoryRow,
} from "../models";
import { LinkedObjectLink } from "./LinkedObjectComponents";

export interface VehicleLinkingHistoryModalProps
  extends Pick<ModalProps, "onCancel"> {
  vehicleId: string;
  vehiclePlateNumber: string;
  vehicleTypeName: string;
}

export function VehicleLinkingHistoryModal({
  vehicleId,
  vehiclePlateNumber,
  vehicleTypeName,
  ...modalProps
}: VehicleLinkingHistoryModalProps) {
  const { t } = useTranslation("backoffice");

  const [isLoading, setIsLoading] = useState(true);
  const [headers, setHeaders] = useState<LinkingHistoryHeader[]>([]);
  const [historyData, setHistoryData] = useState<LinkingHistoryRow[]>([]);

  const fetchLinkingHistory = useCallback(async () => {
    setIsLoading(true);
    const response = await backOfficeApi.getVehicleLinkingHistory(vehicleId);
    setHeaders(response.headers);
    setHistoryData(response.history);
    setIsLoading(false);
  }, [vehicleId]);

  useEffect(() => {
    fetchLinkingHistory();
  }, [fetchLinkingHistory]);

  const textByChangeReason = useMemo(
    () => ({
      [LinkingHistoryChangeReason.MODEL_VIEW_SET]: t(
        "vehicleLinkingHistory.changeReasons.modelViewSet"
      ),
      [LinkingHistoryChangeReason.PROTOCOL_CREATE]: t(
        "vehicleLinkingHistory.changeReasons.protocolCreate"
      ),
    }),
    [t]
  );

  const renderAssignedCell = useCallback(
    (
      header: LinkingHistoryHeader,
      index: number,
      record: LinkingHistoryRow
    ) => {
      if (header.type === "link_type") {
        const vehicle = record.assignedVehicles[index.toString()];
        return vehicle ? (
          <LinkedObjectLink
            $rightMargin
            to={urls.backOffice.vehicleCard(vehicle.id)}
            requiredPermission="backoffice.vehicles.read"
          >
            {vehicle.plateNumber}
          </LinkedObjectLink>
        ) : (
          "-"
        );
      }

      if (header.type === "user_assignment") {
        const user = record.assignedUser;
        return user ? (
          <LinkedObjectLink
            $rightMargin
            to={urls.backOffice.editEmployee(user.id)}
            requiredPermission="backoffice.employees.write"
          >
            {fullName(user)}
          </LinkedObjectLink>
        ) : (
          "-"
        );
      }

      return "-";
    },
    []
  );

  const renderChangeReason = useCallback(
    (record: LinkingHistoryRow) => {
      if (record.changeReason === "PROTOCOL_CREATE") {
        return (
          <div>
            <Space size="small" style={{ alignItems: "start" }}>
              <FileDoneOutlined />
              <Typography.Text italic>
                {textByChangeReason[record.changeReason]}
              </Typography.Text>
            </Space>
            {record.sourceProtocol && (
              <div>
                <Link
                  to={urls.backOffice.protocol(record.sourceProtocol.id)}
                  target="_blank"
                >
                  {record.sourceProtocol.label}
                </Link>
              </div>
            )}
          </div>
        );
      } else if (record.changeReason) {
        return (
          <Typography.Text italic>
            {textByChangeReason[record.changeReason]}
          </Typography.Text>
        );
      }
      return "-";
    },
    [textByChangeReason]
  );

  const columns = useMemo(() => {
    return [
      {
        title: t("vehicleLinkingHistory.headers.dateRange"),
        key: "dateRange",
        width: 200,
        render: (row: LinkingHistoryRow) => (
          <>
            <div>
              <Space size="small">
                <Typography.Text type="secondary">
                  {t("vehicleLinkingHistory.dateTo")}
                </Typography.Text>
                <Typography.Text strong>
                  {row.dateTo ? (
                    dayjs(row.dateTo).format("DD.MM.YYYY, HH:mm")
                  ) : (
                    <Badge
                      status="processing"
                      text={t("vehicleLinkingHistory.now")}
                    />
                  )}
                </Typography.Text>
              </Space>
            </div>
            <div>
              <Space size="small">
                <Typography.Text type="secondary">
                  {t("vehicleLinkingHistory.dateFrom")}
                </Typography.Text>
                <Typography.Text strong>
                  {row.dateFrom
                    ? dayjs(row.dateFrom).format("DD.MM.YYYY, HH:mm")
                    : t("vehicleLinkingHistory.beginning")}
                </Typography.Text>
              </Space>
            </div>
          </>
        ),
      },
      {
        title: vehicleTypeName,
        key: "currentVehicle",
        width: 200,
        render: () => (
          <Typography.Text strong>{vehiclePlateNumber}</Typography.Text>
        ),
      },
      ...headers.map((header, index) => ({
        title: header.label,
        key: `assigned_${index}`,
        width: 200,
        render: (_: any, record: LinkingHistoryRow) =>
          renderAssignedCell(header, index, record),
      })),
      {
        title: t("vehicleLinkingHistory.headers.changeReason"),
        key: "changeReason",
        render: renderChangeReason,
      },
    ];
  }, [
    t,
    vehiclePlateNumber,
    vehicleTypeName,
    headers,
    renderAssignedCell,
    renderChangeReason,
  ]);

  return (
    <Modal
      {...modalProps}
      open
      title={t("vehicleLinkingHistory.title", {
        plateNumber: vehiclePlateNumber,
      })}
      width={1100}
      footer={null}
      onCancel={modalProps.onCancel}
    >
      {isLoading ? (
        <Skeleton active />
      ) : (
        <BasicTable
          columns={columns}
          dataSource={historyData}
          rowKey={(record) => `${record.dateFrom}-${record.dateTo}`}
          pagination={false}
        />
      )}
    </Modal>
  );
}
