import styled from "styled-components";

export const ManualSpace = styled.div<{ gap: string }>`
  display: flex;
  gap: ${({ gap }) => gap};
`;

export const ManualSpaceItem = styled.div<{
  flex: string;
}>`
  flex: ${({ flex }) => flex};
  min-width: 0; /* Important to allow flex children to shrink properly */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
