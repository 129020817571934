import * as Sentry from "@sentry/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import { NoConnectionResultPage, Spinner } from "src/components";
import { urls } from "src/urls";

import { protocolFillerApi } from "../api";
import {
  ProtocolFillerLocalStorageMigrator,
  ProtocolFillerPositionProvider,
} from "../components";
import { PageLayout } from "../components/PageLayout";
import {
  ProtocolFillerLocalStorageProvider,
  ProtocolFillerContextProvider,
  ProtocolFillerWizard,
  VehicleFromParamsProvider,
} from "../contexts";
import { TemplatesProvider, VehiclesProvider } from "../hooks";
import { VehicleType } from "../models";
import { ChooseVehicleInitialScreen } from "./ChooseVehicleInitialScreen";
import { LoginViaQRCode } from "./LoginViaQRCode";
import { TemplateSelectorView } from "./TemplateSelectorView";
import { VehicleSelectorView } from "./VehicleSelectorView";

const SentryRoute = Sentry.withSentryRouting(Route);
export function ProtocolFillerRouter(): JSX.Element {
  const location = useLocation();
  const [vehicleTypesLoadingState, setVehicleTypesLoadingState] = useState<
    "loading" | "loaded" | "error"
  >("loading");
  const [vehicleTypes, setVehicleTypes] = useState<VehicleType[]>([]);
  const vehicleTypesOptions = useMemo(
    () =>
      vehicleTypes.map((vehicleType) => ({
        ...vehicleType,
        value: vehicleType.id,
      })),
    [vehicleTypes]
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const loadVehicleTypes = useCallback(async () => {
    setVehicleTypesLoadingState("loading");
    try {
      const vehicleTypes = await protocolFillerApi.getVehicleTypes();
      setVehicleTypes(vehicleTypes);
      setVehicleTypesLoadingState("loaded");
    } catch {
      setVehicleTypesLoadingState("error");
    }
  }, []);

  useEffect(() => {
    loadVehicleTypes();
  }, [loadVehicleTypes]);
  const hasOneVehicleType = vehicleTypes.length === 1;
  const hasMoreVehicleTypes = vehicleTypes.length > 1;

  switch (vehicleTypesLoadingState) {
    case "loading":
      return (
        <PageLayout>
          <Spinner backgroundColor="transparent" />
        </PageLayout>
      );
    case "error":
      return (
        <PageLayout>
          <NoConnectionResultPage onRetryClick={() => loadVehicleTypes()} />
        </PageLayout>
      );
    case "loaded":
      return (
        <ProtocolFillerLocalStorageMigrator>
          <ProtocolFillerLocalStorageProvider>
            <VehiclesProvider>
              <TemplatesProvider>
                <Switch>
                  <SentryRoute path={urls.protocolFiller.main()} exact>
                    {hasOneVehicleType && vehicleTypes[0] ? (
                      <Redirect
                        to={urls.protocolFiller.vehicleType(vehicleTypes[0].id)}
                      />
                    ) : (
                      <ChooseVehicleInitialScreen
                        vehicleTypes={vehicleTypesOptions}
                      />
                    )}
                  </SentryRoute>
                  <SentryRoute path={urls.protocolFiller.qrCodeLogin()} exact>
                    <LoginViaQRCode />
                  </SentryRoute>
                  <SentryRoute path={urls.protocolFiller.vehicleType()} exact>
                    <VehicleSelectorView
                      hasBackButton={hasMoreVehicleTypes}
                      showQRScan={hasOneVehicleType}
                    />
                  </SentryRoute>
                  <SentryRoute
                    path={urls.protocolFiller.vehicleTypeAndVehicle()}
                  >
                    <VehicleFromParamsProvider>
                      <Switch>
                        <SentryRoute
                          path={urls.protocolFiller.vehicleTypeAndVehicle()}
                          exact
                        >
                          <TemplateSelectorView />
                        </SentryRoute>
                        <SentryRoute path="*">
                          <ProtocolFillerPositionProvider>
                            <ProtocolFillerContextProvider
                              vehicleTypes={vehicleTypes}
                            >
                              <Switch>
                                <SentryRoute
                                  path={urls.protocolFiller.vehicleTypeAndVehicleAndTemplate()}
                                >
                                  <ProtocolFillerWizard />
                                </SentryRoute>
                                <SentryRoute path="*">
                                  <Redirect to={urls.protocolFiller.main()} />
                                </SentryRoute>
                              </Switch>
                            </ProtocolFillerContextProvider>
                          </ProtocolFillerPositionProvider>
                        </SentryRoute>
                      </Switch>
                    </VehicleFromParamsProvider>
                  </SentryRoute>
                  <SentryRoute path="*">
                    <Redirect to={urls.protocolFiller.main()} />
                  </SentryRoute>
                </Switch>
              </TemplatesProvider>
            </VehiclesProvider>
          </ProtocolFillerLocalStorageProvider>
        </ProtocolFillerLocalStorageMigrator>
      );
  }
}
