import { CustomVehicleFieldStatusSelect } from "../CustomVehicleFieldStatusSelect";
import { FilterItem } from "../ListView";

interface Props {
  excludeNone?: boolean;
}

export function StatusSelectFilterItem({ excludeNone }: Props) {
  return (
    <FilterItem name="statuses" $inputWidth="long">
      <CustomVehicleFieldStatusSelect excludeNone={excludeNone} />
    </FilterItem>
  );
}
