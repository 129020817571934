import * as Sentry from "@sentry/react";
import { Skeleton, Space, Tabs, Tooltip } from "antd";
import { isEmpty, sortBy, uniqBy } from "lodash";
import React, { PropsWithChildren, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import styled from "styled-components";

import { urls } from "src/urls";

import { FiltersRow, StatusIcon } from "../../../components";
import {
  CustomVehicleFieldValueStatus,
  ListCustomVehicleField,
  ListRetrieveCustomVehicleFieldsGroup,
  RetrieveVehicleCustomVehicleField,
} from "../../../models";
import {
  CustomVehicleFieldsStatusAndInfo,
  getResultingVehicleFieldsStatusAndInfo,
} from "../../../utils";
import { BackOfficeLayout } from "../../BackOfficeLayout";
import { damagesContentMinWidth, DamagesList } from "../../DamagesList";
import { useAllCustomVehicleFieldsExcludingDamages } from "../hooks/useAllCustomVehicleFieldsExcludingDamages";
import { VehicleFieldValueHistoryModalView } from "../VehicleFieldValueHistoryModalView";
import { DisplayUpdateVehicleCustomFieldsForm } from "./index";

const SentryRoute = Sentry.withSentryRouting(Route);
const ZeroMarginStatusIcon = styled(StatusIcon)`
  margin: 0 !important;
`;

const exclamationMarkOffset = 8;

const StatusIconWrapper = styled.div<{ extraRightMargin: boolean }>`
  position: relative;
  display: inline-block;
  margin-left: -${exclamationMarkOffset}px;
  ${(props) =>
    props.extraRightMargin
      ? `margin-right: ${exclamationMarkOffset - 2}px;`
      : ""}
`;

const BlueDotTopRight = styled.div`
  position: absolute;
  width: 14px;
  height: 14px;
  top: -3px;
  right: -${exclamationMarkOffset}px;
  font-size: 12px;
  line-height: 14px;
  background-color: #1890ff;
  color: #fff;
  border-radius: 50%;
  text-align: center;
`;

function TabLabel(props: {
  status: CustomVehicleFieldValueStatus;
  text: string;
  testIdPrefix: string;
  statusInfo?: string;
}): JSX.Element {
  const content = (
    <Space>
      <StatusIconWrapper extraRightMargin={!!props.statusInfo}>
        <ZeroMarginStatusIcon status={props.status} />
        {!!props.statusInfo && <BlueDotTopRight>!</BlueDotTopRight>}
      </StatusIconWrapper>
      {props.text}
    </Space>
  );
  return (
    <span data-testid={`${props.testIdPrefix}-${props.status}`}>
      {props.statusInfo && !!props.statusInfo.length ? (
        <Tooltip title={props.statusInfo} placement="topLeft">
          {content}
        </Tooltip>
      ) : (
        content
      )}
    </span>
  );
}

type DoNotDisplay = null;

interface StatusAndInfoMessage {
  status: CustomVehicleFieldValueStatus;
  infoMessage: string;
}

interface TabbedSectionProps {
  vehicleId: string;
  activeTab:
    | "customVehicleFields"
    | "damages"
    | ListRetrieveCustomVehicleFieldsGroup["id"];
  customVehicleFieldsStatusAndInfo: StatusAndInfoMessage | DoNotDisplay;
  damagesStatusAndInfo: StatusAndInfoMessage;
  customVehicleFieldsGroups: ListRetrieveCustomVehicleFieldsGroup[];
  customVehicleFieldsGroupStatusAndInfoById: Record<
    string,
    StatusAndInfoMessage
  >;
}

function TabbedSection(
  props: PropsWithChildren<TabbedSectionProps>
): JSX.Element {
  const { t } = useTranslation("backoffice");
  const history = useHistory();

  return (
    <>
      <Tabs
        size="large"
        tabBarStyle={{ fontWeight: 500 }}
        type="card"
        activeKey={props.activeTab}
        onTabClick={(clickedTab) => {
          switch (clickedTab) {
            case "customVehicleFields":
              history.push(urls.backOffice.vehicleCard(props.vehicleId));
              break;
            case "damages":
              history.push(urls.backOffice.vehicleCardDamages(props.vehicleId));
              break;
            default:
              history.push(
                urls.backOffice.vehicleCardCustomVehicleFieldsGroup(
                  props.vehicleId,
                  clickedTab
                )
              );
          }
        }}
        items={[
          ...(props.customVehicleFieldsStatusAndInfo != null
            ? [
                {
                  label: (
                    <TabLabel
                      text={t("vehicleView.customVehicleFields")}
                      testIdPrefix="custom-vehicle-fields-status"
                      status={props.customVehicleFieldsStatusAndInfo.status}
                      statusInfo={
                        props.customVehicleFieldsStatusAndInfo.infoMessage
                      }
                    />
                  ),
                  key: "customVehicleFields",
                  children: null,
                },
              ]
            : []),
          {
            label: (
              <TabLabel
                text={t("damages.pageTitle")}
                testIdPrefix="damages-status"
                status={props.damagesStatusAndInfo.status}
                statusInfo={props.damagesStatusAndInfo.infoMessage}
              />
            ),
            key: "damages",
            children: null,
          },
          ...props.customVehicleFieldsGroups.map((customVehicleFieldsGroup) => {
            const groupStatusAndInfo =
              props.customVehicleFieldsGroupStatusAndInfoById[
                customVehicleFieldsGroup.id
              ];

            return {
              label: (
                <TabLabel
                  text={customVehicleFieldsGroup.label}
                  testIdPrefix="custom-vehicle-fields-group-status"
                  status={groupStatusAndInfo?.status || "none"}
                  statusInfo={groupStatusAndInfo?.infoMessage}
                />
              ),
              key: customVehicleFieldsGroup.id,
              children: null,
            };
          }),
        ]}
      />
      {props.children}
    </>
  );
}

interface Props {
  vehicleId: string;
  vehiclePlateNumber: string;
  vehicleTypeId: string;
  loadVehicleFieldValues: () => Promise<void>;
  vehicleCustomVehicleFields: Record<string, RetrieveVehicleCustomVehicleField>;
}

export function VehicleCardTabs(props: Props) {
  const history = useHistory();
  const { t } = useTranslation("backoffice");

  const {
    customVehicleFields: customVehicleFieldsExcludingDamages,
    isCustomVehicleFieldsLoading,
  } = useAllCustomVehicleFieldsExcludingDamages(props.vehicleTypeId);

  const customVehicleFieldsExcludingDamagesWithoutGroup = useMemo(
    () =>
      customVehicleFieldsExcludingDamages.filter(
        (customVehicleField) =>
          !customVehicleField.customVehicleFieldsGroupObject
      ),
    [customVehicleFieldsExcludingDamages]
  );

  const getMessageFromStatusInfo = useCallback(
    (statusInfo: CustomVehicleFieldsStatusAndInfo["info"]): string => {
      if (statusInfo == null) {
        return "";
      }

      return statusInfo.name === "fields-with-no-status-exist"
        ? t("vehicleView.someFieldsHaveNoStatus", { count: statusInfo.count })
        : "";
    },
    [t]
  );

  const customVehicleFieldsWithGroupByGroupId = useMemo(
    () =>
      customVehicleFieldsExcludingDamages.reduce<
        Record<string, ListCustomVehicleField[]>
      >((previousValue, currentValue) => {
        if (!!currentValue.customVehicleFieldsGroupObject) {
          const previousValueArray =
            previousValue[currentValue.customVehicleFieldsGroupObject.id] || [];

          return {
            ...previousValue,
            [currentValue.customVehicleFieldsGroupObject.id]: [
              ...previousValueArray,
              currentValue,
            ],
          };
        }
        return previousValue;
      }, {}),
    [customVehicleFieldsExcludingDamages]
  );

  const customVehicleFieldsGroups = useMemo(
    () =>
      sortBy(
        uniqBy(
          Object.values(customVehicleFieldsExcludingDamages).reduce<
            ListRetrieveCustomVehicleFieldsGroup[]
          >(
            (previous, current) =>
              current.customVehicleFieldsGroupObject
                ? [...previous, current.customVehicleFieldsGroupObject]
                : previous,
            []
          ),
          "id"
        ),
        "order"
      ),
    [customVehicleFieldsExcludingDamages]
  );

  const damagesStatusAndInfo = useMemo(
    () =>
      getResultingVehicleFieldsStatusAndInfo(
        Object.values(props.vehicleCustomVehicleFields)
          .filter(
            (customFieldValue) =>
              customFieldValue.fieldObject.type === "damages"
          )
          .map((customFieldValue) => customFieldValue.valueObject.status)
      ),
    [props.vehicleCustomVehicleFields]
  );

  const customVehicleFieldsStatusAndInfo = useMemo(
    () =>
      getResultingVehicleFieldsStatusAndInfo(
        Object.values(props.vehicleCustomVehicleFields)
          .filter(
            (customFieldValue) =>
              customFieldValue.fieldObject.type !== "damages"
          )
          .filter(
            (vehicleCustomVehicleField) =>
              !vehicleCustomVehicleField.fieldObject
                .customVehicleFieldsGroupObject
          )
          .map((customFieldValue) => customFieldValue.valueObject.status)
      ),
    [props.vehicleCustomVehicleFields]
  );

  const customVehicleFieldsGroupStatusAndInfoById: Record<
    string,
    StatusAndInfoMessage
  > = useMemo(
    () =>
      customVehicleFieldsGroups.reduce<Record<string, StatusAndInfoMessage>>(
        (previousValue, currentValue) => {
          const fieldsForGroup = Object.values(
            props.vehicleCustomVehicleFields
          ).filter(
            (customFieldValue) =>
              customFieldValue.fieldObject.customVehicleFieldsGroupObject
                ?.id === currentValue.id
          );

          const statusAndInfo = getResultingVehicleFieldsStatusAndInfo(
            fieldsForGroup.map(
              (customFieldValue) => customFieldValue.valueObject.status
            )
          );

          return {
            ...previousValue,
            [currentValue.id]: {
              status: statusAndInfo.status,
              infoMessage: getMessageFromStatusInfo(statusAndInfo.info),
            },
          };
        },
        {}
      ),
    [
      customVehicleFieldsGroups,
      getMessageFromStatusInfo,
      props.vehicleCustomVehicleFields,
    ]
  );
  const shouldDisplayVehicleFieldsTab =
    !!customVehicleFieldsExcludingDamagesWithoutGroup.length ||
    isEmpty(props.vehicleCustomVehicleFields);

  const commonTabbedSectionProps: Omit<TabbedSectionProps, "activeTab"> = {
    customVehicleFieldsGroups,
    vehicleId: props.vehicleId,
    customVehicleFieldsStatusAndInfo: shouldDisplayVehicleFieldsTab
      ? {
          status: customVehicleFieldsStatusAndInfo.status,
          infoMessage: getMessageFromStatusInfo(
            customVehicleFieldsStatusAndInfo.info
          ),
        }
      : null,
    damagesStatusAndInfo: {
      status: damagesStatusAndInfo.status,
      infoMessage: "",
    },
    customVehicleFieldsGroupStatusAndInfoById,
  };

  return isCustomVehicleFieldsLoading ? (
    <Skeleton />
  ) : (
    <Switch>
      <SentryRoute path={urls.backOffice.vehicleCardDamages()} exact>
        <TabbedSection {...commonTabbedSectionProps} activeTab="damages">
          <DamagesList
            isCommonStateUpdateEnabled={false}
            constantFilters={{
              selectedVehicle: {
                label: "",
                value: props.vehicleId,
              },
              vehicleTypes: [],
            }}
            onSuccessfulStatusChange={props.loadVehicleFieldValues}
            onCreateButtonClick={(currentUrl: string) =>
              history.push(
                urls.backOffice.damages.create({
                  label: props.vehiclePlateNumber,
                  value: props.vehicleId,
                }),
                {
                  referrer: currentUrl,
                }
              )
            }
          >
            {({
              listElement,
              statusFiltersElement,
              customVehicleFieldElement,
            }) => (
              <div style={{ overflowX: "auto" }}>
                <div style={{ minWidth: damagesContentMinWidth }}>
                  <BackOfficeLayout.Filters>
                    <FiltersRow>
                      {statusFiltersElement}
                      {customVehicleFieldElement}
                    </FiltersRow>
                  </BackOfficeLayout.Filters>
                  {listElement}
                </div>
              </div>
            )}
          </DamagesList>
        </TabbedSection>
      </SentryRoute>
      {customVehicleFieldsGroups.map((customVehicleFieldsGroup) => {
        const customVehicleFieldsForGroup =
          customVehicleFieldsWithGroupByGroupId[customVehicleFieldsGroup.id] ||
          [];
        return (
          <SentryRoute
            path={urls.backOffice.vehicleCardCustomVehicleFieldsGroup(
              props.vehicleId,
              customVehicleFieldsGroup.id
            )}
            key={customVehicleFieldsGroup.id}
          >
            <TabbedSection
              {...commonTabbedSectionProps}
              activeTab={customVehicleFieldsGroup.id}
            >
              <DisplayUpdateVehicleCustomFieldsForm
                reloadVehicleFieldValues={props.loadVehicleFieldValues}
                customFieldValues={props.vehicleCustomVehicleFields}
                vehicleId={props.vehicleId}
                customVehicleFieldsToDisplay={customVehicleFieldsForGroup}
              />
              <SentryRoute
                path={urls.backOffice.vehicleFieldWithGroupValueHistory()}
                exact
              >
                <VehicleFieldValueHistoryModalView />
              </SentryRoute>
            </TabbedSection>
          </SentryRoute>
        );
      })}

      <SentryRoute path={urls.backOffice.vehicleCard()}>
        {shouldDisplayVehicleFieldsTab ? (
          <TabbedSection
            {...commonTabbedSectionProps}
            activeTab="customVehicleFields"
          >
            <DisplayUpdateVehicleCustomFieldsForm
              reloadVehicleFieldValues={props.loadVehicleFieldValues}
              customFieldValues={props.vehicleCustomVehicleFields}
              vehicleId={props.vehicleId}
              customVehicleFieldsToDisplay={
                customVehicleFieldsExcludingDamagesWithoutGroup
              }
            />
            <SentryRoute
              path={urls.backOffice.vehicleFieldWithoutGroupValueHistory()}
              exact
            >
              <VehicleFieldValueHistoryModalView />
            </SentryRoute>
          </TabbedSection>
        ) : (
          <Redirect to={urls.backOffice.vehicleCardDamages(props.vehicleId)} />
        )}
      </SentryRoute>

      <SentryRoute path="*" exact>
        <Redirect to={urls.backOffice.vehicleCard(props.vehicleId)} />
      </SentryRoute>
    </Switch>
  );
}
