import { NoConnectionError } from "@inspecto/common";
import * as Sentry from "@sentry/react";
import { Alert, Button, Checkbox } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";

import { DropdownMenuDivider } from "../DropdownMenuDivider";
import { Select } from "./Select";
import {
  DefaultSelectOptionType,
  SelectOptionWithSubOptionsType,
  SelectProps,
} from "./SelectProps";

type SelectValue = { value: string; label: string };

export interface DefaultSelectOptionTypeStringLabel
  extends DefaultSelectOptionType {
  label: string;
}

export interface SelectOptionWithSubOptionsTypeStringLabel
  extends SelectOptionWithSubOptionsType {
  label: string;
}

export type SelectOptionTypeStringLabel =
  | DefaultSelectOptionTypeStringLabel
  | SelectOptionWithSubOptionsTypeStringLabel;

export interface SearchableAsyncSelectProps<TResponseItem>
  extends Omit<SelectProps<string>, "options" | "onChange" | "value"> {
  searchElements(
    searchQuery: string,
    isArchived: boolean
  ): Promise<TResponseItem[]>;
  getOptionsFromResponse: (
    items: TResponseItem[]
  ) => SelectOptionTypeStringLabel[];
  onChange?(option: SelectValue | null): void;
  value?: SelectValue | null;
  showArchivedCheckbox?: boolean;
}

export function SearchableAsyncSelect<TResponseItem>({
  getOptionsFromResponse,
  ...props
}: SearchableAsyncSelectProps<TResponseItem>) {
  const { t } = useTranslation();
  const [isSearching, setIsSearching] = useState(false);
  const [errorMessage, setErrorMessage] = useState<{
    message: string;
    subtitle: string;
  } | null>(null);
  const [includeArchived, setIncludeArchived] = useState(false);

  const [responseItems, setResponseItems] = useState<TResponseItem[]>([]);
  const options: SelectOptionTypeStringLabel[] = useMemo(
    () => getOptionsFromResponse(responseItems),
    [getOptionsFromResponse, responseItems]
  );
  const [searchQuery, setSearchQuery] = useState("");

  const [searchTerm] = useDebounce(searchQuery, 300);

  const {
    searchElements,
    onChange,
    value,
    showArchivedCheckbox,
    ...restProps
  } = props;

  const runSearch = useCallback(async () => {
    setIsSearching(true);

    try {
      const response = await searchElements(searchTerm, includeArchived);
      setResponseItems(response);
      setErrorMessage(null);
    } catch (e) {
      if (e instanceof NoConnectionError) {
        setErrorMessage({
          message: t("errors.noInternet.message"),
          subtitle: t("errors.noInternet.subtitle"),
        });
      } else {
        setErrorMessage({
          message: t("errors.somethingWentWrong"),
          subtitle: t("errors.pleaseTryAgainOrContactAdministrator"),
        });
        Sentry.captureException(e);
      }
    } finally {
      setIsSearching(false);
    }
  }, [searchElements, searchTerm, includeArchived, t]);

  const findOption = useCallback(
    (
      selectOptions: SelectOptionTypeStringLabel[],
      selectedValue: SelectValue["value"]
    ): SelectOptionTypeStringLabel | undefined => {
      for (const option of selectOptions) {
        if ("options" in option && Array.isArray(option.options)) {
          const found = findOption(option.options, selectedValue);
          if (found) {
            return found;
          }
        } else if (option.value === selectedValue) {
          return option;
        }
      }
      return undefined;
    },
    []
  );

  useEffect(() => {
    runSearch();
  }, [runSearch]);

  const optionsWithSelection = useMemo(() => {
    const newOptions = [];

    if (value && !findOption(options, value.value) && !searchTerm.length) {
      newOptions.push({
        label: t("searchableAsyncSelect.currentlySelected"),
        options: [value],
      });
    }

    newOptions.push(...options);

    return newOptions;
  }, [findOption, options, searchTerm.length, t, value]);

  return (
    <Select<string>
      allowClear
      showSearch
      value={value ? value.value : undefined}
      options={optionsWithSelection}
      loading={isSearching}
      onSearch={setSearchQuery}
      dropdownRender={(menu) =>
        errorMessage ? (
          <div style={{ padding: "5px 18px" }}>
            <Alert
              type="error"
              message={errorMessage.message}
              description={errorMessage.subtitle}
              action={
                <Button
                  size="small"
                  type="primary"
                  onClick={() => runSearch()}
                  style={{ height: 24, padding: "0 7px", borderRadius: 2 }} // Revert to default AntD Small default
                >
                  {t("retry")}
                </Button>
              }
            />
          </div>
        ) : (
          <>
            {showArchivedCheckbox ? (
              <DropdownMenuDivider
                topContent={
                  <Checkbox
                    checked={includeArchived}
                    onChange={(e) => setIncludeArchived(e.target.checked)}
                  >
                    {t("includeDeletedElements")}
                  </Checkbox>
                }
              >
                {menu}
              </DropdownMenuDivider>
            ) : (
              menu
            )}
          </>
        )
      }
      onClear={() => {
        onChange?.(null);
      }}
      onChange={(selectedValue) => {
        const selectedOption = findOption(options, selectedValue);
        if (!selectedOption) {
          return;
        }

        onChange?.({ value: selectedValue, label: selectedOption.label });
        setSearchQuery("");
      }}
      {...restProps}
    />
  );
}
