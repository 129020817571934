import { Select, Tag, Typography } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { tagColorByDamageStatus } from "../common";
import { Damage } from "../models";

export const damagesStatusSelectWidth = 200;

export function DamageStatusSelect(props: {
  status?: Damage["status"];
  onStatusChange?: (newStatus: Damage["status"]) => Promise<void>;
}) {
  const { t } = useTranslation("backoffice");

  const statusOptions = useMemo<
    { label: string; value: Damage["status"] }[]
  >(() => {
    return [
      { label: t("damages.statuses.new"), value: "new" },
      { label: t("damages.statuses.ignored"), value: "ignored" },
      {
        label: t("damages.statuses.forRepairNotOk"),
        value: "for_repair_not_ok",
      },
      {
        label: t("damages.statuses.forRepairWarning"),
        value: "for_repair_warning",
      },
      { label: t("damages.statuses.invalid"), value: "invalid" },
      { label: t("damages.statuses.resolved"), value: "resolved" },
    ];
  }, [t]);

  const [isSaving, setIsSaving] = useState(false);

  return (
    <Select<Damage["status"]>
      style={{ width: damagesStatusSelectWidth }}
      size="small"
      loading={isSaving}
      onClick={(e) => e.stopPropagation()}
      defaultValue="new"
      value={props.status}
      onChange={async (newStatus) => {
        setIsSaving(true);
        if (props.onStatusChange) {
          await props.onStatusChange(newStatus);
        }
        setIsSaving(false);
      }}
    >
      {statusOptions.map((option) => (
        <Select.Option value={option.value} key={option.value}>
          <Tag
            style={{ maxWidth: 140 }}
            color={tagColorByDamageStatus[option.value]}
          >
            <Typography.Text ellipsis={{ tooltip: true }}>
              {option.label}
            </Typography.Text>
          </Tag>
        </Select.Option>
      ))}
    </Select>
  );
}
