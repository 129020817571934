import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { isNoConnectionError, showNoConnectionNotification } from "src/utils";

import { backOfficeApi } from "../api";
import { TemplateTagsRequirementsByVehicleType } from "../models";

type UseTemplateTagsRequirementsByVehicleType = [
  TemplateTagsRequirementsByVehicleType | null,
  () => void
];

export function useTemplateTagsRequirementsByVehicleType(): UseTemplateTagsRequirementsByVehicleType {
  const { t } = useTranslation("translation");
  const [
    templateTagsRequirementsByVehicleType,
    setTemplateTagsRequirementsByVehicleType,
  ] = useState<TemplateTagsRequirementsByVehicleType | null>(null);

  const fetchTemplateTagsRequirementsByVehicleType = useCallback(async () => {
    try {
      const templateTagsRequirementsByVehicleType =
        await backOfficeApi.getTemplateTagsRequirementsByVehicleType();
      setTemplateTagsRequirementsByVehicleType(
        templateTagsRequirementsByVehicleType
      );
    } catch (e) {
      if (isNoConnectionError(e)) {
        showNoConnectionNotification(t);
      } else {
        throw e;
      }
    }
  }, [t]);

  useEffect(() => {
    fetchTemplateTagsRequirementsByVehicleType();
  }, [fetchTemplateTagsRequirementsByVehicleType]);

  return [
    templateTagsRequirementsByVehicleType,
    fetchTemplateTagsRequirementsByVehicleType,
  ];
}
