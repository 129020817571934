import axios from "axios";
import { isObject } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  isNoConnectionError,
  sendErrorToSentryIfNotNetworkError,
  showNoConnectionNotification,
} from "src/utils";

import { DEPLOYMENT_INFO_CHECK_INTERVAL } from "./config";

interface DeploymentInfoResponse {
  isInDeployment: boolean;
}

function isDeploymentInfoResponse(
  data: unknown
): data is DeploymentInfoResponse {
  return isObject(data) && data !== null && "isInDeployment" in data;
}

export function useDeploymentInfoHook(): boolean {
  const [isInDeployment, setIsInDeployment] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    async function checkIfInDeployment(): Promise<boolean | null> {
      try {
        const { data } = await axios.get<unknown>("/api/deployment-info/");
        if (!isDeploymentInfoResponse(data)) {
          throw new Error(
            `Wrong deployment-info content: ${JSON.stringify(data)}`
          );
        }
        setIsInDeployment(data.isInDeployment);
      } catch (e) {
        if (isNoConnectionError(e)) {
          showNoConnectionNotification(t);
          return null;
        }
        sendErrorToSentryIfNotNetworkError("Deployment Info check failed", e);
      }
      return null;
    }
    checkIfInDeployment();
    const interval = setInterval(
      checkIfInDeployment,
      DEPLOYMENT_INFO_CHECK_INTERVAL
    );

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [t]);

  return isInDeployment;
}
