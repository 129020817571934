import type { SelectProps as AntdSelectProps } from "antd";
import { DefaultOptionType } from "rc-select/lib/Select";

export type DefaultSelectOptionType = DefaultOptionType;

export interface SelectOptionWithSubOptionsType
  extends DefaultSelectOptionType {
  options: DefaultOptionType[]; // Not sure why this is not in the original type even though it's used in the antd code
}

export type SelectOptionType =
  | DefaultSelectOptionType
  | SelectOptionWithSubOptionsType;

export function isSelectOptionWithSubOptionsType(
  option: SelectOptionType
): option is SelectOptionWithSubOptionsType {
  return (option as SelectOptionWithSubOptionsType).options !== undefined;
}

export interface SelectProps<TValue>
  extends Omit<AntdSelectProps<TValue>, "options"> {
  label?: string;
  isRequired?: boolean;
  helpTooltip?: string;
  onSearch?: (searchQuery: string) => void;
  noMargin?: boolean;
  options: SelectOptionType[];
}
