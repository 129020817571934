import "react-app-polyfill/stable";
import * as Sentry from "@sentry/react";
import { createBrowserHistory } from "history";
import { enableMapSet } from "immer";
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";

import "src/index.less";
import App from "src/App";
import reportWebVitals from "src/reportWebVitals";
import { isDevelopment } from "src/utils";

enableMapSet();
const history = createBrowserHistory();

if (!isDevelopment()) {
  Sentry.init({
    dsn: "https://8b111acfdd8043bea715c51706321b1e@o970631.ingest.sentry.io/5922060",
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({ history: history }),
    ],
    tracesSampleRate: 0.0,
    ignoreErrors: [
      "ApiError",
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed with undelivered notifications.",
      "getBoundingClientRect",
      "Can't find variable: gmo", // Chromium bug: https://issues.chromium.org/issues/396043331
      "ServiceWorker is in redundant state",
      "instantweb.min.js", // MiuiBrowser only, TypeError: element.className.split is not a function
    ],
  });
}
ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
