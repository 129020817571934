import {
  ArrowRightOutlined,
  CalendarOutlined,
  DownOutlined,
  FileDoneOutlined,
  HistoryOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useCompany } from "@inspecto/common";
import {
  Button,
  message,
  Space,
  TableColumnType,
  Form,
  Dropdown,
  Typography,
  Skeleton,
  Table,
  Row,
  Col,
  Tooltip,
  Select,
} from "antd";
import dayjs from "dayjs";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import { useAuthentication } from "src/authentication";
import {
  ChargeEmployeeIconButton,
  MultiLineTag,
  MultiSelect,
  SearchableAsyncSelect,
} from "src/components";
import { urls } from "src/urls";
import { fullNameReversed, getPlateNumber } from "src/utils";

import { backOfficeApi } from "../api";
import { tagColorByChargedEmployeeStatus } from "../common";
import { getDamageStatusOptions } from "../common";
import {
  FilterItem,
  ListView,
  VehicleGroupsFilterItem,
  StorageUploadedPhoto,
  CompanyDepartmentsMultiSelectFilterItem,
} from "../components";
import { ChargedEmployeeComponent } from "../components/ChargedEmployeeComponent";
import {
  damagesStatusSelectWidth,
  DamageStatusSelect,
} from "../components/DamageStatusSelect";
import { CommonViewState } from "../contexts";
import {
  useChargedEmployeeModal,
  useVehicleGroups,
  useVehicleTypes,
} from "../hooks";
import { useDamagesCustomVehicleFields } from "../hooks/useDamagesCustomVehicleFields";
import {
  ChargedEmployeeStatus,
  Damage,
  ListEmployee,
  SimpleVehicle,
} from "../models";
import { DamageHistoryModal } from "./damages";

const PhotoAndLabelWrapper = styled.div`
  display: flex;
`;

const MarginTop8 = styled.div`
  margin-top: 8px;
`;

const ExpandedRowItem = styled.div`
  border: 1px solid ${(props) => props.theme.colors.whitePanelBorderColor};
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
`;

const DropdownContent = styled.div`
  background: #fff;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  padding: 12px 16px;
  border-radius: 4px;
`;

export const damagesContentMinWidth = 1100;

interface Props {
  constantFilters?: Partial<CommonViewState["damagesView"]>;
  isCommonStateUpdateEnabled?: boolean;
  onCreateButtonClick?: (currentUrl: string) => void;
  onSuccessfulStatusChange?: () => void;
  children: (props: {
    listElement: ReactNode;
    statusFiltersElement: ReactNode;
    selectedVehicleElement: ReactNode;
    selectedEmployeeElement: ReactNode;
    customVehicleFieldElement: ReactNode;
    vehicleTypeElement: ReactNode;
    vehicleGroupsElement: ReactNode;
    companyDepartmentsElement: ReactNode;
  }) => JSX.Element;
}

export function DamagesList({
  isCommonStateUpdateEnabled = true,
  onCreateButtonClick = undefined,
  constantFilters = {},
  onSuccessfulStatusChange = () => null,
  ...props
}: Props) {
  const currentUrl = useRouteMatch().url;
  const history = useHistory();
  const { t } = useTranslation("backoffice");
  const [filtersForm] = Form.useForm();
  const { vehicleTypeOptions, isLoadingVehicleTypes } = useVehicleTypes();
  const { vehicleGroupOptions, isLoadingVehicleGroups } = useVehicleGroups();
  const [damageHistoryModalState, setDamageHistoryModalState] = useState<{
    damageId: string;
    damageLabel: string;
  } | null>(null);

  const { user } = useAuthentication();
  const { allowManagingCompanyDepartments } = useCompany();

  const {
    isLoadingDamagesCustomVehicleFields,
    damagesCustomVehicleFieldOptions,
  } = useDamagesCustomVehicleFields();

  const statusOptions = useMemo<{ label: string; value: Damage["status"] }[]>(
    () => getDamageStatusOptions(t),
    [t]
  );

  const dataGetter = useMemo(
    () => async (damagesView: CommonViewState["damagesView"]) => {
      const { tableParams, ...filters } = damagesView;
      const filtersToUse = {
        ...filters,
        ...constantFilters,
      };

      return await backOfficeApi.getDamages({
        tableParams,
        customVehicleField: filtersToUse.customVehicleField || "",
        vehicle: filtersToUse.selectedVehicle?.value || "",
        statuses: filtersToUse.statusFilters,
        employee: filtersToUse.selectedEmployee?.value || "",
        vehicleTypes: filtersToUse.vehicleTypes,
        vehicleGroups: filtersToUse.vehicleGroups,
        excludeVehicleGroups: filtersToUse.excludeVehicleGroups,
        companyDepartments: filtersToUse.companyDepartments,
        excludeCompanyDepartments: filtersToUse.excludeCompanyDepartments,
      });
    },
    [constantFilters]
  );

  const [chargedEmployeeModal, setChargedEmployeeState] =
    useChargedEmployeeModal(() => filtersForm.submit());

  const columns = useMemo<TableColumnType<Damage>[]>(() => {
    return [
      {
        width: "auto",
        dataIndex: ["createdAt"],
        title: `${t("damages.fieldNames.photo")} / ${t(
          "damages.fieldNames.label"
        )} / ${t("damages.fieldNames.createdAt")}`,
        render: (_, damage: Damage) => (
          <PhotoAndLabelWrapper>
            <StorageUploadedPhoto photo={damage.photo} />
            <div>
              <Typography.Text strong>{damage.label}</Typography.Text>
              <MarginTop8>
                <MultiLineTag>
                  {damage.customVehicleFieldObject.label}
                </MultiLineTag>
              </MarginTop8>
              {damage.createdAt && (
                <MarginTop8>
                  <Space size="small">
                    <CalendarOutlined />
                    <span>
                      {dayjs(damage.createdAt).format("DD.MM.YYYY, HH:mm")}
                    </span>
                  </Space>
                </MarginTop8>
              )}
            </div>
          </PhotoAndLabelWrapper>
        ),
      },
      {
        width: 140,
        dataIndex: ["vehicleObject", "plateNumber"],
        title: t("damages.fieldNames.plateNumber"),
        render: (plateNumber: string, damage: Damage) => (
          <>
            <div>{plateNumber}</div>
            <div>
              {damage.vehicleObject.vehicleGroups.map((vehicleGroup) => (
                <MultiLineTag style={{ marginTop: 4 }} key={vehicleGroup.id}>
                  {vehicleGroup.label}
                </MultiLineTag>
              ))}
            </div>
          </>
        ),
      },
      {
        width: damagesStatusSelectWidth + 20,
        dataIndex: "status",
        title: t("damages.fieldNames.status"),
        render: (status: Damage["status"], damage) =>
          status && (
            <DamageStatusSelect
              status={status}
              onStatusChange={async (status) => {
                await backOfficeApi.updateDamage(damage.id, {
                  status,
                });
                filtersForm.submit();
                onSuccessfulStatusChange();
                message.success(t("damages.damageSavedSuccessfully"));
              }}
            />
          ),
      },
      Table.EXPAND_COLUMN,
      {
        width: 220,
        dataIndex: "chargedEmployeeObject",
        title: t("damages.charges.fieldName"),
        render: (charge: Damage["chargedEmployeeObject"], damage: Damage) =>
          charge ? (
            <>
              <div>
                <Typography.Text
                  strong
                  delete={charge.status === ChargedEmployeeStatus.CANCELED}
                >
                  {charge.amount}&nbsp;
                  {charge.currency}
                </Typography.Text>
              </div>
              <MultiLineTag
                style={{
                  marginTop: 4,
                }}
                color={tagColorByChargedEmployeeStatus[charge.status]}
              >
                {t(`chargedEmployeeStatus.fullName.${charge.status}`)}
              </MultiLineTag>
            </>
          ) : (
            <Typography.Text type="secondary">
              {t("employeeCharges.noCharge")}
            </Typography.Text>
          ),
      },
      {
        key: "action",
        width: 250,
        align: "right",
        render: (value, damage) => (
          <Space size="small" onClick={(e) => e.stopPropagation()}>
            <Tooltip title={t("vehicleView.customFields.historyAndCharges")}>
              <Button
                icon={<HistoryOutlined />}
                shape="circle"
                size="small"
                onClick={() =>
                  setDamageHistoryModalState({
                    damageId: damage.id,
                    damageLabel: damage.label,
                  })
                }
                title={t("vehicleView.customFields.historyAndCharges")}
              />
            </Tooltip>

            <Dropdown
              dropdownRender={(menu) =>
                damage.createdInProtocol ? (
                  menu
                ) : (
                  <DropdownContent>
                    <Typography.Text disabled>
                      {t("damages.damageNotSavedInProtocol")}
                    </Typography.Text>
                  </DropdownContent>
                )
              }
              menu={{
                items: [
                  {
                    key: "1",
                    label: t("protocol"),
                    icon: <FileDoneOutlined />,
                    onClick: () => {
                      if (damage.createdInProtocol) {
                        history.push(
                          urls.backOffice.protocol(damage.createdInProtocol)
                        );
                      }
                    },
                  },
                ],
              }}
            >
              <Button size="small">
                {t("tableActions.goTo")} <DownOutlined />
              </Button>
            </Dropdown>

            <Button
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                history.push(urls.backOffice.damages.edit(damage.id), {
                  referrer: currentUrl,
                });
              }}
              icon={<ArrowRightOutlined />}
            >
              {t("tableActions.edit")}
            </Button>
          </Space>
        ),
      },
    ];
  }, [t, filtersForm, onSuccessfulStatusChange, history, currentUrl]);

  const searchVehicles = useCallback(
    async (query, includeArchived: boolean) => {
      const vehicles = await backOfficeApi.getSimpleVehiclesList({
        tableParams: {
          pageNumber: 1,
          sortByField: "",
        },
        plateNumber: query,
        includeArchived,
      });

      return vehicles.results;
    },
    []
  );

  const searchEmployees = useCallback(async (query, includeArchived) => {
    const employees = await backOfficeApi.getAllEmployees({
      tableParams: {
        pageNumber: 1,
      },
      searchTerm: query,
      searchIn: "name",
      status: "all",
      includeInactive: includeArchived,
      userRoles: [],
      emailFilter: "all",
    });
    return employees.results;
  }, []);

  const getOptionsFromListEmployees = useCallback(
    (employees: ListEmployee[]) =>
      employees.map((employee) => ({
        value: employee.id,
        label: fullNameReversed(employee),
      })),
    []
  );

  const getOptionsFromSimpleVehicles = useCallback(
    (vehicles: SimpleVehicle[]) =>
      vehicles.map((vehicle) => ({
        value: vehicle.id,
        label: getPlateNumber(vehicle),
      })),
    []
  );

  const statusFiltersElement = (
    <FilterItem name="statusFilters" $inputWidth="long">
      <MultiSelect<Damage["status"]>
        options={statusOptions}
        placeholder={t("damages.fieldNames.status")}
        noMargin
        style={{ width: 450 }}
        maxTagCount="responsive"
      />
    </FilterItem>
  );

  const selectedVehicleElement = (
    <FilterItem name="selectedVehicle" $inputWidth="medium">
      <SearchableAsyncSelect<SimpleVehicle>
        searchElements={searchVehicles}
        getOptionsFromResponse={getOptionsFromSimpleVehicles}
        showArchivedCheckbox
        placeholder={t("damages.fieldNames.vehicles")}
        noMargin
      />
    </FilterItem>
  );

  const selectedEmployeeElement = (
    <FilterItem name="selectedEmployee" $inputWidth="medium">
      <SearchableAsyncSelect<ListEmployee>
        searchElements={searchEmployees}
        getOptionsFromResponse={getOptionsFromListEmployees}
        showArchivedCheckbox
        placeholder={t("damages.fieldNames.chargedEmployee")}
        noMargin
      />
    </FilterItem>
  );

  const customVehicleFieldElement = (
    <FilterItem name="customVehicleField" $inputWidth="long">
      <Select
        allowClear
        loading={isLoadingDamagesCustomVehicleFields}
        options={damagesCustomVehicleFieldOptions}
        placeholder={t("damages.fieldNames.customVehicleField")}
      />
    </FilterItem>
  );

  const vehicleTypeElement = (
    <FilterItem name="vehicleTypes" $inputWidth="medium">
      <MultiSelect
        options={vehicleTypeOptions}
        style={{ width: 250 }}
        noMargin
        placeholder={t("settings.customVehicleFields.vehicleTypes")}
      />
    </FilterItem>
  );

  const vehicleGroupsElement = (
    <VehicleGroupsFilterItem options={vehicleGroupOptions} />
  );

  const vehicleDepartmentsElement = allowManagingCompanyDepartments && user && (
    <CompanyDepartmentsMultiSelectFilterItem
      width={500}
      options={user.allowedCompanyDepartments.map((companyDepartment) => ({
        label: companyDepartment.name,
        value: companyDepartment.id,
      }))}
    />
  );

  return isLoadingVehicleGroups || isLoadingVehicleTypes ? (
    <Skeleton />
  ) : (
    <>
      <ListView
        sortedTableProps={["createdAt", "vehicleObject_plateNumber", "status"]}
        isCommonStateUpdateEnabled={isCommonStateUpdateEnabled}
        dataGetter={dataGetter}
        columns={columns}
        commonStateViewName="damagesView"
        filtersForm={filtersForm}
        stickyTableActions={
          onCreateButtonClick
            ? [
                {
                  buttonLabel: t("add"),
                  icon: <PlusOutlined />,
                  callback: async () => onCreateButtonClick(currentUrl),
                },
              ]
            : undefined
        }
        expandable={{
          expandRowByClick: true,
          expandedRowRender: (damage) => (
            <Row>
              <Col xs={12} offset={12}>
                <ExpandedRowItem>
                  <div style={{ marginBottom: 10 }}>
                    <Typography.Text strong>
                      {t("damages.fieldNames.chargedEmployee")}
                    </Typography.Text>
                  </div>
                  <Space
                    style={{ justifyContent: "space-between", width: "100%" }}
                  >
                    <div>
                      <ChargedEmployeeComponent
                        chargedEmployee={damage.chargedEmployeeObject}
                        showEmpty
                        showStatusTag
                      />
                    </div>
                    <span>
                      <ChargeEmployeeIconButton
                        mode="text"
                        onClick={(event) => {
                          event.stopPropagation();
                          setChargedEmployeeState({
                            damageId: damage.id,
                            initialValues: damage.chargedEmployeeObject
                              ? {
                                  ...damage.chargedEmployeeObject,
                                }
                              : {
                                  comment: damage.label,
                                },
                            chargedEmployeeId:
                              damage.chargedEmployeeObject?.id || null,
                          });
                        }}
                      />
                    </span>
                  </Space>
                </ExpandedRowItem>
              </Col>
            </Row>
          ),
        }}
      >
        {({ listElement }) =>
          props.children({
            listElement: (
              <>
                {listElement}
                {chargedEmployeeModal}
              </>
            ),
            statusFiltersElement,
            selectedVehicleElement,
            selectedEmployeeElement,
            customVehicleFieldElement,
            vehicleTypeElement,
            vehicleGroupsElement,
            companyDepartmentsElement: vehicleDepartmentsElement,
          })
        }
      </ListView>
      {damageHistoryModalState && (
        <DamageHistoryModal
          damageId={damageHistoryModalState.damageId}
          damageLabel={damageHistoryModalState.damageLabel}
          onCancel={() => setDamageHistoryModalState(null)}
        />
      )}
    </>
  );
}
