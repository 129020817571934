import { useCallback } from "react";

import {
  antdOrderToOrderedDataIndexString,
  getStringDataIndex,
} from "src/utils/antdSortingUtils";

import { BasicTable, BasicTableProps } from "./BasicTable";

export interface FormItemBasicTableProps<TRecordType>
  extends Omit<BasicTableProps<TRecordType>, "pagination" | "onChange"> {
  onChange?: (changedParams: BasicTableFormItemTableParams) => void;
  tableParams?: BasicTableFormItemTableParams;
  total: number;
}

export interface BasicTableFormItemTableParams {
  pageNumber: number;
  sortByField?: string;
}

export function FormItemBasicTable<TRecordType extends object>(
  props: FormItemBasicTableProps<TRecordType>
): JSX.Element {
  const { onChange, tableParams } = props;
  const privateOnChange = useCallback<
    NonNullable<BasicTableProps<TRecordType>["onChange"]>
  >(
    (pagination, filters, sorter, extra) => {
      if (Array.isArray(sorter)) {
        // Multi-sort, which is not supported by us
        return;
      }
      const dataIndexString = getStringDataIndex(sorter.field);
      const sortByField = antdOrderToOrderedDataIndexString(
        sorter.order,
        dataIndexString
      );
      onChange?.({ pageNumber: 1, sortByField });
    },
    [onChange]
  );

  const onPageChange = useCallback(
    (pageNumber: number) => {
      onChange?.({ ...tableParams, pageNumber });
    },
    [onChange, tableParams]
  );

  return (
    <BasicTable
      {...props}
      onChange={privateOnChange}
      pagination={
        props.tableParams?.pageNumber
          ? {
              total: props.total,
              current: props.tableParams.pageNumber,
              onChange: onPageChange,
            }
          : false
      }
    />
  );
}
