import { Select, SelectProps } from "antd";
import { useTranslation } from "react-i18next";

import { FilterItem } from "../ListView";
import { DividerCheckboxFilterItem } from "./DividerCheckboxFilterItem";

export function CompanyDepartmentsMultiSelectFilterItem(
  props: Pick<SelectProps<string>, "options"> & { width?: number }
) {
  const { t } = useTranslation("backoffice");
  return (
    <FilterItem name="companyDepartments" $inputWidth="medium">
      <Select
        mode="multiple"
        allowClear
        showArrow
        optionFilterProp="label"
        style={{ width: props.width || 350 }}
        options={props.options}
        placeholder={t("settings.companyDepartments.listLabel")}
        dropdownRender={(menu) => (
          <DividerCheckboxFilterItem
            checkboxLabel={t(
              "assignUsersList.excludeUsersWithSelectedCompanyDepartments"
            )}
            filterItemName="excludeCompanyDepartments"
          >
            {menu}
          </DividerCheckboxFilterItem>
        )}
      />
    </FilterItem>
  );
}
