import { useTranslation } from "react-i18next";

import { backOfficeApi } from "../../api";
import { useProtocolTypes } from "../../hooks";
import { useUserRoleOptions } from "../../hooks/useUserRoleOptions";
import {
  DatePeriodFormState,
  formatDatePeriod,
  getDatePeriodFormInitialValues,
} from "./common";
import { DatePeriodFormItem, ReportFormLayout } from "./components";
import { ExpandableProtocolTypesSelectorFormItem } from "./components/ExpandableProtocolTypesSelectorFormItem";
import { UserRolesFormItem } from "./components/UserRolesFormItem";

interface NumberOfProtocolsPerUserReportFormState extends DatePeriodFormState {
  protocolTypes: string[];
  userRoles: string[];
}

export function NumberOfProtocolsPerUserReportView() {
  const { t } = useTranslation("backoffice");
  const { protocolTypes, protocolTypeOptions, isLoadingProtocolTypes } =
    useProtocolTypes();
  const { isLoadingUserRoles, userRoleOptions } = useUserRoleOptions();

  return (
    <ReportFormLayout<NumberOfProtocolsPerUserReportFormState>
      reportTitle={t("reportsView.reports.numberOfProtocolsPerUser")}
      startDownloading={async (data) =>
        backOfficeApi.reports.startDownloadProtocolsPerUser({
          ...formatDatePeriod(data),
          protocolTypes:
            data.protocolTypes.length === protocolTypes.length
              ? []
              : data.protocolTypes,
          userRoles:
            data.userRoles.length === userRoleOptions.length
              ? []
              : data.userRoles,
        })
      }
      formInitialValues={
        isLoadingProtocolTypes || isLoadingUserRoles
          ? "loading"
          : {
              protocolTypes,
              ...getDatePeriodFormInitialValues(),
              userRoles: [],
            }
      }
    >
      <DatePeriodFormItem />
      <UserRolesFormItem userRolesOptions={userRoleOptions} />
      <ExpandableProtocolTypesSelectorFormItem
        protocolTypeOptions={protocolTypeOptions}
        isLoadingProtocolTypes={isLoadingProtocolTypes}
      />
    </ReportFormLayout>
  );
}
