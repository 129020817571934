import { Select as AntdSelect, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { InputWrapper } from "../InputWrapper";
import { isSelectOptionWithSubOptionsType, SelectProps } from "./SelectProps";

export function Select<T>({ options, ...props }: SelectProps<T>) {
  const { t } = useTranslation("translation");
  const { noMargin, ...restProps } = props;

  return (
    <InputWrapper
      label={props.label}
      isRequired={props.isRequired}
      helpTooltip={props.helpTooltip}
      noMargin={noMargin}
    >
      <AntdSelect<T>
        {...restProps}
        showArrow
        defaultValue={props.defaultValue || undefined}
        value={props.value || undefined}
        placeholder={props.placeholder || t("select")}
        notFoundContent={t("noOptions")}
        filterOption={!props.onSearch}
        options={options?.map((option) => ({
          key: option.value,
          ...option,
          label: (
            <Typography.Text ellipsis={{ tooltip: true }}>
              {option.label}
            </Typography.Text>
          ),
          ...(isSelectOptionWithSubOptionsType(option)
            ? {
                options: option.options.map((subOption) => ({
                  key: subOption.value,
                  ...subOption,
                  label: (
                    <Typography.Text ellipsis={{ tooltip: true }}>
                      {subOption.label}
                    </Typography.Text>
                  ),
                })),
              }
            : {}),
        }))}
      />
    </InputWrapper>
  );
}
