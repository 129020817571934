import * as Sentry from "@sentry/react";
import { Route, Switch } from "react-router-dom";

import { urls } from "src/urls";

import { CreateEditDamageView } from "./CreateEditDamageView";
import { DamagesListView } from "./DamagesListView";

const SentryRoute = Sentry.withSentryRouting(Route);
export function DamagesRouter() {
  return (
    <Switch>
      <SentryRoute path={urls.backOffice.damages.list()} exact>
        <DamagesListView />
      </SentryRoute>
      <SentryRoute path={urls.backOffice.damages.create()} exact>
        <CreateEditDamageView isEditing={false} />
      </SentryRoute>
      <SentryRoute path={urls.backOffice.damages.edit()}>
        <CreateEditDamageView isEditing={true} />
      </SentryRoute>
    </Switch>
  );
}
