import { useCompany } from "@inspecto/common";
import { Form, message, Select } from "antd";
import { useTranslation } from "react-i18next";

import { ModalForm } from "src/components";

import { backOfficeApi } from "../../../api";
import { Option } from "../../../contexts";
import {
  CreateUpdateVehicle,
  RetrieveVehicle,
  VehicleType,
} from "../../../models";
import { LinkingVehicleFormItems } from "./LinkingVehicleFormItems";
import {
  StandardVehicleFormItems,
  StandardVehicleFormItemsProps,
} from "./StandardVehicleFormItems";

interface Props {
  currentVehicleValue: RetrieveVehicle;
  vehicleGroupsOptions: StandardVehicleFormItemsProps["vehicleGroupsOptions"];
  vehicleFieldsPresetOptions: StandardVehicleFormItemsProps["vehicleFieldsPresetOptions"];
  vehicleTypeOptions: { label: string; value: string }[];
  reloadVehicleAndFieldValues: () => void;
  closeModal: () => void;
}

export function UpdateVehicleFormModal(props: Props): JSX.Element {
  const { t } = useTranslation("backoffice");

  const { allowLinkingVehicles } = useCompany();

  const initialValues: CreateUpdateVehicle = {
    ...props.currentVehicleValue,
    companyDepartment: props.currentVehicleValue.companyDepartment.id,
    assignedUser: props.currentVehicleValue.assignedUsers.reduce<
      CreateUpdateVehicle["assignedUser"]
    >(
      (acc, curr) =>
        acc || { value: curr.id, label: `${curr.firstName} ${curr.lastName}` },
      null
    ),
    linkTypeValues: props.currentVehicleValue.linkTypes.reduce<
      CreateUpdateVehicle["linkTypeValues"]
    >(
      (acc, curr) => ({
        ...acc,
        [curr.id]: curr.linkedVehicles.reduce<Option | null>(
          (vehicleAcc, vehicle) =>
            vehicleAcc || { label: vehicle.plateNumber, value: vehicle.id },
          null
        ),
      }),
      {}
    ),
  };

  const [form] = Form.useForm();

  const selectedVehicleTypeId = Form.useWatch("vehicleType", form);

  return (
    <ModalForm<CreateUpdateVehicle, CreateUpdateVehicle>
      open
      formTitle={t("vehicleView.editModalTitle")}
      cancelButtonText={t("cancel")}
      confirmButtonText={t("save")}
      closeModal={props.closeModal}
      form={form}
      initialValues={initialValues}
      saveCallback={async (values) => {
        return await backOfficeApi.updateVehicle(props.currentVehicleValue.id, {
          ...values,
          vehicleFieldsPreset: values.vehicleFieldsPreset || null,
        });
      }}
      onSuccessfulSave={() => {
        props.reloadVehicleAndFieldValues();
        message.success(t("vehicleSuccessfullySaved"));
      }}
    >
      <Form.Item
        required
        name="vehicleType"
        label={t("vehicleType")}
        htmlFor="vehicle-form-select-vehicle-type"
      >
        <Select<VehicleType["id"]>
          id="vehicle-form-select-vehicle-type"
          options={props.vehicleTypeOptions}
          style={{ maxWidth: 300 }}
        />
      </Form.Item>
      <StandardVehicleFormItems
        vehicleGroupsOptions={props.vehicleGroupsOptions}
        vehicleFieldsPresetOptions={props.vehicleFieldsPresetOptions}
      />

      {allowLinkingVehicles && (
        <LinkingVehicleFormItems vehicleTypeId={selectedVehicleTypeId} />
      )}
    </ModalForm>
  );
}
