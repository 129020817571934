import * as Sentry from "@sentry/react";
import { Route, Switch } from "react-router-dom";

import { urls } from "src/urls";

import { CreateVehicleView } from "./CreateVehicleView";
import { VehicleCardView } from "./VehicleCardView";
import { VehiclesListView } from "./VehiclesListView";

const SentryRoute = Sentry.withSentryRouting(Route);
export function VehiclesRouter() {
  return (
    <Switch>
      <SentryRoute path={urls.backOffice.vehicles()} exact>
        <VehiclesListView />
      </SentryRoute>
      <SentryRoute path={urls.backOffice.createVehicle()} exact>
        <CreateVehicleView />
      </SentryRoute>
      <SentryRoute path={urls.backOffice.vehicleCard()}>
        <VehicleCardView />
      </SentryRoute>
    </Switch>
  );
}
