import { ApiError, Company, CompanyContextProvider } from "@inspecto/common";
import {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { NoConnectionResultPage } from "src/components";
import { Spinner } from "src/components/Spinner";
import { languageContext } from "src/locales/LanguageProvider";
import {
  api,
  isNoConnectionError,
  showNoConnectionNotification,
} from "src/utils";
import { CompanyIsNotActiveErrorPage } from "src/views";

import { CompanySwitcher } from "./CompanySwitcher";
import { redirectToAnotherSubdomain } from "./utils";

export function CompanyProvider(props: PropsWithChildren<{}>) {
  const domain = window.location.hostname.split(".")[0] || "";
  const [companyLoadingState, setCompanyLoadingState] = useState<
    "loading" | "loaded" | "error"
  >("loading");
  const [company, setCompany] = useState<Company | null>(null);
  const { dispatch: languageDispatch } = useContext(languageContext);

  const loadCompany = useCallback(
    async (subdomain: string) => {
      try {
        const company = await api.company.getBySubdomain(subdomain);
        setCompany(company);
        languageDispatch({
          type: "setLanguage",
          payload: { language: company.language },
        });
        setCompanyLoadingState("loaded");
      } catch (e) {
        if (isNoConnectionError(e)) {
          // We do not provide the "t" function to avoid a circular dependency and infinite loop
          // ("t" depends on the company language)
          showNoConnectionNotification();
        } else if (e instanceof ApiError) {
          if (e.statusCode === 404) {
            setCompanyLoadingState("loaded");
            return;
          }
        }
        setCompanyLoadingState("error");
      }
    },
    [languageDispatch]
  );

  useEffect(() => {
    (async () => {
      await loadCompany(domain);
    })();
  }, [loadCompany, domain]);

  switch (companyLoadingState) {
    case "error":
      return (
        <NoConnectionResultPage onRetryClick={() => loadCompany(domain)} />
      );
    case "loading":
      return <Spinner />;
    case "loaded": {
      if (!company) {
        return (
          <CompanySwitcher
            forceSubdomain={async (subdomain) => {
              await loadCompany(subdomain);
            }}
          />
        );
      }

      if (!company.isActive) {
        if (!company.redirectToCompanySubdomain) {
          return <CompanyIsNotActiveErrorPage company={company} />;
        } else {
          redirectToAnotherSubdomain(company.redirectToCompanySubdomain);
          return <Spinner />;
        }
      }

      return (
        <CompanyContextProvider value={company}>
          {props.children}
        </CompanyContextProvider>
      );
    }
  }
}
