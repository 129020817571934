import { FolderOpenOutlined } from "@ant-design/icons";
import { Button, Space, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { filesFolderIconColor } from "src/backOffice/views/vehicles/common";
import { Icon } from "src/components";

interface Props {
  onBackClick: () => void;
  vehiclePlateNumber?: string;
}

export function VehicleDocumentsHeader(props: Props) {
  const { t } = useTranslation("translation");
  return (
    <Space size="small" style={{ marginBottom: 4 }}>
      <Button
        icon={<Icon icon="chevronLeft" />}
        size="small"
        type="text"
        onClick={props.onBackClick}
      />

      <Space align="baseline" size="small" style={{ paddingTop: 10 }}>
        <FolderOpenOutlined
          style={{
            fontSize: 18,
            color: filesFolderIconColor,
          }}
        />
        <Typography.Title level={5}>
          {t("vehicleDocuments.title")}
          {typeof props.vehiclePlateNumber !== "undefined" && (
            <Typography.Text type="secondary">
              {" "}
              - {props.vehiclePlateNumber}
            </Typography.Text>
          )}
        </Typography.Title>
      </Space>
    </Space>
  );
}
