import * as Sentry from "@sentry/react";
import { Route, Switch } from "react-router-dom";

import { urls } from "src/urls";

import { CreateLinkTypeView } from "./CreateLinkTypeView";
import { EditLinkTypeView } from "./EditLinkTypeView";
import { LinkTypesSettingsListView } from "./LinkTypesSettingsListView";

const SentryRoute = Sentry.withSentryRouting(Route);
export function LinkTypesSettingsRouting() {
  return (
    <Switch>
      <SentryRoute path={urls.backOffice.settings.linkTypes.baseView()} exact>
        <LinkTypesSettingsListView />
      </SentryRoute>
      <SentryRoute path={urls.backOffice.settings.linkTypes.create()} exact>
        <CreateLinkTypeView />
      </SentryRoute>
      <SentryRoute path={urls.backOffice.settings.linkTypes.edit()}>
        <EditLinkTypeView />
      </SentryRoute>
    </Switch>
  );
}
