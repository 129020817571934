import * as Sentry from "@sentry/react";
import { Route, Switch } from "react-router-dom";

import { urls } from "src/urls";

import { EmployeeChargesListView } from "./EmployeeChargesListView";

const SentryRoute = Sentry.withSentryRouting(Route);
export function EmployeeChargesRouter() {
  return (
    <Switch>
      <SentryRoute path={urls.backOffice.employeeCharges()} exact>
        <EmployeeChargesListView />
      </SentryRoute>
    </Switch>
  );
}
