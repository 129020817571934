import { ArrowUpOutlined, CloseOutlined } from "@ant-design/icons";
import { useCompany } from "@inspecto/common";
import { Alert, Button, Checkbox, Space, Typography } from "antd";
import produce from "immer";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import { RadioGroupOfTypeButton } from "src/components";
import { urls } from "src/urls";

import { WhiteCard } from "../components";
import { PageLayout } from "../components/PageLayout";
import { protocolFillerLocalStorageContext } from "../contexts";
import { useTemplates, useVehicles } from "../hooks";

const FilterTag = styled("div")<{ checked: boolean; disabled: boolean }>`
  user-select: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  display: inline-block;
  color: ${(props) =>
    props.checked
      ? props.theme.antd.menuDarkItemHoverBg
      : props.disabled
      ? "#909090"
      : "#2a2a2a"};
  font-weight: 500;
  border: 1px solid
    ${(props) => (props.checked ? props.theme.antd.primaryColor : "#dfdfdf")};
  border-radius: 12px;
  padding: 6px 14px;
  margin-right: 8px;
  margin-bottom: 8px;
  background-color: ${(props) =>
    props.disabled ? props.theme.colors.protocolResponseBackground : "#fff"};

  ${(props) =>
    !props.disabled &&
    `
  &:hover {
    background-color: ${props.theme.antd.primary1};
  }
  `}
`;

const StartSelectingFilters = styled.div`
  text-align: center;
  font-weight: 500;
  padding: 15px 0 30px;
`;

interface Filter {
  value: string;
  name: string;
  checked: boolean;
  disabled: boolean;
}

export function TemplateSelectorView() {
  const { t } = useTranslation("protocolFiller");
  const { t: translationT } = useTranslation();
  const company = useCompany();
  const { vehicleTypeId, vehicleId } =
    useParams<{ vehicleTypeId: string; vehicleId: string }>();
  const history = useHistory();
  const { getVehicleById } = useVehicles();

  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [wasSubmittedWithMissingData, setWasSubmittedWithMissingData] =
    useState(false);
  const { clearStorage } = useContext(protocolFillerLocalStorageContext);

  const {
    reloadTemplates,
    templates,
    loadingState: templatesLoadingState,
    templateTags,
    setTemplateListFilters,
    templateListFilters,
  } = useTemplates();

  useEffect(() => {
    clearStorage();
  }, [clearStorage]);

  useEffect(() => {
    const vehicle = getVehicleById(vehicleId);
    setTemplateListFilters((filters) => ({
      ...filters,
      vehicleGroupIds: vehicle?.vehicleGroups,
    }));
  }, [vehicleId, getVehicleById, setTemplateListFilters]);

  useEffect(() => {
    setWasSubmittedWithMissingData(false);
  }, [selectedTemplateId]);

  useEffect(() => {
    setSelectedTemplateId("");
  }, [templateListFilters]);

  useEffect(() => {
    setTemplateListFilters((filters) => ({ ...filters, vehicleTypeId }));
  }, [setTemplateListFilters, vehicleTypeId]);

  const filters: Filter[] = templateTags
    .sort((a, b) => a.order - b.order)
    .map((templateTag) => ({
      value: templateTag.id,
      name: templateTag.label,
      checked: !!templateListFilters.selectedTemplateTagIds?.includes(
        templateTag.id
      ),
      disabled: !templates.find((templateItem) =>
        templateItem.templateTags.map((tag) => tag.id).includes(templateTag.id)
      ),
    }));

  const displayStartSelectingFiltersMessage =
    company.requireTemplateTagFilterSelectionInProtocolFiller &&
    (!templateListFilters.selectedTemplateTagIds ||
      templateListFilters.selectedTemplateTagIds.length === 0) &&
    !!filters.length;

  return (
    <PageLayout
      onBackClick={() =>
        history.push(urls.protocolFiller.vehicleType(vehicleTypeId))
      }
      onNextClick={
        !!selectedTemplateId
          ? () =>
              history.push(
                urls.protocolFiller.vehicleTypeAndVehicleAndTemplate(
                  vehicleTypeId,
                  vehicleId,
                  selectedTemplateId
                )
              )
          : () => setWasSubmittedWithMissingData(true)
      }
    >
      <WhiteCard
        title={t("chooseProtocolType")}
        innerKey="protocolType"
        hasAsterisk
        errors={
          wasSubmittedWithMissingData
            ? [t("questionValidationErrors.requiredQuestion")]
            : []
        }
      >
        <Space direction="vertical" style={{ width: "100%" }} size="middle">
          {!!filters.length && (
            <div>
              {filters.map((filter) => {
                return (
                  <FilterTag
                    checked={filter.checked}
                    disabled={filter.disabled}
                    onClick={
                      filter.disabled
                        ? undefined
                        : () => toggleFilter(filter.value)
                    }
                  >
                    <Space>
                      <Checkbox
                        checked={filter.checked}
                        disabled={filter.disabled}
                      />
                      {filter.name}
                    </Space>
                  </FilterTag>
                );
              })}
              {!!templateListFilters.selectedTemplateTagIds?.length && (
                <div
                  style={{
                    textAlign: "center",
                    paddingTop: 5,
                    paddingBottom: 10,
                  }}
                >
                  <Button
                    size="small"
                    type="dashed"
                    shape="round"
                    icon={<CloseOutlined />}
                    onClick={() =>
                      setTemplateListFilters((filters) => ({
                        ...filters,
                        selectedTemplateTagIds: [],
                      }))
                    }
                  >
                    {t("clearFilters")}
                  </Button>
                </div>
              )}
            </div>
          )}

          {displayStartSelectingFiltersMessage ? (
            <StartSelectingFilters>
              <Typography.Text type="secondary">
                <Space size="middle">
                  <ArrowUpOutlined style={{ fontSize: 16 }} />
                  {t("startSelectingFilters")}
                </Space>
              </Typography.Text>
            </StartSelectingFilters>
          ) : templatesLoadingState === "error" ? (
            <Alert
              type="error"
              message={
                <>
                  {translationT("errors.noInternet.message")}
                  <Button type="link" onClick={() => reloadTemplates()}>
                    {t("retry")}
                  </Button>
                </>
              }
            />
          ) : (
            <RadioGroupOfTypeButton
              mode={
                templatesLoadingState === "loading"
                  ? "verticalSkeleton"
                  : "vertical"
              }
              value={selectedTemplateId}
              onChange={(value) =>
                setSelectedTemplateId(value == null ? "" : value)
              }
              items={templates.map((template) => ({
                value: template.id,
                ...template,
              }))}
            />
          )}
        </Space>
      </WhiteCard>
    </PageLayout>
  );

  function toggleFilter(value: string) {
    setTemplateListFilters((filters) =>
      produce(filters, (draftFilters) => {
        if (!draftFilters.selectedTemplateTagIds) {
          draftFilters.selectedTemplateTagIds = [];
        }
        if (draftFilters.selectedTemplateTagIds.includes(value)) {
          draftFilters.selectedTemplateTagIds =
            draftFilters.selectedTemplateTagIds.filter(
              (item) => item !== value
            );
        } else {
          draftFilters.selectedTemplateTagIds.push(value);
        }
        return draftFilters;
      })
    );
  }
}
