import { Select as AntdSelect } from "antd";
import { useTranslation } from "react-i18next";

import { InputWrapper } from "../InputWrapper";
import { SelectProps } from "./SelectProps";

export interface MultiSelectProps<T>
  extends Omit<SelectProps<T>, "onChange" | "value" | "defaultValue"> {
  onChange?: (value: T[]) => void;
  value?: T[];
  defaultValue?: T[];
}

export function MultiSelect<T extends string = string>(
  props: MultiSelectProps<T>
) {
  const { t } = useTranslation("translation");

  return (
    <InputWrapper
      label={props.label}
      isRequired={props.isRequired}
      helpTooltip={props.helpTooltip}
      noMargin={props.noMargin}
    >
      <AntdSelect
        id={props.id}
        style={props.style}
        disabled={props.disabled}
        loading={props.loading}
        defaultValue={props.defaultValue || []}
        onChange={props.onChange}
        value={props.value}
        placeholder={props.placeholder || t("select")}
        notFoundContent={t("noOptions")}
        mode="multiple"
        showArrow
        maxTagCount={props.maxTagCount}
        dropdownRender={props.dropdownRender}
      >
        {props.options?.map((option) => {
          return (
            <AntdSelect.Option value={option.value} key={option.value}>
              {option.label}
            </AntdSelect.Option>
          );
        })}
      </AntdSelect>
    </InputWrapper>
  );
}
