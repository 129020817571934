import { HomeOutlined, PoweroffOutlined } from "@ant-design/icons";
import { useCompany, Company } from "@inspecto/common";
import * as Sentry from "@sentry/react";
import { Button, Result, Typography } from "antd";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Route, RouteProps, useHistory } from "react-router-dom";
import styled from "styled-components";

import { LandingPageTopBarLayout } from "src/components";
import { urls } from "src/urls";

import { useAuthentication } from "./context";
import { LoginPage } from "./LoginPage";
import { Permission, User } from "./models";

const StyledButton = styled(Button)`
  margin: 0 5px 10px;
`;
const SentryRoute = Sentry.withSentryRouting(Route);

interface Props {
  requiredTruthyUserProperty?: keyof User;
  requiredTruthyCompanyProperty?: keyof Company;
  requiredPermissions?: Permission[];
}

export function NoAccessPageIfNotAuthorizedOrLoginPage({
  children,
  requiredTruthyUserProperty,
  requiredTruthyCompanyProperty,
  requiredPermissions,
}: PropsWithChildren<Props>) {
  const { user, hasPerm, logoutAndRedirectToLandingPage } = useAuthentication();
  const company = useCompany();
  const { t } = useTranslation("translation");
  const history = useHistory();

  if (!user) {
    return <LoginPage />;
  }

  const isUserPropertyMissing =
    requiredTruthyUserProperty && !user[requiredTruthyUserProperty];

  const isUserPermissionMissing =
    requiredPermissions &&
    !requiredPermissions.every((permission) => hasPerm(permission));

  const isCompanyPropertyMissing =
    requiredTruthyCompanyProperty && !company[requiredTruthyCompanyProperty];

  if (
    isUserPropertyMissing ||
    isUserPermissionMissing ||
    isCompanyPropertyMissing
  ) {
    return (
      <LandingPageTopBarLayout
        user={user}
        onLogoutClick={logoutAndRedirectToLandingPage}
      >
        <Result
          status="403"
          title={t("errors.noAccess.title")}
          subTitle={
            <div>
              <div>{t("errors.noAccess.description")}</div>
              <Typography.Text keyboard>
                {history.location.pathname}
              </Typography.Text>
            </div>
          }
          extra={
            <>
              <StyledButton
                onClick={logoutAndRedirectToLandingPage}
                ghost
                type="primary"
                icon={<PoweroffOutlined />}
              >
                {t("logout")}
              </StyledButton>
              <StyledButton
                onClick={() => history.push(urls.landingPage())}
                type="primary"
                icon={<HomeOutlined />}
              >
                {t("errors.noAccess.returnToHomePage")}
              </StyledButton>
            </>
          }
        />
      </LandingPageTopBarLayout>
    );
  }
  return <>{children}</>;
}

export function ProtectedRoute(props: PropsWithChildren<Props> & RouteProps) {
  return (
    <SentryRoute {...props}>
      {/*Needs to be within route in a separate component so that we don't return LoginPage instead of route */}
      <NoAccessPageIfNotAuthorizedOrLoginPage {...props} />
    </SentryRoute>
  );
}
