import { NoConnectionError } from "@inspecto/common";
import { notification } from "antd";
import { useCallback } from "react";
import { TFunction, useTranslation } from "react-i18next";

export function isNoConnectionError(e: any): e is NoConnectionError {
  return e instanceof NoConnectionError;
}

export function showNoConnectionNotification(t?: TFunction<"translation">) {
  const message = t ? t("errors.noInternet.message") : "No Internet Connection";
  const description = t
    ? t("errors.noInternet.subtitle")
    : "Please check your internet connection and try again.";
  notification.error({
    message,
    description,
  });
}

export function useNoConnectionErrorNotification() {
  const { t } = useTranslation();

  const showNotification = useCallback(
    () => showNoConnectionNotification(t),
    [t]
  );

  return [showNotification];
}
