import { AuditOutlined } from "@ant-design/icons";
import { Alert, Button, Result, Space, Typography } from "antd";
import { useState } from "react";
import styled from "styled-components";

import { useAuthentication } from "src/authentication";

import { commonResultProps } from "./common";

const StyledCenter = styled.div`
  margin: auto;
`;

const SignatureWrapper = styled.div`
  display: flex;
  max-width: 320px;
  margin: 6px auto 14px;
  border: 1px dashed #909090;
  border-radius: 2px;
  color: #252323;
  background-color: #ebeced;
  padding: 6px 10px;
  text-align: left;
  align-items: center;
`;

const SignatureRight = styled.div`
  margin-left: 8px;
`;

interface Props {
  status: "success" | "error";
  title: string;
  subTitle?: string | JSX.Element;
  message?: string;
  signature?: {
    id: string;
    date: string;
  };
  logoutButtonText: string;
  extraContent?: JSX.Element;
}

export function ProtocolResolutionComponent(props: Props) {
  const { user, logoutAndRedirectToLandingPage } = useAuthentication();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  return (
    <Result
      {...commonResultProps}
      status={props.status}
      title={props.title}
      subTitle={
        <>
          {typeof props.signature !== "undefined" && (
            <SignatureWrapper>
              <AuditOutlined style={{ fontSize: 24 }} />
              <SignatureRight>
                <div>
                  <Typography.Text strong>
                    {props.signature.date}
                  </Typography.Text>
                </div>
                <div style={{ fontSize: 12 }}>{props.signature.id}</div>
              </SignatureRight>
            </SignatureWrapper>
          )}

          {typeof props.subTitle !== "undefined" && (
            <StyledCenter>{props.subTitle}</StyledCenter>
          )}
          {typeof props.message !== "undefined" && (
            <Alert
              type="error"
              message={props.message}
              style={{ marginTop: 16 }}
            />
          )}
        </>
      }
      extra={
        <>
          {props.extraContent && (
            <Space
              direction="vertical"
              style={{
                marginRight: 0, // Because every .ant-result-extra > * gets margin-right: 8px
                width: "100%",
              }}
            >
              {props.extraContent}
            </Space>
          )}

          {!!user && (
            <StyledCenter>
              <Button
                type="primary"
                style={{ marginTop: 32 }}
                key="logout"
                loading={isLoggingOut}
                onClick={async () => {
                  setIsLoggingOut(true);
                  await logoutAndRedirectToLandingPage();
                  setIsLoggingOut(false);
                }}
              >
                {props.logoutButtonText}
              </Button>
            </StyledCenter>
          )}
        </>
      }
    />
  );
}
