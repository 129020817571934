import { Empty } from "antd";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { FieldValue } from "src/components";
import { StorageUploadedImage } from "src/storage";
import { urls } from "src/urls";
import { useToggleArrayById } from "src/utils";

import {
  ListCustomVehicleField,
  RetrieveVehicleCustomVehicleField,
} from "../models";
import { filterCustomVehicleFields } from "../utils";
import { CustomVehicleFieldFormItem } from "./CustomVehicleFieldFormItem";

export interface CustomFieldValuesFormState {
  customFieldValues: CustomFieldValues;
}

export type CustomFieldValues = Record<
  string,
  | { value: FieldValue }
  | { value: FieldValue; note: string; photos: StorageUploadedImage[] }
>;

interface Props {
  vehicleId?: string;
  customVehicleFieldValues: Record<string, RetrieveVehicleCustomVehicleField>;
  displayMode: "creatingVehicle" | "editingVehicle";
  customVehicleFields: ListCustomVehicleField[];
  displayOnlyNotOk: boolean;
  searchPhrase?: string;
}

export function CustomVehicleFieldsFormItems({
  searchPhrase = "",
  ...props
}: Props) {
  const { t } = useTranslation("backoffice");
  const [
    editedVehicleFields,
    toggleEditedVehicleField,
    clearAllEditedVehicleFields,
  ] = useToggleArrayById<{ id: string }>();
  const editedVehicleFieldIds = editedVehicleFields.map((row) => row.id);
  const { customVehicleFieldValues, customVehicleFields, displayOnlyNotOk } =
    props;

  useEffect(() => {
    clearAllEditedVehicleFields();
  }, [clearAllEditedVehicleFields, customVehicleFieldValues]);

  const filteredCustomVehicleFields = useMemo(() => {
    return filterCustomVehicleFields(
      customVehicleFields,
      customVehicleFieldValues,
      {
        searchPhrase,
        displayOnlyNotOk,
      }
    );
  }, [
    customVehicleFields,
    customVehicleFieldValues,
    displayOnlyNotOk,
    searchPhrase,
  ]);

  return (
    <>
      {filteredCustomVehicleFields.length ? (
        filteredCustomVehicleFields.map((customVehicleField) => {
          if (customVehicleField.type === "damages") {
            return null;
          }
          const isFieldEdited =
            editedVehicleFieldIds.includes(customVehicleField.id) ||
            props.displayMode === "creatingVehicle";
          const customVehicleFieldValue =
            props.customVehicleFieldValues[customVehicleField.id];

          return (
            <CustomVehicleFieldFormItem
              customVehicleField={customVehicleField}
              vehicleCustomVehicleField={customVehicleFieldValue}
              formItemName={[
                "customFieldValues",
                customVehicleField.id,
                "value",
              ]}
              isFieldEdited={isFieldEdited}
              toggleEditedVehicleField={toggleEditedVehicleField}
              displayMode={props.displayMode}
              vehicleId={props.vehicleId}
              key={customVehicleField.id}
            />
          );
        })
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            props.customVehicleFields.length === 0 ? (
              <div>
                <>
                  {t("vehicleView.createCustomVehicleFieldsIn")}{" "}
                  <Link
                    to={urls.backOffice.settings.customVehicleFields()}
                    data-testid="create-custom-vehicle-fields-in-page"
                  >
                    {t("settings.label")}
                  </Link>
                </>
              </div>
            ) : undefined
          }
        />
      )}
    </>
  );
}
