import { AimOutlined } from "@ant-design/icons";
import { Position, useLocalPagination } from "@inspecto/common";
import { Button, Form, Input, Space } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  FiltersAndPaginationForm,
  RadioGroupOfTypeButton,
} from "src/components";

import { Vehicle } from "../models";
import { WhiteCard } from "./WhiteCard";

const PlateNumberAndButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledButtonNegativeMargins = styled(Button)`
  margin: -9px -8px -9px 0;
`;

interface GetVehiclesParams {
  pageNumber: number;
  searchQuery: string;
}

interface Props {
  vehicles: Vehicle[];
  validationErrors: string[];
  isLoading: boolean;
  value: string;
  onChange: (value: string) => void;
  onVehiclePositionClick?: (data: {
    position: Position;
    plateNumber: string;
  }) => void;
}

export function VehicleSelector(props: Props) {
  const { t } = useTranslation("protocolFiller");
  const [form] = Form.useForm();
  const pageNumber = Form.useWatch("pageNumber", form);
  const searchQuery = Form.useWatch("searchQuery", form);

  const filteredVehicles = useMemo(() => {
    const lowerCasedSearchQuery = searchQuery ? searchQuery.toLowerCase() : "";
    return props.vehicles.filter((vehicle) =>
      vehicle.plateNumber.toLowerCase().includes(lowerCasedSearchQuery)
    );
  }, [props.vehicles, searchQuery]);

  const { currentPage } = useLocalPagination(filteredVehicles, pageNumber);

  return (
    <WhiteCard
      title={t("plateNumber")}
      innerKey="plateNumber"
      hasAsterisk
      errors={props.validationErrors}
    >
      <FiltersAndPaginationForm<GetVehiclesParams>
        saveCallback={async () => {}}
        onValuesChange={(changedValues) => {
          if (!Object.keys(changedValues).includes("pageNumber")) {
            form.setFields([
              {
                name: "pageNumber",
                value: 1,
              },
            ]);
          }

          props.onChange("");
        }}
        form={form}
        initialValues={{
          pageNumber: 1,
          searchQuery: "",
        }}
        isRetryableOnError
      >
        <Space direction="vertical" style={{ width: "100%" }} size="middle">
          <Form.Item noStyle name="searchQuery">
            <Input.Search
              allowClear
              autoComplete="off"
              placeholder={t("plateNumber")}
              loading={props.isLoading}
            />
          </Form.Item>

          <Form.Item
            noStyle
            name="pageNumber"
            valuePropName="pageNumber"
            trigger="onPageNumberChange"
          >
            <RadioGroupOfTypeButton
              mode={props.isLoading ? "verticalSkeleton" : "vertical"}
              total={filteredVehicles.length}
              items={currentPage.map((vehicle) => {
                return {
                  label:
                    props.onVehiclePositionClick && vehicle.positionObject ? (
                      <PlateNumberAndButtonWrapper>
                        {vehicle.plateNumber}
                        <StyledButtonNegativeMargins
                          icon={<AimOutlined style={{ fontSize: 16 }} />}
                          size="small"
                          title={t(
                            "vehiclePositionModal.locationOfPlateNumber",
                            {
                              plateNumber: vehicle.plateNumber,
                            }
                          )}
                          onClick={() => {
                            vehicle.positionObject &&
                              props.onVehiclePositionClick &&
                              props.onVehiclePositionClick({
                                position: vehicle.positionObject,
                                plateNumber: vehicle.plateNumber,
                              });
                          }}
                        />
                      </PlateNumberAndButtonWrapper>
                    ) : (
                      vehicle.plateNumber
                    ),
                  value: vehicle.id,
                };
              })}
              value={props.value}
              onChange={(value) => props.onChange(value == null ? "" : value)}
            />
          </Form.Item>
        </Space>
      </FiltersAndPaginationForm>
    </WhiteCard>
  );
}
