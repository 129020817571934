import {
  Position,
  getCompanyProtocolFillerSelectors,
  useCompany,
} from "@inspecto/common";
import { Modal } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { ProtocolPosition } from "src/backOffice/components";
import { NoConnectionResultPage, OrDivider } from "src/components";
import { urls } from "src/urls";

import { VehicleSelector, WhiteCard } from "../components";
import { ChooseVehicleViaQRCodeButton } from "../components/ChooseVehicleViaQRCodeButton";
import { PageLayout } from "../components/PageLayout";
import { protocolFillerLocalStorageContext } from "../contexts";
import { useVehicles } from "../hooks";

interface Props {
  hasBackButton: boolean;
  showQRScan: boolean;
}

export function VehicleSelectorView(props: Props) {
  const { t } = useTranslation("protocolFiller");
  const company = useCompany();
  const [positionModalValue, setPositionModalValue] = useState<null | {
    position: Position;
    plateNumber: string;
  }>(null);
  const history = useHistory();
  const { vehicleTypeId } = useParams<{ vehicleTypeId: string }>();

  const [wasSubmittedWithMissingData, setWasSubmittedWithMissingData] =
    useState(false);

  const [vehicleId, setVehicleId] = useState("");
  const { clearStorage } = useContext(protocolFillerLocalStorageContext);

  const vehicleSelectors = useMemo(() => {
    return getCompanyProtocolFillerSelectors(company);
  }, [company]);

  useEffect(() => {
    clearStorage();
  }, [clearStorage]);

  useEffect(() => {
    setWasSubmittedWithMissingData(false);
  }, [vehicleId]);

  const { vehicles, loadingState, reloadVehicles } = useVehicles();

  const filteredVehiclesByVehicleType = useMemo(() => {
    return vehicles.filter((vehicle) => vehicle.vehicleType === vehicleTypeId);
  }, [vehicleTypeId, vehicles]);

  if (loadingState === "error") {
    return <NoConnectionResultPage onRetryClick={reloadVehicles} />;
  }
  return (
    <PageLayout
      onBackClick={
        props.hasBackButton
          ? () => history.push(urls.protocolFiller.main())
          : undefined
      }
      onNextClick={
        !!vehicleId
          ? () =>
              history.push(
                urls.protocolFiller.vehicleTypeAndVehicle(
                  vehicleTypeId,
                  vehicleId
                )
              )
          : () => setWasSubmittedWithMissingData(true)
      }
    >
      {props.showQRScan && vehicleSelectors.includes("qrCode") && (
        <WhiteCard
          innerKey="protocolType"
          title={t("qrCodes.title")}
          hasAsterisk
          errors={
            wasSubmittedWithMissingData
              ? [t("questionValidationErrors.requiredQuestion")]
              : []
          }
        >
          <ChooseVehicleViaQRCodeButton
            marginBottom={15}
            label={t("qrCodes.chooseVehicle")}
          />
        </WhiteCard>
      )}

      {vehicleSelectors.length > 1 && props.showQRScan && <OrDivider />}

      {vehicleSelectors.includes("plateNumber") && (
        <VehicleSelector
          value={vehicleId}
          onChange={setVehicleId}
          vehicles={filteredVehiclesByVehicleType}
          isLoading={loadingState === "loading"}
          validationErrors={
            wasSubmittedWithMissingData
              ? [t("questionValidationErrors.requiredQuestion")]
              : []
          }
          onVehiclePositionClick={
            company.displayVehiclePositionInProtocolFillerVehicleSelector
              ? setPositionModalValue
              : undefined
          }
        />
      )}
      {positionModalValue && (
        <Modal
          open
          title={t("vehiclePositionModal.locationOfPlateNumber", {
            plateNumber: positionModalValue.plateNumber,
          })}
          onCancel={() => setPositionModalValue(null)}
          okText={t("vehiclePositionModal.openInGoogleMaps")}
          okButtonProps={{
            href: `https://www.google.com/maps/search/?api=1&${new URLSearchParams(
              {
                query: [
                  positionModalValue.position.latitude,
                  positionModalValue.position.longitude,
                ].join(","),
              }
            ).toString()}`,
            target: "_blank",
          }}
        >
          <ProtocolPosition
            displayDate
            positionObject={positionModalValue.position}
          />
        </Modal>
      )}
    </PageLayout>
  );
}
