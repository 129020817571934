import { ExportOutlined } from "@ant-design/icons";
import { Card, Col, Row, Space, Typography } from "antd";
import dayjs from "dayjs";
import { ReactNode, useMemo } from "react";
import * as React from "react";

import { FileTypeIcon } from "src/backOffice/components";
import { VehicleDocument } from "src/backOffice/models";
import { formatToDateTime, fullName } from "src/utils";

import { UserAvatar } from "../UserAvatar";

interface Props {
  vehicleDocument: VehicleDocument;
  mode: "mobile" | "desktop";
  rightContent: ReactNode;
}

export function DocumentListItem(props: Props): JSX.Element {
  const cardBodyStyles: React.CSSProperties = useMemo(
    () => (props.mode === "mobile" ? { padding: "10px 12px" } : {}),
    [props.mode]
  );

  return (
    <Card key={props.vehicleDocument.id} bodyStyle={cardBodyStyles}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ flex: 1, paddingRight: 15 }}>
          <Row>
            <Col xs={props.mode === "mobile" ? 24 : 18}>
              <div style={{ paddingRight: 20 }}>
                <Space style={{ alignItems: "start" }}>
                  <FileTypeIcon
                    extension={props.vehicleDocument.file.extension}
                    size={props.mode === "mobile" ? 22 : 30}
                  />
                  <div>
                    <div>
                      <Typography.Text strong>
                        <a
                          href={props.vehicleDocument.file.fileUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Space>
                            {props.vehicleDocument.label}
                            <ExportOutlined />
                          </Space>
                        </a>
                      </Typography.Text>
                    </div>
                    <Typography.Text type="secondary">
                      {formatToDateTime(dayjs(props.vehicleDocument.createdAt))}
                    </Typography.Text>
                  </div>
                </Space>
              </div>
            </Col>
            {props.mode === "desktop" && (
              <Col
                xs={6}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  type="secondary"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <UserAvatar
                    user={props.vehicleDocument.createdByObject}
                    size={22}
                    style={{ verticalAlign: "top", marginRight: 8 }}
                  />

                  {fullName(props.vehicleDocument.createdByObject)}
                </Typography.Text>
              </Col>
            )}
          </Row>
        </div>
        <div>{props.rightContent}</div>
      </div>
    </Card>
  );
}
