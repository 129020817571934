import React from "react";

import { Permission } from "src/authentication/models";

import { LinkedObjectLink } from "./LinkedObjectComponents";
import { ManualSpace, ManualSpaceItem } from "./ManualSpace";

interface Props<TItem> {
  leftContent: JSX.Element;
  assignments: TItem[];
  getAssignedObjectUrl: (item: TItem) => string;
  getAssignedObjectName: (item: TItem) => string;
  requiredPermissionForAssignedObject: Permission;
}

export function VehicleAssignmentRow<TItem>(props: Props<TItem>) {
  return (
    <ManualSpace gap="8px">
      <ManualSpaceItem flex="0 0 auto" style={{ marginTop: 1 }}>
        {props.leftContent}
      </ManualSpaceItem>
      <ManualSpaceItem flex="1 1 auto">
        {props.assignments.length
          ? props.assignments.map((assignment) => (
              <LinkedObjectLink
                $rightMargin
                to={props.getAssignedObjectUrl(assignment)}
                requiredPermission={props.requiredPermissionForAssignedObject}
                key={props.getAssignedObjectUrl(assignment)}
              >
                {props.getAssignedObjectName(assignment)}
              </LinkedObjectLink>
            ))
          : "---"}
      </ManualSpaceItem>
    </ManualSpace>
  );
}
