import * as Sentry from "@sentry/react";
import { Route, Switch } from "react-router-dom";

import { urls } from "src/urls";

import { VehicleFromParamsProvider } from "../../contexts";
import { VehiclesProvider } from "../../hooks";
import { DocumentsVehicleSelectorView } from "./DocumentsVehicleSelectorView";
import { DocumentsView } from "./DocumentsView";

const SentryRoute = Sentry.withSentryRouting(Route);

export function DocumentsRouter() {
  return (
    <VehiclesProvider>
      <Switch>
        <SentryRoute path={urls.documents.main()} exact>
          <DocumentsVehicleSelectorView />
        </SentryRoute>
        <SentryRoute path={urls.documents.listDocumentsForVehicle()} exact>
          <VehicleFromParamsProvider>
            <DocumentsView />
          </VehicleFromParamsProvider>
        </SentryRoute>
      </Switch>
    </VehiclesProvider>
  );
}
