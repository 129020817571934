import * as Sentry from "@sentry/react";
import { Route, Switch } from "react-router-dom";

import { urls } from "src/urls";

import { CompanyDepartmentsListView } from "./CompanyDepartmentsListView";
import { CreateCompanyDepartmentView } from "./CreateCompanyDepartmentView";
import { EditCompanyDepartmentRouter } from "./EditCompanyDepartmentRouter";

const SentryRoute = Sentry.withSentryRouting(Route);
export function CompanyDepartmentsRouter() {
  return (
    <Switch>
      <SentryRoute path={urls.backOffice.settings.companyDepartments()} exact>
        <CompanyDepartmentsListView />
      </SentryRoute>
      <SentryRoute
        path={urls.backOffice.settings.createCompanyDepartment()}
        exact
      >
        <CreateCompanyDepartmentView />
      </SentryRoute>
      <SentryRoute path={urls.backOffice.settings.editCompanyDepartment()}>
        <EditCompanyDepartmentRouter />
      </SentryRoute>
    </Switch>
  );
}
