import { AimOutlined } from "@ant-design/icons";
import { Position } from "@inspecto/common";
import { Badge, Space, Tag, Typography } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export function ProtocolPosition({
  positionObject,
  onPositionClick,
  displayDate = false,
}: {
  positionObject: Position | null;
  onPositionClick?(positionObject: Position): void;
  displayDate?: boolean;
}) {
  const { t } = useTranslation("backoffice");
  let elementToDisplay: JSX.Element | null;

  if (!positionObject) {
    elementToDisplay = (
      <Typography.Text strong>
        {t("protocolView.protocolCreatedInPositionNotProvided")}
      </Typography.Text>
    );
  } else {
    const accuracy = Number(positionObject.accuracy);
    const text = !positionObject.retrievedAddress
      ? t("protocolView.protocolCreatedInPositionShowOnMap")
      : positionObject.retrievedAddress;

    elementToDisplay = (
      <div>
        <div>
          <div>
            <Typography.Text strong>
              {onPositionClick ? (
                <a
                  onClick={(event) => {
                    event.preventDefault();
                    onPositionClick(positionObject);
                  }}
                  href="/#"
                >
                  {text}
                </a>
              ) : (
                text
              )}
            </Typography.Text>
          </div>
          {displayDate && (
            <div>
              <Typography.Text
                type="secondary"
                title={dayjs(positionObject.createdAt).format(
                  "DD.MM.YYYY HH:mm"
                )}
              >
                {dayjs(positionObject.createdAt).fromNow()}
              </Typography.Text>
            </div>
          )}
          <div>
            <Tag
              style={{
                marginTop: 5,
              }}
            >
              <Badge
                status={
                  accuracy < 20
                    ? "success"
                    : accuracy < 100
                    ? "warning"
                    : "error"
                }
                text={`+/- ${positionObject.accuracy}m`}
              />
            </Tag>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Space style={{ alignItems: "start" }}>
      <AimOutlined />
      {elementToDisplay}
    </Space>
  );
}
