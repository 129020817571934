import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  DefaultSelectOptionTypeStringLabel,
  SearchableAsyncSelect,
  SelectOptionTypeStringLabel,
} from "src/components";

import { ListVehicleByLinkTypeAndVehicle, protocolFillerApi } from "../api";
import { useProtocolFillerContext } from "../contexts";

interface Props {
  questionId: string;
  linkTypeId: string;
}

export function LinkingQuestionComponent({ questionId, linkTypeId }: Props) {
  const { t } = useTranslation("protocolFiller");
  const { responses, setLinkingResponse, vehicleId } =
    useProtocolFillerContext();

  const { linkingResponses } = responses;

  const setResponse = useCallback(
    (response) => {
      setLinkingResponse(questionId, response);
    },
    [setLinkingResponse, questionId]
  );

  const searchVehicles = useCallback(
    async (query: string) => {
      if (!vehicleId || !linkTypeId)
        throw new Error(
          "vehicleId and linkTypeId must be defined to search vehicles"
        );
      return await protocolFillerApi.getVehiclesByLinkTypeAndVehicle(
        linkTypeId,
        vehicleId,
        query
      );
    },
    [vehicleId, linkTypeId]
  );

  const getOptionsFromVehicles: (
    vehicles: ListVehicleByLinkTypeAndVehicle[]
  ) => SelectOptionTypeStringLabel[] = useCallback(
    (vehicles) => {
      const hasAssignedVehicle = vehicles.find(
        (vehicle) => vehicle.isUserAssigned
      );

      if (!hasAssignedVehicle) {
        return vehicles.map((vehicle) => ({
          value: vehicle.id,
          label: vehicle.plateNumber,
        }));
      }

      let assignedVehiclesOptions: DefaultSelectOptionTypeStringLabel[] = [];
      let unassignedVehiclesOptions: DefaultSelectOptionTypeStringLabel[] = [];

      vehicles.forEach((vehicle) => {
        if (vehicle.isUserAssigned) {
          assignedVehiclesOptions.push({
            value: vehicle.id,
            label: vehicle.plateNumber,
          });
        } else {
          unassignedVehiclesOptions.push({
            value: vehicle.id,
            label: vehicle.plateNumber,
          });
        }
      });

      return [
        {
          label: t("vehiclesAssignedToYou"),
          options: assignedVehiclesOptions,
        },
        {
          label: t("otherVehicles"),
          options: unassignedVehiclesOptions,
        },
      ];
    },
    [t]
  );

  return (
    <SearchableAsyncSelect<ListVehicleByLinkTypeAndVehicle>
      searchElements={searchVehicles}
      onChange={setResponse}
      value={linkingResponses[questionId]}
      getOptionsFromResponse={getOptionsFromVehicles}
    />
  );
}
