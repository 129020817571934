import { ApiError, NoConnectionError } from "@inspecto/common";
import * as Sentry from "@sentry/react";
import { Spin, Result, Button } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Prompt, Redirect, useHistory, useParams } from "react-router-dom";

import { useReporting } from "src/reporting/useReporting";
import { urls } from "src/urls";
import {
  BAD_REQUEST_STATUS_CODE,
  NON_FIELD_ERRORS_FIELD_NAME,
  UNAUTHORIZED_STATUS_CODE,
} from "src/utils";

import { useGeoPosition } from "../components";
import { commonResultProps } from "../components/common";
import { PageLayout } from "../components/PageLayout";
import { ProtocolResolutionComponent } from "../components/ProtocolResolutionComponent";
import {
  protocolFillerLocalStorageContext,
  useProtocolFillerContext,
} from "../contexts";

export function ProtocolCreator() {
  const { bestPosition } = useGeoPosition();
  const bestPositionRef = useRef(bestPosition);
  const { t } = useTranslation("protocolFiller");
  const { t: translationT } = useTranslation();
  const { vehicleId, templateId } = useParams<{
    vehicleId: string;
    templateId: string;
  }>();
  const history = useHistory();

  const { createProtocol, sendingStatus, resetSendingState } =
    useProtocolFillerContext();

  const { clearStorage } = useContext(protocolFillerLocalStorageContext);
  const [errorMessage, setErrorMessage] = useState<{
    title: string;
    subTitle?: string;
    message?: string;
    extraButton: "retry" | "startOver";
  }>({
    title: t("protocolCreationFailed"),
    subTitle: translationT("errors.pleaseTryAgainOrContactAdministrator"),
    extraButton: "retry",
  });
  const { logAnalyticsEvent } = useReporting();

  useEffect(() => {
    clearStorage();
  }, [clearStorage]);

  useEffect(() => {
    return () => {
      resetSendingState();
    };
  }, [resetSendingState]);

  useEffect(() => {
    (async () => {
      if (sendingStatus !== "toSend") {
        return;
      }

      try {
        await createProtocol(templateId, vehicleId, bestPositionRef.current);
        logAnalyticsEvent({
          eventType: "finishedFillingProtocol",
          payload: {
            resolution: "success",
          },
        });
      } catch (e) {
        if (e instanceof NoConnectionError) {
          setErrorMessage({
            title: translationT("errors.noInternet.message"),
            subTitle: translationT("errors.noInternet.subtitle"),
            extraButton: "retry",
          });
        } else {
          if (e instanceof ApiError) {
            if (
              e.statusCode === BAD_REQUEST_STATUS_CODE &&
              NON_FIELD_ERRORS_FIELD_NAME in e.body
            ) {
              const errorMessage =
                e.body[NON_FIELD_ERRORS_FIELD_NAME].join(", ");

              Sentry.captureMessage(
                "Protocol sending failed - validation error",
                {
                  level: "warning",
                  extra: {
                    errorMessage: errorMessage,
                    status: e.statusCode,
                  },
                }
              );
              setErrorMessage({
                title: t("protocolCreationFailed"),
                message: errorMessage,
                extraButton: "startOver",
              });
            } else {
              if (e.statusCode !== UNAUTHORIZED_STATUS_CODE) {
                Sentry.captureMessage("Protocol sending failed", {
                  level: "error",
                  extra: {
                    response: JSON.stringify(e.body),
                    status: e.statusCode,
                  },
                });
              }

              setErrorMessage({
                title: t("protocolCreationFailed"),
                subTitle: translationT(
                  "errors.pleaseTryAgainOrContactAdministrator"
                ),
                extraButton: "retry",
              });
            }
          } else {
            Sentry.captureException(e);
            setErrorMessage({
              title: t("protocolCreationFailed"),
              subTitle: translationT(
                "errors.pleaseTryAgainOrContactAdministrator"
              ),
              extraButton: "retry",
            });
          }
        }
        logAnalyticsEvent({
          eventType: "finishedFillingProtocol",
          payload: {
            resolution: "failed",
          },
        });
      }
    })();
  }, [
    templateId,
    vehicleId,
    sendingStatus,
    createProtocol,
    t,
    translationT,
    logAnalyticsEvent,
  ]);

  return (
    <PageLayout progress="finish">
      {sendingStatus === "sending" || sendingStatus === "toSend" ? (
        <>
          <Result
            {...commonResultProps}
            icon={<Spin size="large" />}
            title={t("pleaseWaitMessage")}
            subTitle={t("creatingProtocolMessage")}
          />
          <Prompt
            message={translationT("quittingWithUnsavedChangesConfirmation")}
          />
        </>
      ) : sendingStatus === "failed" ? (
        <ProtocolResolutionComponent
          status="error"
          logoutButtonText={t("logout")}
          extraContent={
            errorMessage.extraButton === "retry" ? (
              <Button
                key="retry"
                onClick={() => {
                  logAnalyticsEvent({
                    eventType: "retriedSendingProtocol",
                    payload: {},
                  });
                  resetSendingState();
                }}
              >
                {t("retry")}
              </Button>
            ) : (
              <Button
                key="startOver"
                onClick={() => history.push(urls.protocolFiller.main())}
              >
                {translationT("startOver")}
              </Button>
            )
          }
          title={errorMessage.title}
          subTitle={errorMessage.subTitle}
          message={errorMessage.message}
        />
      ) : (
        <Redirect to={urls.protocolFiller.main()} />
      )}
    </PageLayout>
  );
}
