import { Form } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ModalForm, Spinner } from "src/components";

import { backOfficeApi } from "../api";
import { RetrieveVehicleCustomVehicleField, VehicleObject } from "../models";
import { CustomVehicleFieldFormItem } from "./CustomVehicleFieldFormItem";

export interface VehicleFieldValueEditModalProps {
  vehicle: VehicleObject;
  customVehicleFieldId: string;
  onModalClose(): void;
  onFieldUpdate(): void;
}

export function VehicleFieldValueEditModal({
  vehicle,
  customVehicleFieldId,
  ...props
}: VehicleFieldValueEditModalProps) {
  const { t } = useTranslation("backoffice");

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(true);
  const [vehicleFieldValue, setVehicleFieldValue] =
    useState<RetrieveVehicleCustomVehicleField | null>(null);

  useEffect(() => {
    setIsLoading(true);
    backOfficeApi
      .getVehicleCustomVehicleField(vehicle.id, customVehicleFieldId)
      .then(setVehicleFieldValue)
      .then(() => setIsLoading(false));
  }, [vehicle, customVehicleFieldId]);

  if (isLoading || !vehicleFieldValue) {
    return <Spinner />;
  }
  return (
    <ModalForm
      open
      cancelButtonText={t("cancel")}
      confirmButtonText={t("save")}
      formTitle={vehicle.plateNumber}
      form={form}
      closeModal={props.onModalClose}
      saveCallback={async (values) => {
        await backOfficeApi.updateVehicleCustomFields(vehicle.id, values);
      }}
      onSuccessfulSave={props.onFieldUpdate}
      width={650}
      initialValues={{
        [customVehicleFieldId]: {
          value: vehicleFieldValue?.valueObject.value,
        },
      }}
    >
      <CustomVehicleFieldFormItem
        customVehicleField={vehicleFieldValue.fieldObject}
        vehicleCustomVehicleField={vehicleFieldValue}
        formItemName={[customVehicleFieldId, "value"]}
        isFieldEdited
        toggleEditedVehicleField={() => {}}
        displayMode="editingFieldValue"
        vehicleId={vehicle.id}
      />
    </ModalForm>
  );
}
