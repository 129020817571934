import { HistoryOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Collapse,
  Divider,
  Form,
  Input,
  Space,
  Tooltip,
} from "antd";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { StandardCustomVehicleFieldType } from "src/company";
import {
  ChoiceFieldComponent,
  ChoiceFieldComponentProps,
  DateFieldComponent,
  DateMonthFieldComponent,
  Icon,
  MultipleImagesImmediateUploader,
  NumberFieldComponent,
  SingleImageImmediateUploader,
} from "src/components";
import { urls } from "src/urls";

import {
  ListCustomVehicleField,
  RetrieveCustomVehicleField,
  RetrieveVehicleCustomVehicleField,
} from "../models";
import { CommonFieldProtocolResponse } from "./CommonFieldProtocolResponse";
import { ProtocolResponseLabelWithStatus } from "./ProtocolResponseLabelWithStatus";
import { ProtocolResponseRow } from "./ProtocolResponseRow";

interface ProtocolResponseRowContentProps {
  customVehicleField: ListCustomVehicleField | RetrieveCustomVehicleField;
  vehicleCustomVehicleField: RetrieveVehicleCustomVehicleField | undefined;
  isFieldEdited: boolean;
  toggleEditedVehicleField: (field: { id: string }) => void | null;
  displayMode: "creatingVehicle" | "editingVehicle" | "editingFieldValue";
  vehicleId?: string;
  formItemName: string | string[];
}
const fieldTypesConfig: Record<
  StandardCustomVehicleFieldType,
  {
    component: ((props: any) => JSX.Element) | FunctionComponent;
    getComponentProps: (
      field: ListCustomVehicleField | RetrieveCustomVehicleField
    ) => any;
  }
> = {
  text: {
    component: Input,
    getComponentProps: () => ({}),
  },
  number: {
    component: NumberFieldComponent,
    getComponentProps: () => ({}),
  },
  date: {
    component: DateFieldComponent,
    getComponentProps: () => ({}),
  },
  "date-month": {
    component: DateMonthFieldComponent,
    getComponentProps: () => ({}),
  },
  choice: {
    component: ChoiceFieldComponent,
    getComponentProps: (
      field
    ): Omit<ChoiceFieldComponentProps, "value" | "onChange"> => ({
      choices: field.choices.map((choice) => ({ ...choice, value: choice.id })),
      verticalListWidth: 240,
    }),
  },
  "photo-action": {
    component: SingleImageImmediateUploader,
    getComponentProps: (field) => ({
      uploaderId: field.id,
    }),
  },
};

export function CustomVehicleFieldFormItem({
  customVehicleField,
  vehicleCustomVehicleField,
  isFieldEdited,
  toggleEditedVehicleField,
  displayMode,
  vehicleId,
  formItemName,
}: ProtocolResponseRowContentProps) {
  const { t } = useTranslation();
  const { t: tBackoffice } = useTranslation("backoffice");

  const history = useHistory();

  if (customVehicleField.type === "damages") {
    return null;
  }

  const fieldConfig = fieldTypesConfig[customVehicleField.type];
  const FieldComponent = fieldConfig.component;
  const additionalProps = fieldConfig.getComponentProps(customVehicleField);

  return (
    <ProtocolResponseRow
      leftContent={
        <ProtocolResponseLabelWithStatus
          label={customVehicleField.label}
          status={vehicleCustomVehicleField?.valueObject?.status}
        >
          {isFieldEdited ? (
            <>
              <Form.Item name={formItemName} noStyle>
                <FieldComponent {...additionalProps} />
              </Form.Item>
              {customVehicleField.type === "choice" && (
                <Collapse
                  defaultActiveKey={
                    displayMode === "editingVehicle" ? "photos_note" : undefined
                  }
                  style={{ marginTop: 10 }}
                >
                  <Collapse.Panel
                    header={`${t("addAComment")} / ${t("addPhotos")}`}
                    key="photos_note"
                  >
                    <Form.Item
                      name={[
                        "customFieldValues",
                        customVehicleField.id,
                        "note",
                      ]}
                      label={t("addAComment")}
                    >
                      <Input.TextArea />
                    </Form.Item>
                    <Form.Item
                      name={[
                        "customFieldValues",
                        customVehicleField.id,
                        "photos",
                      ]}
                      label={t("addPhotos")}
                      style={{ marginBottom: 0 }}
                    >
                      <MultipleImagesImmediateUploader
                        uploaderId={customVehicleField.id}
                      />
                    </Form.Item>
                  </Collapse.Panel>
                </Collapse>
              )}
            </>
          ) : (
            <CommonFieldProtocolResponse
              {...{
                fieldLabel: customVehicleField.label,
                type: customVehicleField.type,
                value: "",
                note: "",
                photos: [],
                choices: [],
                isMultichoice: false,
                ...(vehicleCustomVehicleField?.valueObject
                  ? {
                      value:
                        vehicleCustomVehicleField.valueObject.valueFormatted,
                      note: vehicleCustomVehicleField.valueObject.note,
                      photos: vehicleCustomVehicleField.valueObject.photos,
                      choices: [
                        {
                          label:
                            vehicleCustomVehicleField.valueObject
                              .valueFormatted,
                          id: "",
                          wasSelected: true,
                        },
                      ],
                    }
                  : {}),
              }}
            />
          )}
        </ProtocolResponseLabelWithStatus>
      }
      rightContent={
        isFieldEdited ? (
          displayMode === "editingVehicle" ? (
            <Button
              danger
              shape="round"
              size="small"
              onClick={() =>
                toggleEditedVehicleField({
                  id: customVehicleField.id,
                })
              }
              title={`${tBackoffice("vehicleView.customFields.cancel")} - ${
                customVehicleField.label
              }`}
            >
              <Space>
                <Icon icon="times" />
                {tBackoffice("vehicleView.customFields.cancel")}
              </Space>
            </Button>
          ) : displayMode === "editingFieldValue" ? undefined : null
        ) : (
          <Space size="small" align="center">
            <Button
              shape="round"
              size="small"
              onClick={() =>
                toggleEditedVehicleField({
                  id: customVehicleField.id,
                })
              }
              title={`${tBackoffice("vehicleView.customFields.edit")} - ${
                customVehicleField.label
              }`}
            >
              <Space>
                <Icon icon="edit" />
                {tBackoffice("vehicleView.customFields.edit")}
              </Space>
            </Button>
            {!!vehicleId ? (
              <>
                <Divider type="vertical" style={{ height: 30 }} />
                <Tooltip
                  title={tBackoffice(
                    "vehicleView.customFields.showHistoryOfChanges"
                  )}
                >
                  <Badge
                    count={
                      vehicleCustomVehicleField?.valueObject &&
                      vehicleCustomVehicleField?.valueObject.sourceResponse
                        ? "P"
                        : undefined
                    }
                    data-testid={`source-response-for-${customVehicleField.id}`}
                    color="blue"
                    style={{ top: 4, right: 4 }}
                  >
                    <Button
                      icon={<HistoryOutlined />}
                      shape="round"
                      size="small"
                      onClick={() =>
                        history.push(
                          customVehicleField.customVehicleFieldsGroupObject
                            ? urls.backOffice.vehicleFieldWithGroupValueHistory(
                                vehicleId,
                                customVehicleField.id,
                                customVehicleField
                                  .customVehicleFieldsGroupObject.id
                              )
                            : urls.backOffice.vehicleFieldWithoutGroupValueHistory(
                                vehicleId,
                                customVehicleField.id
                              )
                        )
                      }
                      title={`${tBackoffice(
                        "vehicleView.customFields.showHistoryOfChanges"
                      )} - ${customVehicleField.label}`}
                    >
                      {tBackoffice(
                        "vehicleView.customFields.historyAndCharges"
                      )}
                    </Button>
                  </Badge>
                </Tooltip>
              </>
            ) : null}
          </Space>
        )
      }
    />
  );
}
