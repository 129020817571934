import * as Sentry from "@sentry/react";
import { Route, Switch } from "react-router-dom";

import { urls } from "src/urls";

import { NewDamagesNotificationsSettings } from "./NewDamagesNotificationsSettings";
import { NewProtocolNotificationsSettings } from "./NewProtocolNotificationsSettings";
import { NotificationsSettingsListView } from "./NotificationsSettingsListView";

const SentryRoute = Sentry.withSentryRouting(Route);
export function NotificationsSettingsRouting() {
  return (
    <Switch>
      <SentryRoute
        path={urls.backOffice.settings.notifications.newDamages()}
        exact
      >
        <NewDamagesNotificationsSettings />
      </SentryRoute>
      <SentryRoute
        path={urls.backOffice.settings.notifications.newProtocols()}
        exact
      >
        <NewProtocolNotificationsSettings />
      </SentryRoute>
      <SentryRoute
        path={urls.backOffice.settings.notifications.baseView()}
        exact
      >
        <NotificationsSettingsListView />
      </SentryRoute>
    </Switch>
  );
}
