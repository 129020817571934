import { CameraOutlined, PaperClipOutlined } from "@ant-design/icons";
import { Alert, Button, Upload } from "antd";
import { UploadFile } from "antd/es/upload/interface";
import React, { useState, useRef } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import * as uuid from "uuid";

import { useWebView } from "src/hooks";
import { StorageUploadedImage, uploadTemporaryFileUrl } from "src/storage";
import {
  compressFile,
  defaultAxiosRequestConfig,
  getCookie,
  getFilenameFromUrl,
} from "src/utils";

import { SingleImagePreview } from "../SingleImagePreview";
import { useImmediateImageUploadersContext } from "./ImmediateImageUploadersContext";
import { usePreventPhotoUploadFromFileBrowserContext } from "./PreventPhotoUploadFromFileBrowserContext";

export interface ImmediateImageUploaderProps {
  singleImageMode?: boolean;
  value?: StorageUploadedImage[];
  onChange?(images: StorageUploadedImage[]): void;
  uploaderId: string;
  uploadType: "files" | "images";
  beforeUpload?: (file: File) => void;
}

type ImmediateUploadFile = UploadFile<StorageUploadedImage>;

interface ImmediateUploadFileWithResponse extends ImmediateUploadFile {
  response: Required<ImmediateUploadFile>["response"];
}

export function CommonImmediateFileUploader({
  uploaderId,
  ...props
}: ImmediateImageUploaderProps) {
  const uploadRef = useRef<typeof Upload>(null);
  const immediateImageUploadersContext = useImmediateImageUploadersContext();
  const { preventPhotoUploadFromFileBrowser } =
    usePreventPhotoUploadFromFileBrowserContext();

  const { t } = useTranslation();
  const [previewImageUrl, setPreviewImageUrl] = useState("");
  const { isInWebView, requestImageCapture, isPending } = useWebView();

  const defaultFileList = props.value
    ? props.value.map((file) => {
        const newFile: ImmediateUploadFile = {
          uid: uuid.v4(),
          response: file,
          status: "done",
          percent: 100,
          name: getFilenameFromUrl(file.fileName),
          thumbUrl: file.fileUrl,
        };
        return newFile;
      })
    : undefined;

  const csrfTokenCookieValue = getCookie(
    defaultAxiosRequestConfig.xsrfCookieName
  );
  const requestHeaders = csrfTokenCookieValue
    ? {
        [defaultAxiosRequestConfig.xsrfHeaderName]: csrfTokenCookieValue,
      }
    : {};

  const shouldDisplayUploader =
    isMobile ||
    !preventPhotoUploadFromFileBrowser ||
    immediateImageUploadersContext.allowSelectingImageFromPhoneGallery;

  return shouldDisplayUploader ? (
    <>
      <Upload
        withCredentials
        ref={uploadRef}
        capture={
          immediateImageUploadersContext.allowSelectingImageFromPhoneGallery
            ? undefined
            : "environment"
        }
        listType="picture"
        accept={props.uploadType === "images" ? "image/*" : undefined}
        action={
          defaultAxiosRequestConfig.baseURL +
          uploadTemporaryFileUrl +
          `?upload_type=${props.uploadType}`
        }
        headers={requestHeaders}
        beforeUpload={(file) => {
          if (props.beforeUpload) {
            props.beforeUpload(file);
          }

          return props.uploadType === "images"
            ? compressFile(
                file,
                immediateImageUploadersContext.compressionPreset
              )
            : file;
        }}
        onPreview={(file) => {
          setPreviewImageUrl(file.response?.fileUrl || "");
        }}
        progress={{
          showInfo: false,
          strokeWidth: 4,
        }}
        maxCount={props.singleImageMode ? 1 : undefined}
        onChange={handleOnChange}
        defaultFileList={defaultFileList}
      >
        <Button
          icon={
            props.uploadType === "images" ? (
              <CameraOutlined />
            ) : (
              <PaperClipOutlined />
            )
          }
          loading={isPending}
          disabled={isPending}
          onClick={async (event) => {
            if (isInWebView) {
              event.preventDefault();
              event.stopPropagation();
              requestImageCapture(
                (uploadedFile) => {
                  if (uploadRef.current) {
                    // @ts-ignore
                    uploadRef.current.upload.uploader.uploadFiles([
                      uploadedFile,
                    ]);
                  }
                },
                immediateImageUploadersContext.allowSelectingImageFromPhoneGallery,
                immediateImageUploadersContext.useExternalCameraApplicationInMobileApp,
                !props.singleImageMode
              );
            }
          }}
        >
          {props.singleImageMode && defaultFileList
            ? props.uploadType === "images"
              ? t("replacePicture")
              : t("replaceFile")
            : props.uploadType === "images"
            ? t("addPicture")
            : t("addFile")}
        </Button>
      </Upload>
      {!!previewImageUrl.length && (
        <SingleImagePreview
          close={() => setPreviewImageUrl("")}
          src={previewImageUrl}
        />
      )}
    </>
  ) : (
    <Alert
      message={t("useMobileDeviceToUploadPhoto")}
      type="info"
      showIcon
      icon={<CameraOutlined />}
    />
  );

  async function handleOnChange({
    fileList,
  }: {
    fileList: ImmediateUploadFile[];
  }): Promise<void> {
    const uploaderStatus = isGivenStatusOfFiles("error", fileList)
      ? "error"
      : isGivenStatusOfFiles("uploading", fileList)
      ? "uploading"
      : "done";
    const uploadingFinishedFiles = fileList.filter(isFileUploaded);

    immediateImageUploadersContext.setUploaderStatus(
      uploaderId,
      uploaderStatus
    );

    const isAllFilesUploading =
      fileList.length &&
      fileList.filter((file) => file.status === "uploading").length ===
        fileList.length;

    if (isAllFilesUploading) {
      // When uploading a first file to a required Form Field we don't want to
      // call onChange with empty [] in order to prevent from the "required"
      // error message being displayed
      return;
    }
    props.onChange?.(uploadingFinishedFiles.map((file) => file.response));
  }

  function isGivenStatusOfFiles(
    givenStatus: UploadFile["status"],
    fileList: ImmediateUploadFile[]
  ): boolean {
    return !!fileList.filter((file) => file.status === givenStatus).length;
  }
}

function isFileUploaded(
  file: ImmediateUploadFile
): file is ImmediateUploadFileWithResponse {
  return file.status === "done" && !!file.response;
}
