import * as Sentry from "@sentry/react";
import { Route, Switch } from "react-router-dom";

import { urls } from "src/urls";

import { TemplateEditorView } from "./TemplateEditorView";
import { TemplatesListView } from "./TemplatesListView";

const SentryRoute = Sentry.withSentryRouting(Route);
export function TemplateBuilderRouter() {
  return (
    <Switch>
      <SentryRoute path={urls.backOffice.templateBuilder()} exact>
        <TemplatesListView />
      </SentryRoute>
      <SentryRoute path={urls.backOffice.templateBuilderNew()} exact>
        <TemplateEditorView />
      </SentryRoute>
      <SentryRoute path={urls.backOffice.templateBuilderEdit()} exact>
        <TemplateEditorView />
      </SentryRoute>
      <SentryRoute path={urls.backOffice.templateBuilderClone()} exact>
        <TemplateEditorView isCloning />
      </SentryRoute>
    </Switch>
  );
}
