import { Form, Select } from "antd";
import { useTranslation } from "react-i18next";

interface Props {
  userRolesOptions: { label: string; value: string }[];
}

export function UserRolesFormItem(props: Props) {
  const { t } = useTranslation("backoffice");

  return (
    <Form.Item label={t("reportsView.filters.userRoles")} name="userRoles">
      <Select
        allowClear
        mode="multiple"
        style={{ maxWidth: 400 }}
        options={props.userRolesOptions}
        placeholder={t("reportsView.filters.allUserRoles")}
      />
    </Form.Item>
  );
}
