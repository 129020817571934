import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { NoConnectionResultPage } from "src/components";
import { urls } from "src/urls";

import { VehicleDocumentsHeader, VehicleSelector } from "../../components";
import { PageLayout } from "../../components/PageLayout";
import { useVehicles } from "../../hooks";

export function DocumentsVehicleSelectorView() {
  const { t } = useTranslation("protocolFiller");
  const history = useHistory();

  const [wasSubmittedWithMissingData, setWasSubmittedWithMissingData] =
    useState(false);

  const [vehicleId, setVehicleId] = useState("");

  useEffect(() => {
    setWasSubmittedWithMissingData(false);
  }, [vehicleId]);

  const { vehicles, loadingState, reloadVehicles } = useVehicles();

  if (loadingState === "error") {
    return <NoConnectionResultPage onRetryClick={reloadVehicles} />;
  }
  return (
    <PageLayout
      onBackClick={() => history.push(urls.landingPage())}
      onNextClick={
        !!vehicleId
          ? () =>
              history.push(urls.documents.listDocumentsForVehicle(vehicleId))
          : () => setWasSubmittedWithMissingData(true)
      }
    >
      <VehicleDocumentsHeader
        onBackClick={() => history.push(urls.landingPage())}
      />
      <VehicleSelector
        value={vehicleId}
        onChange={setVehicleId}
        vehicles={vehicles}
        isLoading={loadingState === "loading"}
        validationErrors={
          wasSubmittedWithMissingData
            ? [t("questionValidationErrors.requiredQuestion")]
            : []
        }
      />
    </PageLayout>
  );
}
