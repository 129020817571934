import { fetchAllPaginatedElements } from "@inspecto/common";
import * as Sentry from "@sentry/react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, useParams } from "react-router-dom";

import { useAuthentication } from "src/authentication";
import { Spinner } from "src/components";
import { urls } from "src/urls";

import { backOfficeApi } from "../../../api";
import { useFetch } from "../../../hooks";
import { AssignCompanyDepartmentToUsersView } from "./AssignCompanyDepartmentToUsersView";
import { AssignCompanyDepartmentToVehiclesView } from "./AssignCompanyDepartmentToVehiclesView";
import { EditCompanyDepartmentView } from "./EditCompanyDepartmentView";

const SentryRoute = Sentry.withSentryRouting(Route);
export function EditCompanyDepartmentRouter() {
  const { t } = useTranslation("backoffice");
  const { reloadUserWithoutLoading } = useAuthentication();
  const { companyDepartmentId } = useParams<{ companyDepartmentId: string }>();
  const companyDepartmentGetter = useCallback(
    () => backOfficeApi.getCompanyDepartment(companyDepartmentId),
    [companyDepartmentId]
  );
  const [
    currentCompanyDepartmentState,
    ,
    refetchCurrentCompanyDepartmentWithoutLoading,
  ] = useFetch(companyDepartmentGetter);
  const companyDepartmentsFetcher = useCallback(
    async () =>
      fetchAllPaginatedElements((pageNumber) =>
        backOfficeApi.getCompanyDepartments({
          pageNumber,
        })
      ),
    []
  );
  const [companyDepartmentsState, , refetchCompanyDepartmentsWithoutLoading] =
    useFetch(companyDepartmentsFetcher);

  const refetchAllData = useCallback(() => {
    refetchCurrentCompanyDepartmentWithoutLoading();
    refetchCompanyDepartmentsWithoutLoading();
    reloadUserWithoutLoading();
  }, [
    refetchCompanyDepartmentsWithoutLoading,
    refetchCurrentCompanyDepartmentWithoutLoading,
    reloadUserWithoutLoading,
  ]);

  const breadcrumbs = [
    { label: t("settings.label") },
    {
      label: t("settings.companyDepartments.listLabel"),
      url: urls.backOffice.settings.companyDepartments(),
    },
  ];
  const nestedBreadcrumbs = [
    ...breadcrumbs,
    {
      label: t("settings.companyDepartments.singleItemLabel"),
      url: urls.backOffice.settings.editCompanyDepartment(companyDepartmentId),
    },
  ];

  return currentCompanyDepartmentState.state === "dataFetched" &&
    companyDepartmentsState.state === "dataFetched" ? (
    <Switch>
      <SentryRoute
        path={urls.backOffice.settings.editCompanyDepartment()}
        exact
      >
        <EditCompanyDepartmentView
          companyDepartment={currentCompanyDepartmentState.data}
          breadcrumbs={breadcrumbs}
          onSuccessfulSave={refetchAllData}
        />
      </SentryRoute>
      <SentryRoute
        path={urls.backOffice.settings.assignCompanyDepartmentToVehicles()}
        exact
      >
        <AssignCompanyDepartmentToVehiclesView
          companyDepartment={currentCompanyDepartmentState.data}
          breadcrumbs={nestedBreadcrumbs}
          allCompanyDepartments={companyDepartmentsState.data}
          onSuccessfulSave={refetchAllData}
        />
      </SentryRoute>
      <SentryRoute
        path={urls.backOffice.settings.assignCompanyDepartmentToUsers()}
        exact
      >
        <AssignCompanyDepartmentToUsersView
          companyDepartment={currentCompanyDepartmentState.data}
          breadcrumbs={nestedBreadcrumbs}
          allCompanyDepartments={companyDepartmentsState.data}
          onSuccessfulSave={refetchAllData}
        />
      </SentryRoute>
    </Switch>
  ) : (
    <Spinner />
  );
}
