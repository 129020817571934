import * as fullStory from "@fullstory/browser";
import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import styled, { ThemeProvider } from "styled-components";

import { AuthenticationManager } from "src/authentication";
import { ForgotPassword } from "src/authentication/ForgotPassword";
import { PasswordReset } from "src/authentication/PasswordReset";
import { ProtectedRoute } from "src/authentication/ProtectedRoute";
import { BackOffice } from "src/backOffice";
import {
  CeleryTaskProgressProvider,
  CommonStateProvider,
} from "src/backOffice/contexts";
import { CompanyProvider } from "src/company/CompanyProvider";
import { PageReloadModal } from "src/components";
import { DeploymentInfoProvider } from "src/deploymentInfo";
import { DevToolbar } from "src/devToolbar";
import {
  WebViewProvider,
  ServiceWorkerProvider,
  useNewAppVersionAvailableHook,
} from "src/hooks";
import { LanguageProvider } from "src/locales";
import { DocumentsRouter, ProtocolFillerRouter } from "src/protocolFiller";
import { ProtocolFillerApiConfig } from "src/protocolFiller/components";
import { LanguageConfig } from "src/protocolFiller/components/LanguageConfig";
import { ProtocolFillerSummaryPageManager } from "src/protocolFiller/contexts";
import { ReportingProvider } from "src/reporting";
import { myTheme } from "src/theme";
import { urls } from "src/urls";
import { isDevelopment } from "src/utils";
import { HTTPCodeErrorPage, LandingPage } from "src/views";

import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import "yet-another-react-lightbox/styles.css";

const SentryRoute = Sentry.withSentryRouting(Route);

fullStory.init({
  orgId: "1521CE",
  devMode: isDevelopment(),
});
fullStory.shutdown();

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(calendar);
dayjs.extend(customParseFormat);

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast-container {
  }
  .Toastify__toast {
  }
  .Toastify__toast--error {
    background-color: ${(props) => props.theme.colors.status.notOk};
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
    background-color: ${(props) => props.theme.colors.status.ok};
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;

export function App() {
  const { t } = useTranslation("translation");
  const { isNewAppVersionAvailable } = useNewAppVersionAvailableHook();
  const isProtocolFillerPath = useRouteMatch({
    path: urls.protocolFiller.main(),
    strict: true,
  });

  return (
    <ServiceWorkerProvider isEnabled={!isDevelopment()}>
      <WebViewProvider>
        <LanguageProvider>
          <ThemeProvider theme={myTheme}>
            <CompanyProvider>
              <DeploymentInfoProvider>
                <AuthenticationManager>
                  <CeleryTaskProgressProvider>
                    <ReportingProvider>
                      <DevToolbar enabled={isDevelopment()}>
                        {!isDevelopment() && isNewAppVersionAvailable && (
                          <PageReloadModal
                            title={t(
                              "pageReloadModal.newVersionAvailableTitle"
                            )}
                            paragraph1={t(
                              "pageReloadModal.reloadNeededExplanation"
                            )}
                            paragraph2={
                              isProtocolFillerPath
                                ? t(
                                    "pageReloadModal.protocolFillerResponsesSavedInformation"
                                  )
                                : undefined
                            }
                          />
                        )}
                        <Switch>
                          <ProtectedRoute path={urls.landingPage()} exact>
                            <LandingPage />
                          </ProtectedRoute>
                          <ProtectedRoute
                            path={urls.documents.main()}
                            requiredTruthyUserProperty="canViewProtocolFiller"
                            requiredTruthyCompanyProperty="allowStoringDocumentsOnVehicles"
                          >
                            <LanguageConfig languageToUse="protocolFillerLanguage">
                              <DocumentsRouter />
                            </LanguageConfig>
                          </ProtectedRoute>
                          <SentryRoute
                            path={urls.protocolFiller.summarySuccess()}
                            exact
                          >
                            <Redirect to={urls.protocolFiller.main()} />
                          </SentryRoute>
                          <SentryRoute path={urls.protocolFiller.main()}>
                            <LanguageConfig languageToUse="protocolFillerLanguage">
                              <ProtocolFillerSummaryPageManager>
                                <ProtectedRoute
                                  path="*"
                                  requiredTruthyUserProperty="canViewProtocolFiller"
                                >
                                  <ProtocolFillerApiConfig>
                                    <ProtocolFillerRouter />
                                  </ProtocolFillerApiConfig>
                                </ProtectedRoute>
                              </ProtocolFillerSummaryPageManager>
                            </LanguageConfig>
                          </SentryRoute>

                          <ProtectedRoute
                            path={urls.backOffice.main()}
                            requiredTruthyUserProperty="canViewBackoffice"
                          >
                            <LanguageConfig languageToUse="language">
                              <CommonStateProvider>
                                <BackOffice />
                              </CommonStateProvider>
                            </LanguageConfig>
                          </ProtectedRoute>
                          <SentryRoute path={urls.passwordReset()} exact>
                            <PasswordReset />
                          </SentryRoute>
                          <SentryRoute path={urls.forgotPassword()} exact>
                            <ForgotPassword />
                          </SentryRoute>
                          <SentryRoute path="*">
                            <HTTPCodeErrorPage
                              errorCode="404"
                              redirectUrl={urls.landingPage()}
                            />
                          </SentryRoute>
                        </Switch>
                      </DevToolbar>
                    </ReportingProvider>
                  </CeleryTaskProgressProvider>
                </AuthenticationManager>
              </DeploymentInfoProvider>
            </CompanyProvider>
            <StyledToastContainer />
          </ThemeProvider>
        </LanguageProvider>
      </WebViewProvider>
    </ServiceWorkerProvider>
  );
}

export default App;
