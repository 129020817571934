import { LinkProps } from "react-router-dom";
import styled from "styled-components";

import { myTheme } from "src/theme";

import { RestrictedLink } from "./RestrictedLink";

interface LinkedObjectLinkProps extends LinkProps {
  $rightMargin?: boolean;
}

export const LinkedObjectLink = styled(RestrictedLink)<LinkedObjectLinkProps>`
  ${({ $rightMargin }) => $rightMargin && "margin-right: 8px;"}
  font-weight: 600;
  border: 1px dashed ${myTheme.colors.whitePanelBorderColor};
  border-radius: 10px;
  padding: 1px 8px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;
