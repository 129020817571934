import { Select } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { CustomVehicleFieldValueStatus } from "../models";
import { CustomVehicleFieldStatusBadge } from "./CustomVehicleFieldStatusBadge";

interface Props {
  value?: CustomVehicleFieldValueStatus[];
  onChange?: (values: CustomVehicleFieldValueStatus[]) => void;
  excludeNone?: boolean;
  placeholder?: string;
}

export function CustomVehicleFieldStatusSelect({
  excludeNone,
  onChange,
  value,
  placeholder,
}: Props) {
  const { t } = useTranslation("backoffice");

  const statusOptions = useMemo<
    {
      label: string;
      value: CustomVehicleFieldValueStatus;
    }[]
  >(
    () => [
      { label: t("fleetState.statuses.ok"), value: "ok" },
      { label: t("fleetState.statuses.notOk"), value: "not_ok" },
      { label: t("fleetState.statuses.warning"), value: "warning" },
      ...(excludeNone
        ? []
        : [
            {
              label: t("fleetState.statuses.none"),
              value: "none" as CustomVehicleFieldValueStatus,
            },
          ]),
    ],
    [t, excludeNone]
  );

  return (
    <Select
      mode="multiple"
      allowClear
      showArrow
      optionFilterProp="label"
      style={{ width: 350 }}
      value={value}
      onChange={onChange}
      maxTagCount="responsive"
      options={statusOptions}
      placeholder={placeholder || t("fleetState.fieldNames.status")}
      tagRender={({ value }) => (
        <CustomVehicleFieldStatusBadge status={value} />
      )}
    />
  );
}
