import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { MultiSelect } from "src/components";

import {
  AssignUnassignPayload,
  backOfficeApi,
  GetTemplatesFilters,
} from "../api";
import { ListRetrieveTemplateTag, ListTemplate } from "../models";
import {
  AssignItemsListView,
  ColumnsAssignItemsListViewProps,
} from "./AssignItemsListView";
import { DividerCheckboxFilterItem } from "./filterItems/DividerCheckboxFilterItem";
import { FilterItem, FiltersRow } from "./ListView";

interface Props {
  templateTagOptions: { label: string; value: string }[];
  extraFilters?: JSX.Element;
  isTemplateAssigned: (template: ListTemplate) => boolean;
  assignTemplates: (payload: AssignUnassignPayload) => Promise<void>;
  extraColumns?: ColumnsAssignItemsListViewProps<ListTemplate>;
}

export function AssignTemplatesListView({
  extraColumns = [],
  ...props
}: Props): JSX.Element {
  const { t } = useTranslation("backoffice");

  const columns = useCallback(
    (selectedRows) => [
      { dataIndex: "label", title: t("builder.templateLabel"), width: "auto" },
      ...(typeof extraColumns === "function"
        ? extraColumns(selectedRows)
        : extraColumns),
    ],
    [extraColumns, t]
  );

  const dataGetter = useCallback((viewFilters: GetTemplatesFilters) => {
    return backOfficeApi.getTemplates(viewFilters);
  }, []);

  return (
    <AssignItemsListView
      title={t("assignTemplatesList.title")}
      commonStateViewName="assignTemplatesListView"
      dataGetter={dataGetter}
      isItemAssigned={props.isTemplateAssigned}
      assignItems={props.assignTemplates}
      filters={
        <>
          <FiltersRow>
            <FilterItem name="templateTags" $inputWidth="medium">
              <MultiSelect<ListRetrieveTemplateTag["id"]>
                style={{ width: 350 }}
                options={props.templateTagOptions}
                placeholder={t("settings.templateTags.label")}
                noMargin
                dropdownRender={(menu) => (
                  <DividerCheckboxFilterItem
                    checkboxLabel={t(
                      "assignTemplatesList.excludeTemplatesWithSelectedTags"
                    )}
                    filterItemName="excludeTemplateTags"
                  >
                    {menu}
                  </DividerCheckboxFilterItem>
                )}
              />
            </FilterItem>
            {props.extraFilters}
          </FiltersRow>
        </>
      }
      columns={columns}
    />
  );
}
