import { ApiError, NoConnectionError } from "@inspecto/common";
import { FormInstance } from "antd";
import { get as lodashGet } from "lodash";
import { DefaultResources } from "react-i18next";

export const BAD_REQUEST_STATUS_CODE = 400;
export const UNAUTHORIZED_STATUS_CODE = 401;
export const NOT_FOUND_STATUS_CODE = 404;
export const FORBIDDEN_STATUS_CODE = 403;
export const NON_FIELD_ERRORS_FIELD_NAME = "nonFieldErrors";

type FormErrors = {
  name: Array<string | number>;
  errors: string[];
};

function apiErrorsToFormErrors(
  errorsByFieldName: Record<string, any>
): FormErrors[] {
  const result: FormErrors[] = [];

  Object.entries(errorsByFieldName).forEach(
    ([fieldName, fieldErrorsOrErrorsByNestedFieldName]) => {
      if (
        !Array.isArray(fieldErrorsOrErrorsByNestedFieldName) &&
        typeof fieldErrorsOrErrorsByNestedFieldName === "object"
      ) {
        const nestedResults = apiErrorsToFormErrors(
          fieldErrorsOrErrorsByNestedFieldName
        );
        result.push({
          name: [fieldName],
          errors: [
            nestedResults.map((item) => item.errors.join(" ")).join(" "),
          ],
        });
        return;
      }

      if (
        fieldErrorsOrErrorsByNestedFieldName.length &&
        typeof fieldErrorsOrErrorsByNestedFieldName[0] !== "string"
      ) {
        fieldErrorsOrErrorsByNestedFieldName.forEach(
          (nestedErrorsByFieldName: any, index: number) => {
            const nestedResults = apiErrorsToFormErrors(
              nestedErrorsByFieldName
            );

            nestedResults.forEach((nestedResult) => {
              result.push({
                name: [fieldName, index, ...nestedResult.name],
                errors: nestedResult.errors,
              });
            });
          }
        );
        return;
      }

      result.push({
        name: [fieldName],
        errors: fieldErrorsOrErrorsByNestedFieldName,
      });
    }
  );

  return result;
}

export async function wrapCallback<TSaveOutput = void>(
  saveCallback: () => Promise<TSaveOutput>,
  form: FormInstance,
  setGlobalErrors: (errors: string[]) => void,
  setIsOfflineError: (isOffline: boolean) => void,
  unexpectedErrorMessage: string,
  formValidationErrors: DefaultResources["translation"]["formValidationErrors"],
  clearFieldErrors: boolean = true
): Promise<TSaveOutput> {
  setIsOfflineError(false);
  setGlobalErrors([]);

  if (clearFieldErrors) {
    let fieldsToReset = new Set<string>();
    for (const error of form.getFieldsError()) {
      for (const fieldName of error.name) {
        fieldsToReset.add(fieldName as string);
      }
    }

    if (fieldsToReset.size !== 0) {
      form.setFields(
        Array.from(fieldsToReset).map((fieldName) => ({
          name: fieldName,
          errors: [],
        }))
      );
    }
  }

  try {
    return await saveCallback();
  } catch (e) {
    if (e instanceof NoConnectionError) {
      setIsOfflineError(true);
    } else if (e instanceof ApiError) {
      if (e.statusCode === BAD_REQUEST_STATUS_CODE) {
        if (NON_FIELD_ERRORS_FIELD_NAME in e.body) {
          setGlobalErrors(e.body[NON_FIELD_ERRORS_FIELD_NAME]);
          delete e.body[NON_FIELD_ERRORS_FIELD_NAME];
        }

        let fields = apiErrorsToFormErrors(e.body).map((field) => {
          return {
            name: field.name.length === 1 ? field.name[0]! : field.name,
            errors: field.errors.map((error) => {
              if (error in formValidationErrors) {
                return formValidationErrors[
                  error as keyof typeof formValidationErrors
                ] as string;
              }
              return error;
            }),
          };
        });
        form.setFields(fields);
      } else if (
        [NOT_FOUND_STATUS_CODE, FORBIDDEN_STATUS_CODE].includes(e.statusCode) &&
        e.body.detail
      ) {
        setGlobalErrors([e.body.detail]);
      } else {
        setGlobalErrors([unexpectedErrorMessage]);
      }
    }
    throw e;
  }
}

export function createGlobalErrorsError(messages: string[]): ApiError {
  return new ApiError(BAD_REQUEST_STATUS_CODE, {
    [NON_FIELD_ERRORS_FIELD_NAME]: messages,
  });
}

export function getHaveFieldsUpdatedCallback(
  ...fieldNames: string[]
): (
  previousValues: Record<string, any>,
  currentValues: Record<string, any>
) => boolean {
  return (previousValues, currentValues) =>
    fieldNames.reduce<boolean>(
      (shouldUpdate, fieldName) =>
        shouldUpdate ||
        lodashGet(previousValues, fieldName) !==
          lodashGet(currentValues, fieldName),
      false
    );
}
