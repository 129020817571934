import {
  ArrowRightOutlined,
  DownloadOutlined,
  DownOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { useCompany } from "@inspecto/common";
import {
  Button,
  Dropdown,
  Form,
  Input,
  Select,
  Space,
  Switch,
  TableColumnType,
  Tag,
  Typography,
} from "antd";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { useAuthentication } from "src/authentication";
import { RemoveButton, RestoreButton } from "src/components";
import { urls } from "src/urls";
import { fullNameReversed } from "src/utils";

import { backOfficeApi, GetAllEmployeesFilters } from "../../api";
import {
  CompanyDepartmentsMultiSelectFilterItem,
  CompanyDepartmentTag,
  FilterItem,
  FiltersRow,
  PinDisplay,
} from "../../components";
import {
  EmployeeInferredStatus,
  EmployeesViewState,
  useCommonStateContext,
} from "../../contexts";
import { useRestoreDeletedEmployee } from "../../hooks";
import { useUserRoleOptions } from "../../hooks/useUserRoleOptions";
import { ListEmployee } from "../../models";
import { BackOfficeListViewLayout } from "../BackOfficeListViewLayout";

function employeesViewToApiFilters({
  tableParams,
  userRole,
  includeInactive,
  statuses,
  searchQuery,
  companyDepartments,
  excludeCompanyDepartments,
}: EmployeesViewState): GetAllEmployeesFilters {
  return {
    searchTerm: searchQuery,
    tableParams,
    searchIn: "name_and_email",
    userRoles: userRole ? [userRole] : [],
    includeInactive: includeInactive,
    status:
      statuses.includes("activated") && statuses.includes("not_activated")
        ? "all"
        : statuses.includes("activated")
        ? "is_activated"
        : statuses.includes("not_activated")
        ? "is_not_activated"
        : "all",
    emailFilter: "all",
    companyDepartments,
    excludeCompanyDepartments,
  };
}

export function EmployeesListView() {
  const company = useCompany();
  const { user } = useAuthentication();
  const { t } = useTranslation("backoffice");
  const history = useHistory();
  const [filtersForm] = Form.useForm();
  const { restore } = useRestoreDeletedEmployee();
  const { employeesView } = useCommonStateContext();

  const { isLoadingUserRoles, userRoleOptions } = useUserRoleOptions();

  const statusOptions = useMemo<
    { label: string; value: EmployeeInferredStatus }[]
  >(() => {
    return [
      {
        label: t("employeesView.employeeStatus.plural.activated"),
        value: "activated",
      },
      {
        label: t("employeesView.employeeStatus.plural.not_activated"),
        value: "not_activated",
      },
    ];
  }, [t]);

  const removeEmployee = useCallback(
    async (removingEmployeeId: string) => {
      await backOfficeApi.removeEmployee(removingEmployeeId);

      toast.success(t("employeeSuccessfullyDeleted"));
      filtersForm.submit();
    },
    [filtersForm, t]
  );

  const columns: TableColumnType<ListEmployee>[] = useMemo(
    () => [
      {
        title: `${t("name")} / ${t("email")}`,
        width: 320,
        render: (user: ListEmployee) => (
          <>
            <div>
              <Typography.Text strong>{fullNameReversed(user)}</Typography.Text>
            </div>
            {!!user.email && (
              <Typography.Text type="secondary">{user.email}</Typography.Text>
            )}
          </>
        ),
      },
      {
        dataIndex: "userRoleName",
        title: t("userRole"),
        width: 140,
      },
      {
        title: t("status"),
        width: 160,
        render: (user: ListEmployee) => (
          <>
            {!user.isActive ? (
              <Tag color="red">{t("employeesView.deletedUserTag")}</Tag>
            ) : user.hasPassword || !user.email ? (
              <Tag color="green">
                {t("employeesView.employeeStatus.singular.activated")}
              </Tag>
            ) : (
              <Tag>
                {t("employeesView.employeeStatus.singular.not_activated")}
              </Tag>
            )}
          </>
        ),
      },
      {
        dataIndex: "loginPin",
        title: t("loginPin"),
        width: "120px",
        render: (value: ListEmployee["loginPin"]) => (
          <PinDisplay pin={value || ""} />
        ),
      },
      ...(company.allowManagingCompanyDepartments
        ? ([
            {
              title: t("settings.companyDepartments.listLabel"),
              width: "auto",
              render: (user: ListEmployee) =>
                user.companyDepartments.map((companyDepartment) => (
                  <CompanyDepartmentTag>
                    {companyDepartment.name}
                  </CompanyDepartmentTag>
                )),
            },
          ] as TableColumnType<ListEmployee>[])
        : []),
      {
        key: "action",
        width: 290,
        align: "right",
        render: (value: ListEmployee) => (
          <Space size="small">
            <Dropdown
              menu={{
                items: [
                  {
                    key: "1",
                    label: t("protocolsView.pageTitle"),
                    icon: <FileDoneOutlined />,
                    onClick: () => {
                      history.push(
                        urls.backOffice.protocols({
                          selectedEmployee: {
                            value: value.id,
                            label: fullNameReversed(value),
                          },
                        })
                      );
                    },
                  },
                ],
              }}
            >
              <Button size="small">
                {t("tableActions.goTo")} <DownOutlined />
              </Button>
            </Dropdown>
            <Button
              size="small"
              onClick={() => {
                history.push(urls.backOffice.editEmployee(value.id));
              }}
              icon={<ArrowRightOutlined />}
            >
              {t("tableActions.edit")}
            </Button>
            {value.isActive ? (
              <RemoveButton
                onRemoveConfirm={async () => removeEmployee(value.id)}
              />
            ) : (
              <RestoreButton
                onRestoreConfirm={async () => {
                  await restore(value.id);
                  filtersForm.submit();
                }}
                confirmationTitle={t(
                  "employeesView.restoreUserConfirmationModal.title"
                )}
              />
            )}
          </Space>
        ),
      },
    ],
    [
      t,
      company.allowManagingCompanyDepartments,
      history,
      removeEmployee,
      restore,
      filtersForm,
    ]
  );

  const dataGetter = useMemo(
    () => async (employeesViewState: EmployeesViewState) => {
      return await backOfficeApi.getAllEmployees(
        employeesViewToApiFilters(employeesViewState)
      );
    },
    []
  );

  return (
    <BackOfficeListViewLayout
      dataGetter={dataGetter}
      columns={columns}
      createButtonAction={() => history.push(urls.backOffice.createEmployee())}
      pageTitle={t("employees")}
      commonStateViewName="employeesView"
      debouncedFilterProps={["searchQuery"]}
      filtersForm={filtersForm}
      stickyTableActions={[
        {
          callback: () =>
            backOfficeApi.downloadEmployeesList(
              employeesViewToApiFilters(employeesView)
            ),
          icon: <DownloadOutlined />,
          buttonLabel: t("tableActions.downloadXLSX"),
        },
      ]}
      filters={
        <>
          <FiltersRow>
            <FilterItem
              label={t("employeesView.showDeletedUsersSwitchLabel")}
              valuePropName="checked"
              name="includeInactive"
            >
              <Switch />
            </FilterItem>
          </FiltersRow>
          <FiltersRow>
            <FilterItem name="userRole" $inputWidth="medium">
              <Select
                allowClear
                loading={isLoadingUserRoles}
                options={userRoleOptions}
                placeholder={t("userRole")}
                aria-label={t("userRole")}
              />
            </FilterItem>
            <FilterItem name="statuses" $inputWidth="long">
              <Select<EmployeeInferredStatus>
                mode="multiple"
                aria-label={t("employeesView.statusSelectPlaceholder")}
                options={statusOptions}
                placeholder={t("employeesView.statusSelectPlaceholder")}
              />
            </FilterItem>
            <FilterItem name="searchQuery">
              <Input.Search allowClear placeholder={t("search")} />
            </FilterItem>
          </FiltersRow>
          {company.allowManagingCompanyDepartments && user && (
            <FiltersRow>
              <CompanyDepartmentsMultiSelectFilterItem
                options={user.allowedCompanyDepartments.map(
                  (companyDepartment) => ({
                    label: companyDepartment.name,
                    value: companyDepartment.id,
                  })
                )}
              />
            </FiltersRow>
          )}
        </>
      }
    />
  );
}
