import { useCompany } from "@inspecto/common";
import { Button, Form, Input, Modal, Space } from "antd";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CommonForm,
  ImmediateImageUploadersContextProvider,
  SingleFileImmediateUploader,
} from "src/components";
import { getFilenameWithoutExtension } from "src/utils";

import { backOfficeApi } from "../../../api";
import { VehicleDocument } from "../../../models";

interface Props {
  vehicleId: string;
  document: VehicleDocument | null;
  onClose: (savedDocument?: VehicleDocument) => void;
}

export function CreateEditDocumentModal(props: Props) {
  const [form] = Form.useForm<VehicleDocument>();
  const { allowStoringHighQualityImages } = useCompany();
  const { t } = useTranslation("backoffice");
  const [isSavingForm, setIsSavingForm] = useState(false);

  const isEditingExistingDocument = !!props.document?.id;

  const setLabelToFileName = useCallback(
    (file: File) => {
      const fileName = getFilenameWithoutExtension(file.name);
      const currentLabel = form.getFieldValue("label");
      if (typeof currentLabel === "undefined" || currentLabel === "") {
        form.setFieldsValue({ label: fileName });
      }
    },
    [form]
  );

  return (
    <Modal
      open
      onCancel={() => props.onClose()}
      title={
        isEditingExistingDocument
          ? t("vehicleView.filesFolder.edit")
          : t("vehicleView.filesFolder.create")
      }
      footer={
        <Space>
          <Button onClick={() => props.onClose()} loading={isSavingForm}>
            {t("cancel")}
          </Button>
          <Button
            onClick={() => form.submit()}
            type="primary"
            loading={isSavingForm}
          >
            {t("save")}
          </Button>
        </Space>
      }
    >
      <ImmediateImageUploadersContextProvider
        compressionPreset={
          allowStoringHighQualityImages ? "highQuality" : "standard"
        }
      >
        <CommonForm
          form={form}
          layout="vertical"
          saveCallback={async (data: VehicleDocument) => {
            setIsSavingForm(true);
            let savedDocument: VehicleDocument | undefined = undefined;
            try {
              if (isEditingExistingDocument) {
                savedDocument =
                  await backOfficeApi.documents.editVehicleDocument(
                    props.vehicleId,
                    data
                  );
              } else {
                savedDocument =
                  await backOfficeApi.documents.createVehicleDocument(
                    props.vehicleId,
                    data
                  );
              }
              props.onClose(savedDocument);
            } finally {
              setIsSavingForm(false);
            }
          }}
          initialValues={props.document || undefined}
        >
          <Form.Item name="id" hidden />
          <Form.Item
            name="file"
            rules={[{ required: true }]}
            label={t("vehicleView.filesFolder.file")}
          >
            <SingleFileImmediateUploader
              uploaderId="edit-or-create-document"
              beforeUpload={setLabelToFileName}
            />
          </Form.Item>
          <Form.Item
            name="label"
            rules={[{ required: true }]}
            label={t("vehicleView.filesFolder.label")}
          >
            <Input />
          </Form.Item>
        </CommonForm>
      </ImmediateImageUploadersContextProvider>
    </Modal>
  );
}
