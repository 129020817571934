import {
  ArrowRightOutlined,
  CarOutlined,
  DownloadOutlined,
  DownOutlined,
  FileDoneOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import { Currency } from "@inspecto/common";
import {
  Badge,
  Button,
  Dropdown,
  Form,
  message,
  Skeleton,
  Space,
  Switch,
  TableColumnType,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { RangePicker, SearchableAsyncSelect } from "src/components";
import { urls } from "src/urls";
import {
  fullName,
  isNoConnectionError,
  showNoConnectionNotification,
} from "src/utils";

import { backOfficeApi, ChargedEmployeeFilters } from "../../api";
import { FilterItem, FiltersRow } from "../../components";
import {
  ChargedEmployeeStatusChangeModal,
  ChargedEmployeeStatusChangeModalProps,
} from "../../components/ChargedEmployeesStatusChangeModal";
import { ChargedEmployeeStatusSelect } from "../../components/ChargedEmployeeStatusSelect";
import {
  EmployeeChargesViewState,
  useCommonStateContext,
} from "../../contexts";
import { useChargedEmployeeModal } from "../../hooks";
import {
  ChargedEmployeeStatus,
  ListChargedEmployee,
  ListEmployee,
  SimpleVehicle,
} from "../../models";
import { BackOfficeListViewLayout } from "../BackOfficeListViewLayout";

type ChargedEmployeeStatusOrMixed = ChargedEmployeeStatus | "mixed";

function chargesViewToApiFilters(
  chargesView: EmployeeChargesViewState
): ChargedEmployeeFilters {
  const [dateFrom, dateTo] = chargesView.datesFilter || [null, null];
  return {
    tableParams: chargesView.tableParams,
    employee: chargesView.selectedEmployee?.value || undefined,
    statuses: chargesView.statusFilters,
    dateAfter: dateFrom?.format("YYYY-MM-DD"),
    dateBefore: dateTo?.format("YYYY-MM-DD"),
    hideZeroCharges: chargesView.hideZeroCharges,
    vehiclePlateNumber: chargesView.selectedVehicle?.label,
  };
}

export function EmployeeChargesListView(): JSX.Element {
  const { t } = useTranslation("backoffice");
  const { t: tTranslation } = useTranslation("translation");
  const history = useHistory();
  const { chargesView } = useCommonStateContext();
  const [filtersForm] = Form.useForm();
  const [amountsByCurrency, setAmountsByCurrency] = useState<
    Partial<Record<Currency, number>> | "fetching"
  >("fetching");

  const updateTotalAmount = useCallback(
    async (filters: ChargedEmployeeFilters) => {
      setAmountsByCurrency("fetching");
      try {
        const response = await backOfficeApi.getChargedEmployeesTotalAmount(
          filters
        );
        setAmountsByCurrency(response.amountsByCurrency);
      } catch (e) {
        if (isNoConnectionError(e)) {
          showNoConnectionNotification(tTranslation);
          setAmountsByCurrency({});
        } else {
          throw e;
        }
      }
    },
    [tTranslation]
  );
  const dataGetter = useMemo(
    () => async (employeeChargesViewState: EmployeeChargesViewState) => {
      const filters = chargesViewToApiFilters(employeeChargesViewState);
      updateTotalAmount(filters);
      return await backOfficeApi.getChargedEmployees(filters);
    },
    [updateTotalAmount]
  );

  const [chargedEmployeeModal, setChargedEmployeeState] =
    useChargedEmployeeModal(() => filtersForm.submit());
  const [
    chargedEmployeeStatusChangeModalState,
    setChargedEmployeeStatusChangeModalState,
  ] = useState<Pick<
    ChargedEmployeeStatusChangeModalProps,
    "initialValues" | "chargedEmployeeIds"
  > | null>(null);

  const searchEmployees = useCallback(async (searchQuery, includeArchived) => {
    const employeeResults = await backOfficeApi.getAllEmployees({
      tableParams: {
        pageNumber: 1,
      },
      searchTerm: searchQuery,
      searchIn: "name",
      userRoles: [],
      includeInactive: includeArchived,
      status: "all",
      emailFilter: "all",
    });
    return employeeResults.results;
  }, []);

  const searchVehicles = useCallback(
    async (query: string, includeArchived: boolean) => {
      const vehicles = await backOfficeApi.getSimpleVehiclesList({
        tableParams: {
          pageNumber: 1,
          sortByField: "",
        },
        plateNumber: query,
        includeArchived,
      });

      return vehicles.results;
    },
    []
  );

  const columns = useMemo<TableColumnType<ListChargedEmployee>[]>(() => {
    return [
      {
        dataIndex: "employeeObject",
        title: t("employeeCharges.table.employeeColumnTitle"),
        width: "200px",
        render: (employee: ListChargedEmployee["employeeObject"]) => (
          <div>
            {employee.firstName} {employee.lastName}
          </div>
        ),
      },
      {
        title: t("employeeCharges.table.vehiclePlateNumberColumnTitle"),
        width: "120px",
        dataIndex: "vehiclePlateNumber",
      },
      {
        dataIndex: "comment",
        title: t("employeeCharges.table.commentColumnTitle"),
        width: "auto",
        render: (comment) => {
          return comment;
        },
      },
      {
        title: t("employeeCharges.table.amountColumnTitle"),
        width: 120,
        render: (chargedEmployee: ListChargedEmployee) => (
          <Badge
            status={
              [
                ChargedEmployeeStatus.PAID_BY_EMPLOYEE,
                ChargedEmployeeStatus.PAID_BY_INSURANCE,
              ].includes(chargedEmployee.status)
                ? "success"
                : "error"
            }
            text={
              <>
                <strong>{chargedEmployee.amount}</strong>{" "}
                {chargedEmployee.currency}
              </>
            }
          />
        ),
      },
      {
        title: t("employeeCharges.table.statusColumnTitle"),
        width: 190,
        render: (chargedEmployee: ListChargedEmployee) => {
          return (
            <ChargedEmployeeStatusSelect
              labelsAsTags
              style={{
                width: "100%",
              }}
              size="small"
              labelsType="shortName"
              value={chargedEmployee.status}
              onChange={async (value) => {
                try {
                  await backOfficeApi.editChargedEmployee(chargedEmployee.id, {
                    status: value,
                  });
                  filtersForm.submit();
                } catch (e) {
                  message.error(t("somethingWentWrong"));
                }
              }}
            />
          );
        },
      },
      {
        dataIndex: "date",
        title: t("employeeCharges.table.chargeDateColumnTitle"),
        width: "140px",
        render: (date) => {
          if (date) {
            return <>{dayjs(date as string).format("DD.MM.YYYY HH:mm")}</>;
          } else {
            return <></>;
          }
        },
      },
      {
        key: "action",
        align: "right",
        width: 210,
        render: (chargedEmployee: ListChargedEmployee) => {
          let menuItems: {
            icon: JSX.Element;
            url: string;
            label: string;
          }[] = [];

          if (chargedEmployee.historicalCustomVehicleFieldValueObject) {
            menuItems = menuItems.concat([
              {
                label: t("vehicleCard"),
                url: chargedEmployee.historicalCustomVehicleFieldValueObject
                  .customVehicleFieldsGroupId
                  ? urls.backOffice.vehicleFieldWithGroupValueHistory(
                      chargedEmployee.historicalCustomVehicleFieldValueObject
                        .vehicleObject.id,
                      chargedEmployee.historicalCustomVehicleFieldValueObject
                        .customVehicleFieldId,
                      chargedEmployee.historicalCustomVehicleFieldValueObject
                        .customVehicleFieldsGroupId
                    )
                  : urls.backOffice.vehicleFieldWithoutGroupValueHistory(
                      chargedEmployee.historicalCustomVehicleFieldValueObject
                        .vehicleObject.id,
                      chargedEmployee.historicalCustomVehicleFieldValueObject
                        .customVehicleFieldId
                    ),
                icon: <CarOutlined />,
              },
            ]);
          }

          if (chargedEmployee.responseObject) {
            menuItems = menuItems.concat([
              {
                label: t("protocol"),
                url: urls.backOffice.protocol(
                  chargedEmployee.responseObject.protocolId
                ),
                icon: <FileDoneOutlined />,
              },
            ]);
          }

          if (chargedEmployee.damageObject) {
            menuItems = menuItems.concat([
              {
                label: `${t("protocol")} - ${t("damages.pageTitle")}`,
                url: urls.backOffice.protocol(
                  chargedEmployee.damageObject.protocolId
                ),
                icon: <ThunderboltOutlined />,
              },
            ]);
          }

          return (
            <Space size="small">
              <Dropdown
                menu={{
                  items: menuItems.map((menuItem) => ({
                    key: menuItem.url,
                    icon: menuItem.icon,
                    label: menuItem.label,
                    onClick: () => {
                      history.push(menuItem.url);
                    },
                  })),
                }}
              >
                <Button size="small">
                  {t("tableActions.goTo")} <DownOutlined />
                </Button>
              </Dropdown>
              <Button
                size="small"
                onClick={() => {
                  setChargedEmployeeState({
                    responseId: chargedEmployee.id,
                    chargedEmployeeId: chargedEmployee.id,
                    initialValues: {
                      ...chargedEmployee,
                    },
                  });
                }}
                icon={<ArrowRightOutlined />}
              >
                {t("tableActions.edit")}
              </Button>
            </Space>
          );
        },
      },
    ];
  }, [filtersForm, history, setChargedEmployeeState, t]);

  const getOptionsFromSimpleVehicles = useCallback(
    (vehicles: SimpleVehicle[]) =>
      vehicles.map((vehicle) => ({
        value: vehicle.plateNumber,
        label: vehicle.plateNumber,
      })),
    []
  );

  const getOptionsFromListEmployees = useCallback(
    (employees: ListEmployee[]) =>
      employees.map((employee) => ({
        value: employee.id,
        label: fullName(employee),
      })),
    []
  );

  return (
    <BackOfficeListViewLayout
      dataGetter={dataGetter}
      columns={columns}
      createButtonAction={() => {
        setChargedEmployeeState({
          chargedEmployeeId: null,
          initialValues: { comment: "", status: ChargedEmployeeStatus.UNPAID },
        });
      }}
      sortedTableProps={["date", "vehiclePlateNumber"]}
      commonStateViewName="chargesView"
      pageTitle={t("employeeCharges.pageTitle")}
      filtersForm={filtersForm}
      filters={
        <>
          <FiltersRow>
            <FilterItem
              name="hideZeroCharges"
              label={t("employeeCharges.hideZeroCharges")}
              valuePropName="checked"
            >
              <Switch />
            </FilterItem>
          </FiltersRow>
          <FiltersRow>
            <FilterItem name="selectedVehicle" $inputWidth="medium">
              <SearchableAsyncSelect<SimpleVehicle>
                searchElements={searchVehicles}
                showArchivedCheckbox
                getOptionsFromResponse={getOptionsFromSimpleVehicles}
                placeholder={t("employeeCharges.plateNumber")}
                noMargin
              />
            </FilterItem>
            <FilterItem name="datesFilter">
              <RangePicker allowClear />
            </FilterItem>
          </FiltersRow>
          <FiltersRow>
            <FilterItem name="statusFilters" $inputWidth="veryLong">
              <ChargedEmployeeStatusSelect
                allowClear
                mode="multiple"
                placeholder={t("employeeCharges.statusSelectPlaceholder")}
                maxTagCount="responsive"
              />
            </FilterItem>
            <FilterItem name="selectedEmployee" $inputWidth="medium">
              <SearchableAsyncSelect
                searchElements={searchEmployees}
                getOptionsFromResponse={getOptionsFromListEmployees}
                notFoundContent={t("employeeCharges.noMatchingEmployeeFound")}
                placeholder={t("employeeCharges.startTypingToSearch")}
                noMargin
                showArchivedCheckbox
              />
            </FilterItem>
          </FiltersRow>
          <div style={{ marginBottom: 14 }}>
            {amountsByCurrency === "fetching" ? (
              <Skeleton />
            ) : (
              <Space align="end">
                <Typography.Text type="secondary">
                  {t("employeeCharges.totalAmountToPay")}:
                </Typography.Text>
                <div>
                  {Object.entries(amountsByCurrency)
                    .sort(([firstCurrency], [secondCurrency]) =>
                      firstCurrency.localeCompare(secondCurrency)
                    )
                    .map(([currency, amount]) => (
                      <div style={{ fontSize: 24, height: 30 }}>
                        <strong>{amount}</strong>{" "}
                        <span style={{ fontSize: 16 }}>{currency}</span>
                      </div>
                    ))}
                </div>
              </Space>
            )}
          </div>
        </>
      }
      stickyTableActions={[
        {
          callback: () =>
            backOfficeApi.downloadChargedEmployeesList(
              chargesViewToApiFilters(chargesView)
            ),
          icon: <DownloadOutlined />,
          buttonLabel: t("employeeCharges.downloadXLSX"),
        },
      ]}
      selectableRowActions={[
        {
          isCorrectNumberOfRows: (selectedRowsCount) => selectedRowsCount > 0,
          buttonLabel: t("employeeCharges.editSelectedButtonText"),
          selectionErrorMessage: t(
            "employeeCharges.firstSelectSomeChargesYouWantToEdit"
          ),
          callback: (selectedRows) => {
            setChargedEmployeeStatusChangeModalState({
              chargedEmployeeIds: selectedRows.map((row) => row.id),
              initialValues: {
                status: selectedRows.reduce<ChargedEmployeeStatusOrMixed>(
                  (status, item: ListChargedEmployee) =>
                    item.status === status ? status : "mixed",
                  selectedRows[0] ? selectedRows[0].status : "mixed"
                ),
              },
            });
          },
        },
      ]}
      additionalRenderChildren={
        <>
          {chargedEmployeeModal}
          {chargedEmployeeStatusChangeModalState ? (
            <ChargedEmployeeStatusChangeModal
              onSuccessfulSave={() => {
                filtersForm.submit();
              }}
              closeModal={() => {
                setChargedEmployeeStatusChangeModalState(null);
              }}
              initialValues={
                chargedEmployeeStatusChangeModalState.initialValues
              }
              chargedEmployeeIds={
                chargedEmployeeStatusChangeModalState.chargedEmployeeIds
              }
            />
          ) : null}
        </>
      }
    />
  );
}
