import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { PAGE_SIZE } from "@inspecto/common";
import { Button, Space } from "antd";
import { useMemo } from "react";
import styled from "styled-components";

interface Props {
  currentPageNumber: number;
  totalCount: number;
  onPageChange(newPageNumber: number): void;
  isLoading?: boolean;
}

const PaginationWrapper = styled(Space)``;

export function NextPreviousPagination({
  currentPageNumber,
  totalCount,
  onPageChange,
  ...props
}: Props) {
  const totalPagesCount = useMemo(() => {
    return Math.ceil(totalCount / PAGE_SIZE);
  }, [totalCount]);

  const hasPreviousPage = currentPageNumber !== 1;
  const hasNextPage = currentPageNumber !== totalPagesCount;

  if (totalPagesCount === 1) {
    return null;
  }

  return (
    <PaginationWrapper>
      <Button
        size="small"
        icon={<LeftOutlined />}
        onClick={() => onPageChange(currentPageNumber - 1)}
        disabled={!hasPreviousPage}
        loading={props.isLoading}
      />
      <Button
        size="small"
        icon={<RightOutlined />}
        onClick={() => onPageChange(currentPageNumber + 1)}
        disabled={!hasNextPage}
        loading={props.isLoading}
      />
    </PaginationWrapper>
  );
}
