import { Skeleton } from "antd";
import { ReactNode } from "react";

import { ListView, ListViewProps } from "../components";
import { CommonViewState } from "../contexts";
import { BackOfficeLayout, BackOfficeLayoutProps } from "./BackOfficeLayout";

export interface BackOfficeListViewLayoutProps<
  Item extends { id: string },
  ViewName extends keyof CommonViewState
> extends Omit<ListViewProps<Item, ViewName>, "children">,
    Pick<
      BackOfficeLayoutProps,
      | "pageTitle"
      | "breadcrumbs"
      | "headerDescription"
      | "createButtonAction"
      | "contentMinWidth"
    > {
  additionalRenderChildren?: ReactNode;
  filters?: JSX.Element;
  isLoading?: boolean;
}

/**
 * If you're using tabs make sure to only render this view *after* you've fetched all tabs.
 * Otherwise there will be issues with initial rendering
 */
export function BackOfficeListViewLayout<
  Item extends { id: string },
  ViewName extends keyof CommonViewState & string
>({
  pageTitle,
  breadcrumbs,
  createButtonAction,
  headerDescription,
  additionalRenderChildren,
  filters,
  contentMinWidth = 1200,
  ...listViewProps
}: BackOfficeListViewLayoutProps<Item, ViewName>) {
  return (
    <BackOfficeLayout
      pageTitle={pageTitle}
      contentMinWidth={contentMinWidth}
      breadcrumbs={breadcrumbs}
      headerDescription={headerDescription}
      createButtonAction={createButtonAction}
    >
      <BackOfficeLayout.Content>
        {listViewProps.isLoading ? (
          <Skeleton />
        ) : (
          <ListView {...listViewProps}>
            {({ listElement }) => (
              <>
                {filters && (
                  <BackOfficeLayout.Filters>{filters}</BackOfficeLayout.Filters>
                )}
                {listElement}
                {additionalRenderChildren}
              </>
            )}
          </ListView>
        )}
      </BackOfficeLayout.Content>
    </BackOfficeLayout>
  );
}
