import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useCompany } from "@inspecto/common";
import * as Sentry from "@sentry/react";
import { Modal } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
} from "react-router-dom";

import { useAuthentication } from "src/authentication";
import {
  ImmediateImageUploadersContextProvider,
  NoConnectionResultPage,
} from "src/components";
import { Spinner } from "src/components/Spinner";
import { useReporting } from "src/reporting/useReporting";
import { urls } from "src/urls";

import { protocolFillerApi } from "../api";
import { PageLayoutProgress } from "../components/PageLayout";
import { TemplatePage, TemplateSignaturePage, Template } from "../models";
import { arePageResponsesValid, isSignaturePageValid } from "../selectors";
import { PageView } from "../views/PageView";
import { ProtocolCreator } from "../views/ProtocolCreator";
import { SignaturePageView } from "../views/SignaturePageView";
import { useProtocolFillerContext } from "./ProtocolFillerContext";

interface ItemCommon {
  path: string;
  valid: boolean;
}

interface PageItem extends ItemCommon {
  type: "page";
  data: TemplatePage;
}

interface SignaturePageItem extends ItemCommon {
  type: "signature-page";
  data: TemplateSignaturePage;
}

type Item = PageItem | SignaturePageItem;

const summaryPagesCount = 1;
const SentryRoute = Sentry.withSentryRouting(Route);
export function ProtocolFillerWizard() {
  const [modal, modalContextHolder] = Modal.useModal();
  const { hasPerm } = useAuthentication();
  const { useExternalCameraApplicationInMobileApp } = useCompany();

  const { t } = useTranslation("protocolFiller");
  const { vehicleTypeId, vehicleId, templateId } = useParams<{
    vehicleTypeId: string;
    vehicleId: string;
    templateId: string;
  }>();
  const { pathname } = useLocation();
  const { logAnalyticsEvent } = useReporting();
  const {
    responses,
    signatures,
    hasBeenTouched,
    wasAtLeastOnePageVisited,
    setProtocolVehicleId,
  } = useProtocolFillerContext();

  const [template, setTemplate] = useState<Template | null>(null);
  const [templateLoadingState, setTemplateLoadingState] = useState<
    "loading" | "loaded" | "error"
  >("loading");

  useEffect(() => {
    if (template) {
      logAnalyticsEvent({ eventType: "startedFillingProtocol", payload: {} });
    }
  }, [logAnalyticsEvent, template]);

  useEffect(() => {
    setProtocolVehicleId(vehicleId);
  }, [setProtocolVehicleId, vehicleId]);

  const loadTemplate = useCallback(async () => {
    setTemplateLoadingState("loading");

    try {
      const template = await protocolFillerApi.getTemplateById(
        vehicleId,
        templateId
      );
      setTemplate(template);
      setTemplateLoadingState("loaded");
    } catch (e) {
      setTemplateLoadingState("error");
    }
  }, [vehicleId, templateId]);

  useEffect(() => {
    loadTemplate();
  }, [loadTemplate]);

  if (templateLoadingState === "error") {
    return <NoConnectionResultPage onRetryClick={loadTemplate} />;
  }
  if (templateLoadingState === "loading" || !template) {
    return <Spinner text={t("loading")} />;
  }

  let allPages: Item[] = template.pages.map((page) => ({
    type: "page",
    data: page,
    valid: arePageResponsesValid(page, responses),
    path: urls.protocolFiller.vehicleTypeAndVehicleAndTemplateAndPage(
      vehicleTypeId,
      vehicleId,
      templateId,
      page.id
    ),
  }));
  allPages = allPages.concat(
    template.signaturePages.map((signaturePage) => ({
      type: "signature-page",
      data: signaturePage,
      valid: isSignaturePageValid(signaturePage, signatures),
      path: urls.protocolFiller.vehicleTypeAndVehicleAndTemplateAndPage(
        vehicleTypeId,
        vehicleId,
        templateId,
        signaturePage.id
      ),
    }))
  );

  const currentPage = allPages.find((item) => item.path === pathname);
  const currentPageIndex = currentPage ? allPages.indexOf(currentPage) : -1;
  const isFilledOutCorrectly = allPages.every((item) => item.valid);
  let shouldProceedWithRendering = true;

  return (
    <>
      <Switch>
        {allPages.map((item, index) => {
          const isFirstPage = index === 0;
          const previousView = isFirstPage
            ? urls.protocolFiller.vehicleTypeAndVehicle(
                vehicleTypeId,
                vehicleId
              )
            : allPages[index - 1]?.path || "";
          const nextView =
            index < allPages.length - 1
              ? allPages[index + 1]?.path || ""
              : urls.protocolFiller.vehicleTypeAndVehicleAndTemplateSummary(
                  vehicleTypeId,
                  vehicleId,
                  templateId
                );
          const shouldThisPageBeValid = currentPageIndex > index;

          shouldProceedWithRendering = shouldProceedWithRendering
            ? shouldThisPageBeValid
              ? item.valid
              : true
            : false;

          if (!shouldProceedWithRendering) {
            return null;
          }

          const progress: PageLayoutProgress = {
            current: index + 1,
            total: allPages.length + summaryPagesCount,
            protocolName: template.label,
            pageName: "title" in item.data ? item.data.title : "",
          };
          const onBeforeLogoClick = hasBeenTouched
            ? displayAreYouSureYouWantToExit
            : undefined;
          const onBeforeBack =
            isFirstPage && hasBeenTouched
              ? displayAreYouSureYouWantToExit
              : undefined;

          return (
            <SentryRoute path={item.path} key={item.data.id}>
              {item.type === "page" ? (
                <ImmediateImageUploadersContextProvider
                  allowSelectingImageFromPhoneGallery={
                    template.allowAttachingPhotosFromGallery ||
                    hasPerm(
                      "protocol_filler.allow_attaching_photos_from_gallery_in_protocol_filler"
                    )
                  }
                  useExternalCameraApplicationInMobileApp={
                    useExternalCameraApplicationInMobileApp
                  }
                  compressionPreset={template.imageQuality}
                >
                  <PageView
                    page={item.data}
                    previousView={previousView}
                    nextView={nextView}
                    progress={progress}
                    onBeforeLogoClick={onBeforeLogoClick}
                    onBeforeBack={onBeforeBack}
                  />
                </ImmediateImageUploadersContextProvider>
              ) : (
                <SignaturePageView
                  signaturePage={item.data}
                  previousView={previousView}
                  nextView={nextView}
                  progress={progress}
                  onBeforeLogoClick={onBeforeLogoClick}
                  onBeforeBack={onBeforeBack}
                />
              )}
            </SentryRoute>
          );
        })}

        {isFilledOutCorrectly && (
          <SentryRoute
            path={urls.protocolFiller.vehicleTypeAndVehicleAndTemplateSummary()}
          >
            {wasAtLeastOnePageVisited ? (
              <ProtocolCreator />
            ) : (
              <Redirect to={urls.protocolFiller.main()} />
            )}
          </SentryRoute>
        )}

        <SentryRoute
          path={urls.protocolFiller.vehicleTypeAndVehicleAndTemplate()}
        >
          {allPages.length && allPages[0] ? (
            <Redirect to={allPages[0].path} />
          ) : (
            <Redirect to={urls.protocolFiller.main()} />
          )}
        </SentryRoute>
      </Switch>
      {modalContextHolder}
    </>
  );

  async function displayAreYouSureYouWantToExit() {
    return new Promise<void>((resolve, reject) => {
      modal.confirm({
        centered: true,
        title: t("exitProtocolConfirmationModal.areYouSureYouWantToExit"),
        content: t("exitProtocolConfirmationModal.youWillLooseAllUnsavedData"),
        icon: <ExclamationCircleOutlined />,
        okText: t("exitProtocolConfirmationModal.yes"),
        okType: "danger",
        cancelText: t("exitProtocolConfirmationModal.no"),
        onOk() {
          resolve();
        },
        onCancel() {
          reject();
        },
      });
    });
  }
}
