import {
  DownloadOutlined,
  ExportOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Button, Card, Dropdown, Empty, Skeleton, Space } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { useFetch } from "src/backOffice/hooks"; // TODO: Move useFetch to src/hooks
import { DocumentListItem } from "src/components/documents";
import { urls } from "src/urls";
import { downloadUri } from "src/utils";

import { protocolFillerApi } from "../../api";
import { VehicleDocumentsHeader } from "../../components";
import { PageLayout } from "../../components/PageLayout";
import { useVehicleFromParams } from "../../contexts";

function LoadingSkeleton() {
  return (
    <Card>
      <Skeleton />
    </Card>
  );
}

export function DocumentsView() {
  const { t } = useTranslation("translation");
  const { vehicleId } = useParams<{ vehicleId: string }>();
  const { vehicle } = useVehicleFromParams();
  const history = useHistory();
  const fetchDocuments = useCallback(
    () => protocolFillerApi.getVehicleDocuments(vehicleId),
    [vehicleId]
  );
  const [fetchedData] = useFetch(fetchDocuments);

  return (
    <PageLayout onBackClick={() => history.push(urls.documents.main())}>
      {!!vehicle ? (
        <>
          <VehicleDocumentsHeader
            onBackClick={() => history.push(urls.documents.main())}
            vehiclePlateNumber={vehicle.plateNumber}
          />
          {fetchedData.state === "dataFetched" ? (
            fetchedData.data.length ? (
              fetchedData.data.map((document) => (
                <DocumentListItem
                  vehicleDocument={document}
                  mode="mobile"
                  rightContent={
                    <Space size="small">
                      <Dropdown
                        menu={{
                          items: [
                            {
                              key: "open",
                              label: t("vehicleDocuments.actions.open"),
                              icon: <ExportOutlined />,
                              onClick: () => {
                                window.open(document.file.fileUrl, "_blank");
                              },
                            },
                            {
                              key: "download",
                              label: t("vehicleDocuments.actions.download"),
                              icon: <DownloadOutlined />,
                              onClick: () => {
                                downloadUri(
                                  document.file.fileUrl,
                                  `${vehicle?.plateNumber} - ${document.label}${document.file.extension}`
                                );
                              },
                            },
                          ],
                        }}
                      >
                        <Button
                          size="small"
                          icon={<MoreOutlined style={{ fontSize: 16 }} />}
                        />
                      </Dropdown>
                    </Space>
                  }
                />
              ))
            ) : (
              <Card bodyStyle={{ padding: 0 }}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </Card>
            )
          ) : (
            <LoadingSkeleton />
          )}
        </>
      ) : (
        <LoadingSkeleton />
      )}
    </PageLayout>
  );
}
