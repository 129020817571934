import { fetchAllPaginatedElements } from "@inspecto/common";
import { Form, Skeleton } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SearchableAsyncSelect } from "src/components";
import { fullNameReversed } from "src/utils";

import { backOfficeApi, ListVehicleByLinkTypeAndVehicle } from "../../../api";
import { ListEmployee } from "../../../models";
import { ListLinkType } from "../../../models/LinkType";

function getLinkTypeSide(
  vehicleType: string,
  linkType: ListLinkType
): "left" | "right" {
  return linkType.rightVehicleTypes.includes(vehicleType) ? "left" : "right";
}

export function LinkingVehicleFormItems({
  vehicleTypeId,
}: {
  vehicleTypeId: string;
}) {
  const { t } = useTranslation("backoffice");
  const [isLoading, setIsLoading] = useState(true);

  const [linkTypes, setLinkTypes] = useState<ListLinkType[]>([]);

  useEffect(() => {
    setIsLoading(true);
    fetchAllPaginatedElements((pageNumber) =>
      backOfficeApi.linkTypes.getList(pageNumber)
    )
      .then((linkTypes) => {
        setLinkTypes(linkTypes);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const getSearchVehiclesCallbackForLinkType = useCallback(
    (linkTypeId: string) => async (plateNumberQuery: string) => {
      return backOfficeApi.getVehiclesByLinkTypeAndVehicleType(
        linkTypeId,
        vehicleTypeId,
        plateNumberQuery
      );
    },
    [vehicleTypeId]
  );

  const getOptionsFromVehiclesList = useCallback(
    (vehicles: ListVehicleByLinkTypeAndVehicle[]) => {
      return vehicles.map((vehicle) => ({
        label: vehicle.plateNumber,
        value: vehicle.id,
      }));
    },
    []
  );

  const searchEmployees = useCallback(async (query, includeArchived) => {
    const employees = await backOfficeApi.getAllEmployees({
      tableParams: {
        pageNumber: 1,
      },
      searchTerm: query,
      searchIn: "name",
      status: "all",
      includeInactive: includeArchived,
      userRoles: [],
      emailFilter: "all",
    });
    return employees.results;
  }, []);

  const getOptionsFromListEmployees = useCallback(
    (employees: ListEmployee[]) =>
      employees.map((employee) => ({
        value: employee.id,
        label: fullNameReversed(employee),
      })),
    []
  );
  if (isLoading) {
    return <Skeleton />;
  }
  return (
    <>
      <Form.Item label={t("vehicleView.linkedUser")} name="assignedUser">
        <SearchableAsyncSelect
          searchElements={searchEmployees}
          getOptionsFromResponse={getOptionsFromListEmployees}
        />
      </Form.Item>
      {isLoading ? (
        <Skeleton />
      ) : (
        linkTypes.map((linkType) => {
          const side = getLinkTypeSide(vehicleTypeId, linkType);
          return (
            <Form.Item
              name={["linkTypeValues", linkType.id]}
              key={linkType.id}
              label={linkType[`${side}Name`]}
            >
              <SearchableAsyncSelect
                searchElements={getSearchVehiclesCallbackForLinkType(
                  linkType.id
                )}
                getOptionsFromResponse={getOptionsFromVehiclesList}
              />
            </Form.Item>
          );
        })
      )}
    </>
  );
}
