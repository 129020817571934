import { Currency, PaginationResults } from "@inspecto/common";
import dayjs from "dayjs";
import * as uuid from "uuid";

import { CustomVehicleFieldType } from "src/company";
import { FieldValue, BasicTableFormItemTableParams } from "src/components";
import { Api, api } from "src/utils";

import {
  RetrieveFilledOutProtocol,
  CreateUpdateCustomVehicleField,
  ListTemplate,
  SimpleVehicle,
  Statistics,
  Template,
  Damage,
  ListChargedEmployee,
  ChargedEmployeeStatus,
  VehicleType,
  SimpleVehicleGroup,
  VehicleGroup,
  ListProtocol,
  ListVehicle,
  CeleryTaskProgress,
  RetrieveVehicleCustomVehicleField,
  RetrieveCustomVehicleField,
  CustomVehicleFieldConditionOption,
  ListCustomVehicleField,
  RetrieveVehicleCustomVehicleFieldValueHistory,
  RetrieveVehicle,
  ListVehicleFieldsPreset,
  CreateUpdateVehicleFieldsPreset,
  RetrieveVehicleFieldsPreset,
  UpdateTemplate,
  TemplateCustomVehicleFields,
  ListRetrieveCustomVehicleFieldsGroup,
  CreateUpdateCustomVehicleFieldsGroup,
  ListRetrieveTemplateTag,
  CreateUpdateTemplateTag,
  TemplateTagsRequirementsByVehicleType,
  ListCompanyDepartment,
  CreateCompanyDepartment,
  UpdateCompanyDepartment,
  RetrieveCompanyDepartment,
  ListEmployee,
  RetrieveCreateUpdateEmployee,
  CreateUpdateVehicle,
  RetrieveDamageHistory,
  UpdateDamage,
  CreateDamage,
  LinkingHistoryResponse,
} from "../models";
import { DocumentsApi, documentsApi } from "./documentsApi";
import { FleetStateApi, fleetStateApi } from "./fleetStateApi";
import { LinkTypesApi, linkTypesApi } from "./linkTypesApi";
import { notificationsApi, NotificationsApi } from "./notificationsApi";
import { ReportsApi, reportsApi } from "./reportsApi";

export interface AssignUnassignPayload {
  idsToAssign: string[];
  idsToUnassign: string[];
}

export interface ChargedEmployeePayload {
  employee?: string;
  amount?: number;
  status?: ChargedEmployeeStatus;
  comment?: string;
  historicalCustomVehicleFieldValue?: string;
  response?: string;
  damage?: string;
  currency?: Currency;
}

export interface ChangeChargedEmployeeStatus {
  status: ChargedEmployeeStatus;
  id: string;
}

export type ChargedEmployeeFilters = {
  tableParams: BasicTableFormItemTableParams;
  employee?: string;
  vehiclePlateNumber?: string;
  statuses?: string[];
  dateAfter?: string;
  dateBefore?: string;
  hideZeroCharges?: boolean;
};

export interface DownloadPdfProtocolData {
  includeProtocolAuthor: boolean;
  footer: string | null;
}

export interface SendProtocolViaEmailData extends DownloadPdfProtocolData {
  recipientEmail: string;
  recipientFullName: string;
  emailSubject: string;
  emailMessage: string;
}

export interface ScheduleFreeTrainingData {
  phoneNumber: string;
}

export interface SendProtocolViaEmailResponse {
  pdfProtocolUrl: string;
  pdfProtocolUrlExpirationDate: string;
}

export type NumberOfQrCodesInRow = 5 | 4 | 2 | 1;

export interface GetTemplatesFilters {
  templateTags?: string[];
  excludeTemplateTags?: boolean;
  tableParams: BasicTableFormItemTableParams;
  includeArchived?: boolean;
}

export interface GetAllEmployeesFilters {
  tableParams: BasicTableFormItemTableParams;
  searchTerm: string;
  searchIn: "name" | "name_and_email";
  userRoles: string[];
  includeInactive: boolean;
  status: "all" | "is_activated" | "is_not_activated";
  companyDepartments?: string[];
  excludeCompanyDepartments?: boolean;
  emailFilter: "all" | "has_email" | "doesnt_have_email";
}

interface CommonVehicleFilters {
  tableParams: BasicTableFormItemTableParams;
  plateNumber?: string;
  includeArchived?: boolean;
  vehicleGroups?: string[];
  excludeVehicleGroups?: boolean;
  vehicleFieldsPreset?: string;
  excludeVehicleFieldsPreset?: boolean;
  companyDepartment?: string;
  excludeCompanyDepartment?: boolean;
  selectedVehicleType?: string;
}

export interface SimpleListVehicleFilters extends CommonVehicleFilters {}

export interface ListVehicleFilters extends CommonVehicleFilters {
  statuses?: string[];
}

export interface GetProtocolsFilters {
  tableParams: BasicTableFormItemTableParams;
  vehicleId?: string;
  employeeId?: string;
  dateAfter?: string;
  dateBefore?: string;
  protocolType?: string;
  statuses: string[];
  vehicleGroups: string[];
  excludeVehicleGroups?: boolean;
}

export interface GetDamagesFilters {
  tableParams: BasicTableFormItemTableParams;
  vehicle: string;
  customVehicleField: string;
  employee: string;
  statuses: Damage["status"][];
  vehicleTypes: string[];
  vehicleGroups: string[];
  excludeVehicleGroups?: boolean;
  companyDepartments: string[];
  excludeCompanyDepartments: boolean;
}

export type SimpleVehicleListFilters = Omit<
  ListVehicleFilters,
  "includeProtocolData"
>;

interface CompanyFooter {
  name: string;
  id: string;
}

interface BasicTableFormItemTableParamsWithPageSize
  extends BasicTableFormItemTableParams {
  pageSize?: number;
}

export interface GetCustomVehicleFieldsFilters {
  tableParams: BasicTableFormItemTableParamsWithPageSize;
  vehicleTypes?: VehicleType["id"][];
  customVehicleFieldsGroups?: string[];
  label?: string;
  type?: CustomVehicleFieldType;
  excludeTypes?: CustomVehicleFieldType[];
  vehicleGroups?: string[];
}

export interface GetCustomVehicleFieldsGroupsFilters {
  tableParams: BasicTableFormItemTableParams;
}

export interface GetTemplateTagsFilters {
  tableParams: BasicTableFormItemTableParams;
}

export interface ListVehicleByLinkTypeAndVehicle {
  id: string;
  plateNumber: string;
}

export class BackOfficeApi {
  constructor(
    private api: Api,
    public readonly fleetState: FleetStateApi,
    public readonly reports: ReportsApi,
    public readonly notifications: NotificationsApi,
    public readonly documents: DocumentsApi,
    public readonly linkTypes: LinkTypesApi
  ) {}

  private _getCommonQueryParamsForVehicles(
    filters: CommonVehicleFilters
  ): object {
    const vehicleGroupsFilterName = `vehicle_groups__${
      filters.excludeVehicleGroups ? "not_" : ""
    }in`;
    const vehicleFieldsPresetFilterName = `vehicle_fields_preset__${
      filters.excludeVehicleFieldsPreset ? "not_" : ""
    }in`;
    const companyDepartmentFilterName = `company_department__${
      filters.excludeCompanyDepartment ? "not_" : ""
    }in`;
    return {
      ...(filters.selectedVehicleType
        ? { vehicle_type: filters.selectedVehicleType }
        : {}),
      ...(filters.plateNumber
        ? {
            plate_number__icontains: filters.plateNumber,
          }
        : {}),
      [vehicleGroupsFilterName]: filters.vehicleGroups?.join(",") || [],
      [vehicleFieldsPresetFilterName]: filters.vehicleFieldsPreset,
      [companyDepartmentFilterName]: filters.companyDepartment,
      ...(filters.tableParams.sortByField
        ? { order_by: filters.tableParams.sortByField }
        : {}),
      ...(filters.includeArchived ? {} : { is_active: true }),
    };
  }

  private getQueryParamsForVehicles(filters: ListVehicleFilters): object {
    return {
      ...this._getCommonQueryParamsForVehicles(filters),
      statuses: filters?.statuses?.join(",") || undefined,
    };
  }

  private getQueryParamsForSimpleVehicles(
    filters: SimpleListVehicleFilters
  ): object {
    return this._getCommonQueryParamsForVehicles(filters);
  }

  async startDownloadVehiclesQrCodesTask(
    vehiclesView: SimpleVehicleListFilters,
    itemsInRow: NumberOfQrCodesInRow,
    includeCompanyLogo: boolean
  ): Promise<{ taskResultId: string }> {
    return await this.api.get<{ taskResultId: string }>(
      `/backoffice/vehicles/download-qr-codes/`,
      {
        ...this.getQueryParamsForVehicles({
          ...vehiclesView,
        }),
        itemsInRow,
        includeCompanyLogo,
      }
    );
  }

  async downloadVehiclesList(vehiclesView: SimpleVehicleListFilters) {
    await this.api.download(
      "/backoffice/vehicles/download/",
      this.getQueryParamsForVehicles(vehiclesView)
    );
  }

  async getAllVehicles(
    vehiclesView: ListVehicleFilters
  ): Promise<PaginationResults<ListVehicle[]>> {
    return await this.api.getPaginatedList<ListVehicle[]>(
      "/backoffice/vehicles/",
      {
        pageNumber: vehiclesView.tableParams.pageNumber,
      },
      this.getQueryParamsForVehicles({
        ...vehiclesView,
      })
    );
  }

  async getSimpleVehiclesList(
    filters: SimpleListVehicleFilters
  ): Promise<PaginationResults<SimpleVehicle[]>> {
    return await this.api.getPaginatedList<SimpleVehicle[]>(
      "/backoffice/simple-vehicles/",
      { pageNumber: filters.tableParams.pageNumber },
      this.getQueryParamsForSimpleVehicles({
        ...filters,
      })
    );
  }

  async getVehicle(vehicleId: string): Promise<RetrieveVehicle> {
    return await this.api.get<RetrieveVehicle>(
      `/backoffice/vehicles/${vehicleId}/`
    );
  }

  async getVehicleLinkingHistory(
    vehicleId: string
  ): Promise<LinkingHistoryResponse> {
    return this.api.get<LinkingHistoryResponse>(
      `/backoffice/vehicles/${vehicleId}/linking-history/`
    );
  }

  async getVehicleCustomVehicleFields(
    vehicleId: string
  ): Promise<RetrieveVehicleCustomVehicleField[]> {
    return this.api.get<RetrieveVehicleCustomVehicleField[]>(
      `/backoffice/vehicles/${vehicleId}/custom-fields/`
    );
  }

  async getVehicleCustomVehicleFieldHistory(
    vehicleId: string,
    customVehicleFieldId: string,
    tableParams: BasicTableFormItemTableParams
  ): Promise<
    PaginationResults<RetrieveVehicleCustomVehicleFieldValueHistory[]>
  > {
    return this.api.getPaginatedList<
      RetrieveVehicleCustomVehicleFieldValueHistory[]
    >(
      `/backoffice/vehicles/${vehicleId}/custom-fields/${customVehicleFieldId}/history/`,
      tableParams
    );
  }

  async getVehicleCustomVehicleField(
    vehicleId: string,
    customVehicleFieldId: string
  ): Promise<RetrieveVehicleCustomVehicleField> {
    return this.api.get<RetrieveVehicleCustomVehicleField>(
      `/backoffice/vehicles/${vehicleId}/custom-fields/${customVehicleFieldId}/`
    );
  }

  async updateVehicleCustomFields(
    vehicleId: string,
    fieldValues: Record<
      string,
      {
        value: FieldValue;
      }
    >
  ) {
    return this.api.post(
      `/backoffice/vehicles/${vehicleId}/custom-fields/`,
      fieldValues
    );
  }

  async getProtocolTypes() {
    return await this.api.get<string[]>(
      "/backoffice/protocols/all-protocol-types/"
    );
  }
  async getProtocols(
    filters: GetProtocolsFilters
  ): Promise<PaginationResults<ListProtocol[]>> {
    const vehicleGroupsFilterName = `vehicle_groups__${
      filters.excludeVehicleGroups ? "not_" : ""
    }in`;
    return await this.api.getPaginatedList<ListProtocol[]>(
      "/backoffice/protocols/",
      filters.tableParams,
      {
        [vehicleGroupsFilterName]: filters.vehicleGroups?.join(",") || [],
        created_by: filters.employeeId,
        vehicle: filters.vehicleId,
        ...(filters.statuses ? { status__in: filters.statuses.join(",") } : {}),
        ...(filters.dateAfter ? { date_after: filters.dateAfter } : {}),
        ...(filters.dateBefore ? { date_before: filters.dateBefore } : {}),
        ...(filters.protocolType ? { label: filters.protocolType } : {}),
      }
    );
  }

  async getProtocol(protocolId: string): Promise<RetrieveFilledOutProtocol> {
    return await this.api.get<RetrieveFilledOutProtocol>(
      `/backoffice/protocols/${protocolId}/`
    );
  }

  async downloadProtocolPdf(
    protocolId: string,
    payload: DownloadPdfProtocolData
  ): Promise<void> {
    const timezoneName = dayjs.tz.guess();
    await this.api.download(
      `/backoffice/protocols/${protocolId}/download-pdf/`,
      {
        timezoneName,
        ...payload,
      }
    );
  }

  async sendProtocolViaEmail(
    protocolId: string,
    { includeProtocolAuthor, footer, ...payload }: SendProtocolViaEmailData
  ): Promise<SendProtocolViaEmailResponse> {
    return await this.api.post<SendProtocolViaEmailResponse>(
      `/backoffice/protocols/${protocolId}/send-via-email/`,
      payload,
      { includeProtocolAuthor, footer }
    );
  }

  async scheduleFreeTraining(data: ScheduleFreeTrainingData): Promise<void> {
    return await this.api.post(`/backoffice/schedule-free-training/`, data);
  }

  async createVehicle(
    vehicleData: CreateUpdateVehicle
  ): Promise<CreateUpdateVehicle> {
    return await this.api.post(`/backoffice/vehicles/`, vehicleData);
  }

  async updateVehicle(
    vehicleId: string,
    vehicleData: Partial<CreateUpdateVehicle>
  ): Promise<CreateUpdateVehicle> {
    return await this.api.patch(`/backoffice/vehicles/${vehicleId}/`, {
      ...vehicleData,
      assignedUser: vehicleData.assignedUser?.value || null,
      linkTypeValues: vehicleData.linkTypeValues
        ? Object.entries(vehicleData.linkTypeValues).reduce(
            (acc, [linkTypeId, linkTypeOption]) => ({
              ...acc,
              [linkTypeId]: linkTypeOption?.value || null,
            }),
            {}
          )
        : undefined,
    });
  }

  getEmployeesApiFilter(filters: GetAllEmployeesFilters) {
    const companyDepartmentFilterName = `company_departments__${
      filters.excludeCompanyDepartments ? "not_" : ""
    }in`;
    return {
      [filters.searchIn]: filters.searchTerm,
      ...(!filters.userRoles.length
        ? {}
        : { user_role__in: filters.userRoles.join(",") }),
      ...(filters.includeInactive ? {} : { is_active: true }),
      ...(filters.companyDepartments?.length
        ? {
            [companyDepartmentFilterName]: filters.companyDepartments.join(","),
          }
        : {}),
      ...(filters.status === "all"
        ? {}
        : filters.status === "is_activated"
        ? { is_activated: true }
        : { is_activated: false }),
      ...(filters.emailFilter === "all"
        ? {}
        : { has_email: filters.emailFilter === "has_email" }),
    };
  }

  async getAllEmployees(
    filters: GetAllEmployeesFilters
  ): Promise<PaginationResults<ListEmployee[]>> {
    return await this.api.getPaginatedList<ListEmployee[]>(
      `/backoffice/employees/`,
      filters.tableParams,
      this.getEmployeesApiFilter(filters)
    );
  }

  async downloadEmployeesList(filters: GetAllEmployeesFilters) {
    await this.api.download(
      "/backoffice/employees/download/",
      this.getEmployeesApiFilter(filters)
    );
  }

  async getEmployee(employeeId: string) {
    return await this.api.get<RetrieveCreateUpdateEmployee>(
      `/backoffice/employees/${employeeId}/`
    );
  }

  async updateEmployee(
    employeeId: string,
    backendEmployee: Partial<RetrieveCreateUpdateEmployee>
  ): Promise<void> {
    await this.api.patch<RetrieveCreateUpdateEmployee>(
      `/backoffice/employees/${employeeId}/`,
      {
        ...backendEmployee,
        phoneNumber: backendEmployee.phoneNumber || null,
      }
    );
  }

  async createEmployee(
    backendEmployee: Omit<RetrieveCreateUpdateEmployee, "id">
  ): Promise<RetrieveCreateUpdateEmployee> {
    return await this.api.post<RetrieveCreateUpdateEmployee>(
      `/backoffice/employees/`,
      {
        ...backendEmployee,
        phoneNumber: backendEmployee.phoneNumber || null,
      }
    );
  }

  async removeEmployee(employeeId: string) {
    return await this.api.delete(`/backoffice/employees/${employeeId}/`);
  }

  async resendEmployeeActivationEmail(employeeId: string) {
    return await this.api.post(
      `/backoffice/employees/${employeeId}/resend-activation-email/`,
      {}
    );
  }

  async removeVehicle(vehicleId: string) {
    return await this.api.delete(`/backoffice/vehicles/${vehicleId}/`);
  }

  async toggleResponse(protocolId: string, responseId: string) {
    return await this.api.post(
      `/backoffice/protocols/${protocolId}/toggle-response/`,
      { responseId }
    );
  }
  async applyResponseValue(protocolId: string, responseId: string) {
    return await this.api.post(
      `/backoffice/protocols/${protocolId}/apply-response-value/`,
      { responseId }
    );
  }

  async getTemplatesCustomVehicleFields(pageNumber: number) {
    return await this.api.getPaginatedList<TemplateCustomVehicleFields[]>(
      "/backoffice/templates-custom-vehicle-fields/",
      {
        pageNumber,
      }
    );
  }

  async getTemplates(filters: GetTemplatesFilters) {
    const templateTagsFilterName = `template_tags__${
      filters.excludeTemplateTags ? "not_" : ""
    }in`;

    return await this.api.getPaginatedList<ListTemplate[]>(
      "/backoffice/templates/",
      {
        pageNumber: filters.tableParams.pageNumber,
      },
      {
        ...(filters.includeArchived ? {} : { is_active: true }),
        [templateTagsFilterName]: filters.templateTags?.join(",") || [],
      }
    );
  }

  async getTemplate(templateId: string): Promise<Template> {
    const template = await this.api.get<Template>(
      `/backoffice/templates/${templateId}/`
    );

    return {
      ...template,
      signaturePages: template.signaturePages.map((signaturePage) => ({
        ...signaturePage,
        frontendId: uuid.v4(),
      })),
      pages: template.pages.map((page) => ({
        ...page,
        frontendId: uuid.v4(),
        questions: page.questions.map((question) => {
          return {
            ...question,
            frontendId: uuid.v4(),
            ...("choices" in question && question.choices
              ? {
                  choices: question.choices.map((choice) => ({
                    ...choice,
                    frontendId: uuid.v4(),
                  })),
                }
              : {}),
          };
        }),
      })),
    };
  }

  async updateTemplate(templateId: string, data: UpdateTemplate) {
    return await this.api.patch<UpdateTemplate>(
      `/backoffice/templates/${templateId}/`,
      data
    );
  }

  async saveTemplate(template: Template): Promise<{ templateId: string }> {
    return await this.api.post(`/backoffice/templates/save/`, template);
  }

  async removeTemplate(templateId: string) {
    return await this.api.delete(`/backoffice/templates/${templateId}/`);
  }

  async reorderTemplates(templateIds: string[]) {
    return await this.api.post("/backoffice/templates/reorder/", {
      templateIds,
    });
  }

  async getStatistics() {
    return await this.api.get<Statistics>("/backoffice/statistics/");
  }

  async getCompanyDepartments(
    params: BasicTableFormItemTableParams
  ): Promise<PaginationResults<ListCompanyDepartment[]>> {
    return await this.api.getPaginatedList(
      `/backoffice/company-departments/`,
      params
    );
  }

  async getCompanyDepartment(id: string): Promise<RetrieveCompanyDepartment> {
    return await this.api.get(`/backoffice/company-departments/${id}/`);
  }

  async createCompanyDepartment(
    companyDepartmentData: CreateCompanyDepartment
  ) {
    return await this.api.post<{ id: string }>(
      `/backoffice/company-departments/`,
      companyDepartmentData
    );
  }

  async updateCompanyDepartment(
    companyDepartmentData: UpdateCompanyDepartment
  ) {
    return await this.api.put<void>(
      `/backoffice/company-departments/${companyDepartmentData.id}/`,
      companyDepartmentData
    );
  }

  async removeCompanyDepartment(id: string) {
    return await this.api.delete(`/backoffice/company-departments/${id}/`);
  }

  async assignCompanyDepartmentToVehicles(
    companyDepartmentId: string,
    payload: AssignUnassignPayload
  ) {
    return await this.api.post<void>(
      `/backoffice/company-departments/${companyDepartmentId}/assign-to-vehicles/`,
      payload
    );
  }

  async assignCompanyDepartmentToUsers(
    companyDepartmentId: string,
    payload: AssignUnassignPayload
  ) {
    return await this.api.post<void>(
      `/backoffice/company-departments/${companyDepartmentId}/assign-to-users/`,
      payload
    );
  }

  async getVehicleGroups(pageNumber: number = 1) {
    return await this.api.getPaginatedList<SimpleVehicleGroup[]>(
      "/backoffice/vehicle-groups/",
      { pageNumber }
    );
  }

  async getCustomVehicleFieldsGroups(
    filters: GetCustomVehicleFieldsGroupsFilters = {
      tableParams: {
        pageNumber: 1,
        sortByField: "",
      },
    }
  ) {
    return await this.api.getPaginatedList<
      ListRetrieveCustomVehicleFieldsGroup[]
    >("/backoffice/custom-vehicle-fields-groups/", filters.tableParams);
  }

  async getCustomVehicleFieldsGroup(customVehicleFieldsGroupId: string) {
    return await this.api.get<ListRetrieveCustomVehicleFieldsGroup>(
      `/backoffice/custom-vehicle-fields-groups/${customVehicleFieldsGroupId}/`
    );
  }

  async updateCustomVehicleFieldsGroup(
    customVehicleFieldsGroupId: string,
    customVehicleFieldsGroupData: CreateUpdateCustomVehicleFieldsGroup
  ) {
    return await this.api.put<void>(
      `/backoffice/custom-vehicle-fields-groups/${customVehicleFieldsGroupId}/`,
      customVehicleFieldsGroupData
    );
  }

  async createCustomVehicleFieldsGroup(
    customVehicleFieldsGroupData: CreateUpdateCustomVehicleFieldsGroup
  ) {
    return await this.api.post<{ id: string }>(
      `/backoffice/custom-vehicle-fields-groups/`,
      customVehicleFieldsGroupData
    );
  }

  async removeCustomVehicleFieldsGroup(customVehicleFieldsGroupId: string) {
    return await this.api.delete(
      `/backoffice/custom-vehicle-fields-groups/${customVehicleFieldsGroupId}/`
    );
  }

  async assignCustomVehicleFieldsGroupToCustomVehicleFields(
    customVehicleFieldsGroupId: string,
    payload: AssignUnassignPayload
  ) {
    return await this.api.post<void>(
      `/backoffice/custom-vehicle-fields-groups/${customVehicleFieldsGroupId}/assign-to-custom-vehicle-fields/`,
      payload
    );
  }

  async getTemplateTags(
    filters: GetTemplateTagsFilters = {
      tableParams: {
        pageNumber: 1,
        sortByField: "",
      },
    }
  ) {
    return await this.api.getPaginatedList<ListRetrieveTemplateTag[]>(
      "/backoffice/template-tags/",
      filters.tableParams
    );
  }

  async getTemplateTagsRequirementsByVehicleType() {
    return await this.api.get<TemplateTagsRequirementsByVehicleType>(
      "/backoffice/template-tags/template-tags-requirements-by-vehicle-type/"
    );
  }

  async getTemplateTag(templateTagId: string) {
    return await this.api.get<ListRetrieveTemplateTag>(
      `/backoffice/template-tags/${templateTagId}/`
    );
  }

  async updateTemplateTag(
    templateTagId: string,
    templateTagData: CreateUpdateTemplateTag
  ) {
    return await this.api.put<void>(
      `/backoffice/template-tags/${templateTagId}/`,
      templateTagData
    );
  }

  async createTemplateTag(templateTagData: CreateUpdateTemplateTag) {
    return await this.api.post<{ id: string }>(
      `/backoffice/template-tags/`,
      templateTagData
    );
  }

  async removeTemplateTag(templateTagId: string) {
    return await this.api.delete(`/backoffice/template-tags/${templateTagId}/`);
  }

  async assignTemplateTagToTemplates(
    templateTagId: string,
    payload: AssignUnassignPayload
  ) {
    return await this.api.post<void>(
      `/backoffice/template-tags/${templateTagId}/assign-to-templates/`,
      payload
    );
  }

  async reorderTemplateTags(templateTagIds: string[]) {
    return await this.api.post("/backoffice/template-tags/reorder/", {
      templateTagIds,
    });
  }

  async getVehicleFieldsPreset(
    id: string
  ): Promise<RetrieveVehicleFieldsPreset> {
    return await this.api.get(`/backoffice/vehicle-fields-presets/${id}/`);
  }

  async getVehicleFieldsPresets(
    pageNumber: number = 1
  ): Promise<PaginationResults<ListVehicleFieldsPreset[]>> {
    return await this.api.getPaginatedList(
      `/backoffice/vehicle-fields-presets/`,
      {
        pageNumber,
      }
    );
  }

  async removeVehicleFieldsPreset(vehicleFieldsPresetId: string) {
    return await this.api.delete(
      `/backoffice/vehicle-fields-presets/${vehicleFieldsPresetId}/`
    );
  }

  async createVehicleFieldsPreset(data: CreateUpdateVehicleFieldsPreset) {
    return await this.api.post<{ id: string }>(
      `/backoffice/vehicle-fields-presets/`,
      data
    );
  }

  async updateVehicleFieldsPreset(
    id: string,
    data: CreateUpdateVehicleFieldsPreset
  ): Promise<CreateUpdateVehicleFieldsPreset & { id: string }> {
    return await this.api.put<CreateUpdateVehicleFieldsPreset & { id: string }>(
      `/backoffice/vehicle-fields-presets/${id}/`,
      data
    );
  }

  async assignVehiclePresetToVehicles(
    presetId: string,
    payload: AssignUnassignPayload
  ) {
    return await this.api.post<void>(
      `/backoffice/vehicle-fields-presets/${presetId}/assign-to-vehicles/`,
      payload
    );
  }

  async getVehicleTypes() {
    return await this.api.get<VehicleType[]>("/backoffice/vehicle-types/");
  }

  async getVehicleGroup(vehicleGroupId: string) {
    return await this.api.get<VehicleGroup>(
      `/backoffice/vehicle-groups/${vehicleGroupId}/`
    );
  }

  async updateVehicleGroup(
    vehicleGroupId: string,
    vehicleGroupData: { label: string }
  ) {
    return await this.api.put<VehicleGroup>(
      `/backoffice/vehicle-groups/${vehicleGroupId}/`,
      vehicleGroupData
    );
  }

  async createVehicleGroup(vehicleGroupData: { label: string }) {
    return await this.api.post<VehicleGroup>(
      `/backoffice/vehicle-groups/`,
      vehicleGroupData
    );
  }

  async removeVehicleGroup(vehicleGroupId: string) {
    return await this.api.delete(
      `/backoffice/vehicle-groups/${vehicleGroupId}/`
    );
  }

  async assignVehicleGroupToVehicles(
    vehicleGroupId: string,
    payload: AssignUnassignPayload
  ) {
    return await this.api.post<void>(
      `/backoffice/vehicle-groups/${vehicleGroupId}/assign-to-vehicles/`,
      payload
    );
  }

  async getCustomVehicleFields<TReturnType = ListCustomVehicleField>(
    filters: GetCustomVehicleFieldsFilters
  ) {
    return await this.api.getPaginatedList<TReturnType[]>(
      `/backoffice/custom-vehicle-fields/`,
      {
        pageNumber: filters.tableParams.pageNumber,
        pageSize: filters.tableParams.pageSize,
      },
      {
        ...(filters.vehicleTypes
          ? { vehicle_types__in: filters.vehicleTypes.join(",") }
          : {}),
        vehicle_groups__in: filters.vehicleGroups?.join(",") || [],
        ...(filters.customVehicleFieldsGroups
          ? {
              custom_vehicle_fields_group__in:
                filters.customVehicleFieldsGroups.join(","),
            }
          : {}),
        ...(filters.type ? { type: filters.type } : {}),
        ...(filters.label ? { label: filters.label } : {}),
        ...(filters.excludeTypes
          ? { exclude_types: filters.excludeTypes }
          : {}),
        ...(filters.tableParams.sortByField
          ? { order_by: filters.tableParams.sortByField }
          : {}),
      }
    );
  }

  async getCustomVehicleField(
    customVehicleFieldId: string
  ): Promise<RetrieveCustomVehicleField> {
    return await this.api.get<RetrieveCustomVehicleField>(
      `/backoffice/custom-vehicle-fields/${customVehicleFieldId}/`
    );
  }

  async getCustomVehicleFieldConditionOptions(): Promise<
    CustomVehicleFieldConditionOption[]
  > {
    const options = await this.api.get<CustomVehicleFieldConditionOption[]>(
      "/backoffice/custom-vehicle-fields/condition-options/"
    );

    const alwaysOkCondition: CustomVehicleFieldConditionOption = {
      allowedFieldTypes: ["text", "number", "date", "date-month"],
      arguments: [],
      name: "alwaysOk",
      value: null,
    };
    return [alwaysOkCondition].concat(...options);
  }
  async updateCustomVehicleField(
    customVehicleFieldId: string,
    customVehicleFieldData: Omit<CreateUpdateCustomVehicleField, "id">
  ) {
    return await this.api.put<CreateUpdateCustomVehicleField>(
      `/backoffice/custom-vehicle-fields/${customVehicleFieldId}/`,
      customVehicleFieldData
    );
  }

  async reorderCustomVehicleFields(fieldIds: string[]) {
    return await this.api.post("/backoffice/custom-vehicle-fields/reorder/", {
      fieldIds,
    });
  }

  async reorderCustomVehicleFieldsGroups(
    customVehicleFieldsGroupIds: string[]
  ) {
    return await this.api.post(
      "/backoffice/custom-vehicle-fields-groups/reorder/",
      {
        customVehicleFieldsGroupIds,
      }
    );
  }

  async createCustomVehicleField(
    customVehicleFieldData: Omit<CreateUpdateCustomVehicleField, "id">
  ) {
    return await this.api.post<RetrieveCustomVehicleField>(
      `/backoffice/custom-vehicle-fields/`,
      customVehicleFieldData
    );
  }

  async removeCustomVehicleField(customVehicleFieldId: string) {
    return await this.api.delete(
      `/backoffice/custom-vehicle-fields/${customVehicleFieldId}/`
    );
  }

  async addResponseComment(
    responseId: string,
    comment: {
      text: string;
    }
  ): Promise<unknown> {
    return await this.api.post<unknown>(
      `/backoffice/responses/${responseId}/add-comment/`,
      comment
    );
  }

  async editSignature(
    signatureId: string,
    payload: {
      signature?: string | null;
      signer?: string | null;
    }
  ): Promise<unknown> {
    return await this.api.patch<unknown>(
      `/backoffice/signatures/${signatureId}/`,
      payload
    );
  }

  async editResponseComment(
    commentId: string,
    comment: {
      text: string;
    }
  ): Promise<unknown> {
    return await this.api.patch<unknown>(
      `/backoffice/response-comments/${commentId}/`,
      comment
    );
  }

  async removeResponseComment(commentId: string): Promise<unknown> {
    return await this.api.delete<unknown>(
      `/backoffice/response-comments/${commentId}/`
    );
  }

  async chargeEmployee(data: ChargedEmployeePayload): Promise<unknown> {
    return await this.api.post<unknown>(`/backoffice/charged-employees/`, data);
  }

  private getQueryParamsForChargedEmployees(
    filters: ChargedEmployeeFilters
  ): object {
    const {
      employee,
      statuses,
      dateAfter,
      dateBefore,
      hideZeroCharges,
      vehiclePlateNumber,
    } = filters;

    return {
      ...(vehiclePlateNumber
        ? { vehicle_plate_number: vehiclePlateNumber }
        : {}),
      ...(employee ? { employee: employee } : {}),
      ...(statuses && statuses.length > 0
        ? { status__in: statuses.join(",") }
        : {}),
      ...(dateAfter ? { date_after: dateAfter } : {}),
      ...(dateBefore ? { date_before: dateBefore } : {}),
      ...(hideZeroCharges ? { hide_zero_charges: true } : {}),
      ...(filters.tableParams.sortByField
        ? { order_by: filters.tableParams.sortByField }
        : {}),
    };
  }

  async getChargedEmployees(
    filters: ChargedEmployeeFilters
  ): Promise<PaginationResults<ListChargedEmployee[]>> {
    return await this.api.getPaginatedList<ListChargedEmployee[]>(
      `/backoffice/charged-employees/`,
      { pageNumber: filters.tableParams.pageNumber },
      this.getQueryParamsForChargedEmployees(filters)
    );
  }

  async getChargedEmployeesTotalAmount(
    filters: ChargedEmployeeFilters
  ): Promise<{
    amountsByCurrency: Partial<Record<Currency, number>>;
  }> {
    return await this.api.get(
      `/backoffice/charged-employees/total-amount/`,
      this.getQueryParamsForChargedEmployees(filters)
    );
  }

  async downloadChargedEmployeesList(filters: ChargedEmployeeFilters) {
    await this.api.download(
      `/backoffice/charged-employees/download/`,
      this.getQueryParamsForChargedEmployees(filters)
    );
  }

  async editChargedEmployee(
    chargedEmployeeId: string,
    data: ChargedEmployeePayload
  ): Promise<unknown> {
    return await this.api.patch<unknown>(
      `/backoffice/charged-employees/${chargedEmployeeId}/`,
      data
    );
  }

  async changeChargedEmployeeStatuses(
    data: ChangeChargedEmployeeStatus[]
  ): Promise<unknown> {
    return await this.api.post<unknown>(
      `/backoffice/charged-employees/change-statuses/`,
      data
    );
  }

  async getDamage(damageId: string): Promise<Damage> {
    return await this.api.get<Damage>(`/backoffice/damages/${damageId}/`);
  }

  async getDamages(damageFilter: GetDamagesFilters) {
    const {
      statuses,
      employee,
      vehicle,
      customVehicleField,
      tableParams,
      vehicleTypes,
      vehicleGroups,
      excludeVehicleGroups,
      companyDepartments,
      excludeCompanyDepartments,
    } = damageFilter;
    const vehicleGroupsFilterName = `vehicle_groups__${
      excludeVehicleGroups ? "not_" : ""
    }in`;
    return await this.api.getPaginatedList<Damage[]>(
      "/backoffice/damages/",
      {
        pageNumber: tableParams.pageNumber,
      },
      {
        ...(vehicle ? { custom_vehicle_field_value__vehicle: vehicle } : {}),
        ...(customVehicleField
          ? { custom_vehicle_field_value__field: customVehicleField }
          : {}),
        ...(employee ? { charged_employee__employee: employee } : {}),
        ...(statuses.length > 0 ? { status__in: statuses.join(",") } : {}),
        ...(tableParams.sortByField
          ? { order_by: tableParams.sortByField }
          : {}),
        ...(vehicleTypes.length > 0
          ? {
              custom_vehicle_field_value__vehicle__vehicle_type_id__in:
                vehicleTypes.join(","),
            }
          : {}),
        ...(companyDepartments
          ? {
              [`vehicle_departments__${
                excludeCompanyDepartments ? "not_" : ""
              }in`]: companyDepartments.join(","),
            }
          : {}),
        [vehicleGroupsFilterName]: vehicleGroups?.join(",") || [],
      }
    );
  }

  async updateDamage(
    damageId: string,
    damageInformation: Partial<UpdateDamage>
  ): Promise<Damage> {
    return await this.api.patch(
      `/backoffice/damages/${damageId}/`,
      damageInformation
    );
  }

  async createDamage(newDamage: CreateDamage): Promise<Damage> {
    return await this.api.post(`/backoffice/damages/`, newDamage);
  }

  async getDamageHistory(
    damageId: string,
    tableParams: BasicTableFormItemTableParams
  ): Promise<PaginationResults<RetrieveDamageHistory[]>> {
    return this.api.getPaginatedList<RetrieveDamageHistory[]>(
      `/backoffice/damages/${damageId}/history/`,
      tableParams
    );
  }

  async getCeleryTaskProgress<TSuccessResult>(
    taskId: string
  ): Promise<CeleryTaskProgress<TSuccessResult>> {
    return await this.api.get<CeleryTaskProgress<TSuccessResult>>(
      `/celery-progress/${taskId}/`
    );
  }

  async getAllCompanyFooters() {
    return await this.api.get<CompanyFooter[]>("/backoffice/company-footers/");
  }

  async getUserRoleOptions(): Promise<{ label: string; value: string }[]> {
    const userRoles = await this.api.get<{ id: string; name: string }[]>(
      "/backoffice/user-roles/"
    );

    return userRoles.map((el) => ({
      label: el.name,
      value: el.id,
    }));
  }

  async getVehiclesByLinkTypeAndVehicleType(
    linkTypeId: string,
    parentVehicleTypeId: string,
    plateNumberQuery: string
  ) {
    return await this.api.get<ListVehicleByLinkTypeAndVehicle[]>(
      `/backoffice/vehicles-by-link-type-and-vehicle-type/${linkTypeId}/${parentVehicleTypeId}/`,
      {
        plate_number: plateNumberQuery,
      }
    );
  }
}

export const backOfficeApi = new BackOfficeApi(
  api,
  fleetStateApi,
  reportsApi,
  notificationsApi,
  documentsApi,
  linkTypesApi
);
