import * as Sentry from "@sentry/react";
import { Route, Switch } from "react-router-dom";

import { urls } from "src/urls";

import {
  FleetStateCustomVehicleFieldsListView,
  FleetStateCustomVehicleFieldValuesListView,
} from "./";

const SentryRoute = Sentry.withSentryRouting(Route);
export function FleetStateRouter() {
  return (
    <Switch>
      <SentryRoute
        path={urls.backOffice.fleetState.customVehicleFields()}
        exact
      >
        <FleetStateCustomVehicleFieldsListView />
      </SentryRoute>
      <SentryRoute
        path={urls.backOffice.fleetState.customVehicleFieldsValues()}
        exact
      >
        <FleetStateCustomVehicleFieldValuesListView />
      </SentryRoute>
    </Switch>
  );
}
