import { allReports, ReportType } from "@inspecto/common";
import * as Sentry from "@sentry/react";
import { FunctionComponent } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { urls } from "src/urls";

import {
  NumberOfNotOkVehicleFieldsReportView,
  NumberOfOkAndNotOkProtocolsReportView,
  ProtocolsCreationLocationReportView,
  NumberOfProtocolsPerUserReportView,
  ListOfReportedDeficienciesReportView,
  NumberOfProtocolsPerVehicleReportView,
  VehicleStateByDateReportView,
} from "./";
import { ReportsListView } from "./ReportsListView";

const SentryRoute = Sentry.withSentryRouting(Route);
export function ReportsRouter() {
  const componentByReportType: Record<ReportType, FunctionComponent> = {
    numberOfProtocolsPerUser: NumberOfProtocolsPerUserReportView,
    numberOfProtocolsPerVehicle: NumberOfProtocolsPerVehicleReportView,
    numberOfOkAndNotOkProtocols: NumberOfOkAndNotOkProtocolsReportView,
    numberOfNotOkVehicleFields: NumberOfNotOkVehicleFieldsReportView,
    protocolsCreationLocation: ProtocolsCreationLocationReportView,
    listOfReportedDeficiencies: ListOfReportedDeficienciesReportView,
    vehicleStatesByDate: VehicleStateByDateReportView,
  };

  return (
    <Switch>
      <SentryRoute path={urls.backOffice.reports.main()} exact>
        <ReportsListView />
      </SentryRoute>
      {allReports.map((reportType) => {
        const Component = componentByReportType[reportType];
        return (
          <SentryRoute
            path={urls.backOffice.reports.reportView(reportType)}
            key={reportType}
          >
            <Component />
          </SentryRoute>
        );
      })}
      <SentryRoute path="*">
        <Redirect to={urls.backOffice.reports.main()} />
      </SentryRoute>
    </Switch>
  );
}
