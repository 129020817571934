import { Dayjs } from "dayjs";
import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useReducer,
} from "react";

import { BasicTableFormItemTableParams } from "src/components";

import {
  GetAllEmployeesFilters,
  GetCustomVehicleFieldsFilters,
  GetCustomVehicleFieldsGroupsFilters,
  GetProtocolsFilters,
  GetTemplatesFilters,
  GetTemplateTagsFilters,
  ListVehicleFilters,
  SimpleVehicleListFilters,
} from "../api";
import {
  ChargedEmployeeStatus,
  Damage,
  FleetStateCustomVehicleFieldValue,
} from "../models";

export interface Option {
  value: string;
  label: string;
}

export type EmployeeInferredStatus = "activated" | "not_activated";

export interface EmployeesViewState {
  tableParams: BasicTableFormItemTableParams;
  searchQuery: string;
  includeInactive: boolean;
  statuses: EmployeeInferredStatus[];
  userRole: string | null;
  companyDepartments: string[];
  excludeCompanyDepartments: boolean;
}
export type VehiclesViewState = Pick<
  ListVehicleFilters,
  | "tableParams"
  | "plateNumber"
  | "includeArchived"
  | "vehicleGroups"
  | "statuses"
  | "selectedVehicleType"
  | "companyDepartment"
  | "excludeCompanyDepartment"
>;

export interface EmployeeChargesViewState {
  tableParams: BasicTableFormItemTableParams;
  selectedEmployee: Option | null;
  statusFilters: ChargedEmployeeStatus[];
  datesFilter: [Dayjs | null, Dayjs | null] | null;
  hideZeroCharges: boolean;
  selectedVehicle: Option | null;
}

export interface ProtocolsViewState
  extends Omit<
    GetProtocolsFilters,
    "vehicleId" | "employeeId" | "dateAfter" | "dateBefore"
  > {
  selectedVehicle: Option | null;
  selectedEmployee: Option | null;
  datesFilter: [Dayjs | null, Dayjs | null] | null;
}

export interface CommonViewState {
  chargesView: EmployeeChargesViewState;
  damagesView: {
    tableParams: BasicTableFormItemTableParams;
    selectedVehicle: Option | null;
    selectedEmployee: Option | null;
    statusFilters: Damage["status"][];
    customVehicleField: string | null;
    vehicleTypes: string[];
    vehicleGroups: string[];
    excludeVehicleGroups: boolean;
    companyDepartments: string[];
    excludeCompanyDepartments: boolean;
  };
  protocolsView: ProtocolsViewState;
  employeesView: EmployeesViewState;
  vehiclesView: VehiclesViewState;
  templatesView: GetTemplatesFilters;
  vehicleGroupsView: {
    tableParams: BasicTableFormItemTableParams;
  };
  customVehicleFieldsGroupsView: GetCustomVehicleFieldsGroupsFilters;
  templateTagsView: GetTemplateTagsFilters;
  vehicleFieldsPresetsView: {
    tableParams: BasicTableFormItemTableParams;
  };
  companyDepartmentsListView: {
    tableParams: BasicTableFormItemTableParams;
  };
  assignVehiclesListView: SimpleVehicleListFilters;
  assignUsersListView: Pick<
    GetAllEmployeesFilters,
    | "includeInactive"
    | "tableParams"
    | "searchTerm"
    | "companyDepartments"
    | "excludeCompanyDepartments"
  >;
  assignTemplatesListView: GetTemplatesFilters;
  assignCustomVehicleFieldsListView: GetCustomVehicleFieldsFilters;
  customVehicleFieldsView: GetCustomVehicleFieldsFilters;
  fleetStateCustomVehicleFieldsListView: GetCustomVehicleFieldsFilters;
  fleetStateCustomVehicleFieldValuesListView: {
    tableParams: BasicTableFormItemTableParams;
    statuses: FleetStateCustomVehicleFieldValue["status"][];
    vehicleTypeFilters: string[];
    vehicleGroupFilters: string[];
    plateNumber: string;
  };
  linkTypesSettings: {
    tableParams: BasicTableFormItemTableParams;
  };
}

export const commonViewInitialState: CommonViewState = {
  chargesView: {
    tableParams: {
      pageNumber: 1,
      sortByField: "-date",
    },
    selectedEmployee: null,
    statusFilters: [
      ChargedEmployeeStatus.PAID_BY_EMPLOYEE,
      ChargedEmployeeStatus.UNPAID,
    ],
    datesFilter: null,
    hideZeroCharges: false,
    selectedVehicle: null,
  },
  damagesView: {
    tableParams: {
      pageNumber: 1,
      sortByField: "-createdAt",
    },
    selectedEmployee: null,
    selectedVehicle: null,
    statusFilters: [
      "new",
      "ignored",
      "for_repair_warning",
      "for_repair_not_ok",
    ],
    customVehicleField: null,
    vehicleTypes: [],
    vehicleGroups: [],
    excludeVehicleGroups: false,
    companyDepartments: [],
    excludeCompanyDepartments: false,
  },
  protocolsView: {
    tableParams: {
      pageNumber: 1,
      sortByField: "",
    },
    selectedEmployee: null,
    selectedVehicle: null,
    datesFilter: null,
    statuses: [],
    protocolType: undefined,
    vehicleGroups: [],
    excludeVehicleGroups: false,
  },
  employeesView: {
    tableParams: {
      pageNumber: 1,
      sortByField: "",
    },
    searchQuery: "",
    includeInactive: false,
    statuses: ["activated", "not_activated"],
    userRole: null,
    companyDepartments: [],
    excludeCompanyDepartments: false,
  },
  vehiclesView: {
    vehicleGroups: [],
    includeArchived: false,
    plateNumber: "",
    statuses: [],
    tableParams: {
      pageNumber: 1,
      sortByField: "lastProtocolDate",
    },
    selectedVehicleType: "",
    companyDepartment: undefined,
    excludeCompanyDepartment: false,
  },
  templatesView: {
    includeArchived: false,
    tableParams: {
      pageNumber: 1,
      sortByField: "",
    },
  },
  vehicleGroupsView: {
    tableParams: {
      pageNumber: 1,
      sortByField: "",
    },
  },
  customVehicleFieldsGroupsView: {
    tableParams: {
      pageNumber: 1,
      sortByField: "",
    },
  },
  templateTagsView: {
    tableParams: {
      pageNumber: 1,
      sortByField: "",
    },
  },
  vehicleFieldsPresetsView: {
    tableParams: {
      pageNumber: 1,
      sortByField: "",
    },
  },
  companyDepartmentsListView: {
    tableParams: {
      pageNumber: 1,
      sortByField: "",
    },
  },
  assignVehiclesListView: {
    vehicleGroups: [],
    plateNumber: "",
    excludeVehicleGroups: false,
    tableParams: {
      pageNumber: 1,
      sortByField: "",
    },
    selectedVehicleType: "",
  },
  assignUsersListView: {
    includeInactive: false,
    tableParams: {
      pageNumber: 1,
      sortByField: "",
    },
    searchTerm: "",
  },
  assignTemplatesListView: {
    templateTags: [],
    excludeTemplateTags: false,
    tableParams: {
      pageNumber: 1,
      sortByField: "",
    },
  },
  assignCustomVehicleFieldsListView: {
    tableParams: {
      pageNumber: 1,
      sortByField: "",
    },
  },
  customVehicleFieldsView: {
    tableParams: {
      pageNumber: 1,
      sortByField: "customVehicleFieldsGroupObject",
    },
  },
  fleetStateCustomVehicleFieldsListView: {
    tableParams: {
      pageNumber: 1,
      sortByField: "customVehicleFieldsGroupObject",
    },
  },
  fleetStateCustomVehicleFieldValuesListView: {
    tableParams: {
      pageNumber: 1,
      sortByField: "status",
    },
    statuses: [],
    vehicleGroupFilters: [],
    vehicleTypeFilters: [],
    plateNumber: "",
  },
  linkTypesSettings: {
    tableParams: {
      pageNumber: 1,
      sortByField: "",
    },
  },
};

interface SetFilterAction {
  viewName: keyof CommonViewState;
  filters:
    | Partial<CommonViewState["employeesView"]>
    | Partial<CommonViewState["damagesView"]>
    | Partial<CommonViewState["chargesView"]>
    | Partial<CommonViewState["protocolsView"]>
    | Partial<CommonViewState["templatesView"]>
    | Partial<CommonViewState["vehicleGroupsView"]>
    | Partial<CommonViewState["vehicleFieldsPresetsView"]>
    | Partial<CommonViewState["assignVehiclesListView"]>
    | Partial<CommonViewState["customVehicleFieldsView"]>
    | Partial<CommonViewState["vehiclesView"]>
    | Partial<CommonViewState["fleetStateCustomVehicleFieldValuesListView"]>
    | Partial<CommonViewState["fleetStateCustomVehicleFieldsListView"]>
    | Partial<CommonViewState["linkTypesSettings"]>;
}

export type CommonStateAction = SetFilterAction;

function reducer(
  prevState: CommonViewState,
  action: CommonStateAction
): CommonViewState {
  return {
    ...prevState,
    [action.viewName]: {
      ...prevState[action.viewName],
      ...action.filters,
    },
  };
}

export interface CommonStateContextValue extends CommonViewState {
  setFilters: Dispatch<CommonStateAction>;
}

export const commonStateContext = createContext<CommonStateContextValue>({
  ...commonViewInitialState,
  setFilters: () => null,
});

export function useCommonStateContext() {
  return useContext(commonStateContext);
}

export function CommonStateProvider(props: {
  children: ReactNode;
}): JSX.Element {
  const [state, setFilters] = useReducer(reducer, commonViewInitialState);

  return (
    <commonStateContext.Provider
      value={{
        ...state,
        setFilters,
      }}
    >
      {props.children}
    </commonStateContext.Provider>
  );
}
